import * as R from 'remeda';
import { createSelector } from 'reselect';
import { activitySelectors } from 'store/data/activity';
import { subZoneSelectors } from 'store/data/subZone';
import { zoneSelectors } from 'store/data/zone';
import { Types } from 'Types';

import { MISSING_VALUE, OUTSIDE_ZONE_NAME } from './constants';

export type Hierarchy = {
  id: string;
  name: string;
  children: PivotTable.Row[];
  code?: string;
};

export const editedActivityDomain = (state: Types.RootState) =>
  state.page.zoneManagement.editedActivityDomain;

export const byId = createSelector(
  editedActivityDomain,
  (editedActivityDomain) => editedActivityDomain.byId,
);
export const allIds = createSelector(
  editedActivityDomain,
  (editedActivityDomain) => editedActivityDomain.allIds,
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

const zoneStructure = createSelector(
  list,
  zoneSelectors.byId,
  subZoneSelectors.byId,
  (activities, zoneById, subZoneById) => {
    const hierarchy: Hierarchy[] = [];

    function getSubZoneChildren(subZoneIds: string[]) {
      const children: PivotTable.Row[] = R.pipe(
        subZoneIds,
        R.map((subZoneId) => {
          const subZone = subZoneById[subZoneId];
          if (!R.isDefined(subZone)) return;

          const child: PivotTable.Row = {
            id: subZone.id,
            name: `${subZone.code} - ${subZone.name}`,
            ancestorIds: [],
            indentLevel: 1,
          };

          return child;
        }),
        R.filter(R.isDefined),
      );
      return children;
    }

    function getActivityChildren(activities: Data.Activity[]) {
      const children: PivotTable.Row[] = R.pipe(
        activities,
        R.map((activity) => {
          const child: PivotTable.Row = {
            id: activity.id,
            name: `${activity.code} - ${activity.name}`,
            ancestorIds: [],
            indentLevel: 1,
            childRows:
              (activity.subZoneIds &&
                getSubZoneChildren(activity.subZoneIds)) ??
              undefined,
          };
          return child;
        }),
      );

      return children || [];
    }

    const sortZones = (zones: Hierarchy[]) => {
      const sortOrder = ['Outbound', 'Inbound', 'Outside zone'];

      return zones.sort((a: Hierarchy, b: Hierarchy) => {
        const aIndex = sortOrder.indexOf(a.name);
        const bIndex = sortOrder.indexOf(b.name);
        return aIndex - bIndex;
      });
    };

    R.pipe(
      activities,
      R.groupBy((activity) => activity.zoneId ?? MISSING_VALUE),
      R.forEachObj.indexed((activities, zoneId) => {
        const zone = zoneById[zoneId.toString()];

        const children: PivotTable.Row[] = getActivityChildren(activities);

        hierarchy.push({
          id: zoneId.toString(),
          name: zone?.name ?? OUTSIDE_ZONE_NAME,
          children,
        });
      }),
    );

    return sortZones(hierarchy);
  },
);

const selectableActivitiesForZone = createSelector(
  (state: Types.RootState, ownProps: { zone: Hierarchy }) => ownProps.zone.id,
  byId,
  zoneSelectors.byId,
  (zoneId, editedActivityDomain) => {
    return R.pipe(
      editedActivityDomain,
      R.pickBy((activity: Data.Activity) => {
        return (activity?.zoneId || '-') !== zoneId;
      }),
      Object.values,
      R.map((activity) => R.pick(activity, ['id', 'name', 'code'])),
    );
  },
);

export const selectableSubZones = createSelector(
  (state: Types.RootState, ownProps: { zoneId: string }) => ownProps.zoneId,
  zoneSelectors.byId,
  subZoneSelectors.byId,
  (zoneId, zoneById, subZoneById) => {
    const zone = zoneById[zoneId];

    if (!R.isDefined(zone)) return [];

    const { subZoneIds } = zone;

    const selectableSubZones: LabelItem[] = R.pipe(
      subZoneIds,
      R.map((subZoneId) => {
        const subZone = subZoneById[subZoneId];

        if (!R.isDefined(subZone)) return;

        return {
          id: subZone.id,
          name: `${subZone.code} - ${subZone.name}`,
        } as LabelItem;
      }),
      R.filter(R.isDefined),
    );

    return selectableSubZones;
  },
);

type ActivityChange = {
  activityName: string;
  changes: string[];
};

export type ActivityDomainDiff = {
  [key: string]: ActivityChange;
};

const activityDomainDiff = createSelector(
  activitySelectors.byId,
  byId,
  zoneSelectors.byId,
  subZoneSelectors.byId,
  (activityById, editedActivityById, zonesById, subZoneById) => {
    const diff: { [key: string]: ActivityChange } = {};

    R.pipe(
      activityById,
      Object.values,
      R.map((activity) => {
        const editedActivity = editedActivityById[activity.id];

        if (!editedActivity) return;

        let changes: string[] = [];

        if (activity.zoneId !== editedActivity.zoneId) {
          const sourceZoneName =
            activityById[activity.zoneId]?.name || OUTSIDE_ZONE_NAME;

          const destZoneName = editedActivity.zoneId
            ? zonesById[activity.zoneId]?.name
            : OUTSIDE_ZONE_NAME;

          changes = [
            ...changes,
            `Moved from "${destZoneName}" to "${sourceZoneName}"`,
          ];
        }

        const subZonesRemoved = R.pipe(
          R.difference(
            activity.subZoneIds || [],
            editedActivity.subZoneIds || [],
          ),
          R.map((subZoneId: string) => {
            const subZoneName = subZoneById[subZoneId]?.name;
            return `Removed subZone "${subZoneName}"`;
          }),
        );

        const subZonesAdded = R.pipe(
          R.difference(
            editedActivity.subZoneIds || [],
            activity.subZoneIds || [],
          ),
          R.map((subZoneId: string) => {
            const subZoneName = subZoneById[subZoneId]?.name;
            return `Added subZone "${subZoneName}"`;
          }),
        );

        changes = [...changes, ...subZonesRemoved, ...subZonesAdded];

        if (changes.length)
          diff[activity.id] = {
            activityName: activity.name,
            changes,
          };
      }),
    );

    return diff;
  },
);

export { activityDomainDiff, selectableActivitiesForZone, zoneStructure };
