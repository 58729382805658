import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  Card,
  Chart,
  Input,
  Modal,
  MODAL_CONTENT_GAP,
  MODAL_CONTENT_HORIZONTAL_PADDING,
  Stepper,
} from 'components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { dashboardSelectors } from 'store/page/dashboard';
import Types from 'Types';
import { timeUtility } from 'utility';

const CHART_TYPES: LabelItem<Charts.ChartType>[] = [
  { id: 'hours', name: 'Hours' },
  { id: 'rows', name: 'Rows' },
];

const mapStateToProps = (state: Types.RootState) => ({
  week: dashboardSelectors.selectedWeek(state), // week selected in background;
});

const mapDispatchToProps = (_dispatch: Types.Dispatch) => ({});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  isOpen: boolean;
  row?: PivotTable.Row;

  onClose(): void;
};

type Props = OwnProps & DispatchProps & StateProps;

const TableRowChartModal: FunctionComponent<Props> = (props) => {
  const { onClose, isOpen, week, row } = props;

  const entityIds = !!row ? [row.id] : [];
  const [selectedWeek, setSelectedWeek] = useState(week);

  const [selectedChart, setSelectedChart] = useState<Charts.ChartType>('hours');

  const theme = useTheme();

  // Selected week should reset when closing / opening
  useEffect(() => {
    setSelectedWeek(week);
  }, [isOpen, week]);

  if (!isOpen) return null;

  function stepSelectedWeek(step: System.Step) {
    const steppedWeek = timeUtility.step({
      timeDate: selectedWeek,
      step,
      stepSize: 'week',
    });

    setSelectedWeek(steppedWeek);
  }

  function generateDateIds(surroundingWeeks: number) {
    const fromDay = R.pipe(
      timeUtility.getDaysFromWeek(selectedWeek.year, selectedWeek.week),
      R.values,
      R.sortBy(
        ({ year }) => year,
        ({ month }) => month,
        ({ dayOfMonth }) => dayOfMonth,
      ),
      R.first(),
    );

    if (!R.isDefined(fromDay)) return [];

    const dates = timeUtility.getSurroundingWeeks({
      fromDay,
      surroundingWeeks,
    });

    return R.pipe(
      dates,
      R.map((date) => date.id),
    );
  }

  function generateDataset() {
    const entityId = R.first(entityIds);

    if (!R.isDefined(entityId)) return;

    const datasets: Charts.Dataset[] = [];

    datasets.push({
      id: 'actual',
      color: theme.palette.datasets.line,
      entityIds: [entityId],
      filterEntityIds: [],
      kpi: 'actual',
      name: 'Productivity',
      type: 'line-fill-to-dataset',
      xAxisId: 'xAxis',
      yAxisId: 'yAxisPrimary',
    });

    datasets.push({
      id: 'target',
      color: theme.palette.datasets.stepped,
      entityIds: [entityId],
      filterEntityIds: [],
      kpi: 'target',
      name: 'Target',
      type: 'stepped-line',
      xAxisId: 'xAxis',
      yAxisId: 'yAxisPrimary',
    });

    if (selectedChart === 'hours') {
      datasets.push({
        id: 'hours',
        color: theme.palette.datasets.complimentary_bar,

        entityIds: [entityId],
        filterEntityIds: [],
        kpi: 'hours',
        name: 'Hours',
        type: 'bar',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisSecondary',
      });
    } else if (selectedChart === 'rows') {
      datasets.push({
        id: 'rows',
        color: theme.palette.datasets.complimentary_bar,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: 'noInternalProcessed',
        name: 'Rows',
        type: 'bar',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisSecondary',
      });
    }
    return datasets;
  }

  const datasets = generateDataset();
  const dateIds = generateDateIds(2);

  if (!R.isDefined(datasets)) throw 'Should never happen';

  return (
    <Modal title={row?.name ?? ''} isOpen={isOpen} onClose={onClose}>
      <Card
        largeHeader
        noPadding
        title={
          <Stepper
            next={() => stepSelectedWeek('next')}
            previous={() => stepSelectedWeek('previous')}
            sx={{ width: theme.sizes['377'], alignSelf: 'flex-end' }}
          >
            <Typography variant="header3">
              {timeUtility.format(selectedWeek, 'dashboard-selected-week')}
            </Typography>
          </Stepper>
        }
        action={
          <Input
            id="select-secondary-graphy"
            value={selectedChart}
            label="secondary data"
            select={{ items: CHART_TYPES }}
            onChange={(value) => setSelectedChart(value as any)}
          />
        }
      >
        <Box sx={{ width: '100%', height: 600, pt: theme.distances.large }}>
          <Chart
            type="line"
            dateIds={dateIds}
            datasets={datasets}
            hasLegend
            xAxisConfig={{
              id: 'xAxis',
              type: 'x_axis_days_to_week',
              surroundingWeeks: 2,
            }}
            yAxisConfigs={[
              { id: 'yAxisPrimary', stacked: false, expandDataLimit: true },
              { id: 'yAxisSecondary', stacked: false, expandDataLimit: true },
            ]}
            fastAnimation
            xAxisHeight="massive"
            yAxisWidth="massive"
          />
        </Box>
      </Card>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRowChartModal);
