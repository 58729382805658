import { createAction } from 'typesafe-actions';

import { ADD, REMOVE, SET, SET_DOMAIN, UPDATE } from './constants';

const add = createAction(ADD, (payload: Data.DistributionCenter) => payload)();

const update = createAction(
  UPDATE,
  (payload: PartialWithId<Data.DistributionCenter>) => payload,
)();

const remove = createAction(REMOVE, (payload: string) => payload)();

const set = createAction(SET, (payload: Data.DistributionCenter) => payload)();

const setDomain = createAction(
  SET_DOMAIN,
  (payload: NormalizedDomain<Data.DistributionCenter>) => payload,
)();

export { add, remove, set, setDomain, update };
