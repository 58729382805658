import Glue from 'Glue';
import { createAction } from 'typesafe-actions';

import { UPDATE_LOADING_STATUS } from './constants';

export const updateLoadingStatus = createAction(
  UPDATE_LOADING_STATUS,
  (payload: {
    type: Glue.LoadingType;
    status: Glue.LoadingStatus;
    error?: any;
  }) => payload,
)();
