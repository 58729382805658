import * as R from 'remeda';
import { createSelector } from 'reselect';
import { forecastSelectors } from 'store/aggregated/forecast';
import { zoneSelectors } from 'store/data/zone';
import Types from 'Types';
import { timeUtility } from 'utility';

const domain = (state: Types.RootState) => state.page.forecast;

const selectedWeek = createSelector(domain, (domain) => domain.selectedWeek);
/**
 * We need to have the selected week in day format
 * so we can list days in the zone cards.
 */
const selectedDays = createSelector(selectedWeek, (selectedWeek) => {
  const days = R.pipe(
    timeUtility.getDaysFromWeek(selectedWeek.year, selectedWeek.week),
    R.values,
    R.sortBy((day) => day.id),
  );
  return days;
});

/**
 * The card structure for the view,
 * necessary to relevant zones
 */
const zoneCards = createSelector(
  forecastSelectors.byZone,
  zoneSelectors.byId,
  (forecastByZone, zoneById) => {
    const zoneIds = R.keys(forecastByZone);

    const zones = R.pipe(
      zoneIds,
      R.map((zoneId) => zoneById[zoneId]),
      R.filter(R.isDefined),
      R.sortBy((zone) => zone.name),
    );

    return zones as LabelItem[];
  },
);

export { selectedDays, selectedWeek, zoneCards };
