function parseJwt<T extends Record<string, any>>(token: string) {
  const base64Url = token.split('.')[1];
  if (!base64Url) return;
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload) as T;
}

export { parseJwt };
