import { CalendarTodayOutlined } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
// import {} from 'components';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { flexHoursSelectors } from 'store/page/flexHours';
import Types from 'Types';

import SelectDatesModal from './SelectDatesModal';

const mapStateToProps = (state: Types.RootState) => ({
  selectedDatesCount: flexHoursSelectors.selectedDates(state).length,
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const PageActions: FunctionComponent<Props> = (props) => {
  const { selectedDatesCount } = props;
  const [selectingDates, setSelectingDates] = useState(false);

  return (
    <>
      <SelectDatesModal
        isOpen={selectingDates}
        onClose={() => setSelectingDates(false)}
      />

      <IconButton
        onClick={() => setSelectingDates(true)}
        sx={(theme) => ({ marginLeft: theme.distances.medium })}
      >
        <Badge
          badgeContent={selectedDatesCount}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          color="secondary"
        >
          <CalendarTodayOutlined />
        </Badge>
      </IconButton>
    </>
  );
};

export default connect(mapStateToProps)(PageActions);
