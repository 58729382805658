import * as R from 'remeda';
import { createSelector } from 'reselect';
import { activitySelectors } from 'store/data/activity';
import { subZoneSelectors } from 'store/data/subZone';
import { zoneSelectors } from 'store/data/zone';
import Types from 'Types';
import { aggregationUtility, kpiUtility } from 'utility';

import { onRelevantItems } from '../helpers';

export const domain = (state: Types.RootState) => state.aggregated.external;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

export const byDate = createSelector(list, (list) =>
  aggregationUtility.groupByDate(list),
);
export const byZone = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'zoneId'),
);

export const byEntity = createSelector(byZone, (byZone) => ({
  ...byZone,
}));

export const kpi = createSelector(
  (
    state: Types.RootState,
    ownProps: {
      entityIds: string[];
      dateId: string;
    },
  ) => ownProps,
  byDate,
  byEntity,
  byId,
  zoneSelectors.byId, // To get target
  subZoneSelectors.byId, // To get target
  activitySelectors.byId, // To get target
  ({ dateId, entityIds }, byDate, byEntity, byId) => {
    let noReceived = 0;

    onRelevantItems({
      byId,
      byDate,
      byEntity,
      dateId,
      itemCallback: (item) => {
        noReceived = noReceived + item.noReceived;
      },
      entityIds,
    });

    const kpiValues: KPI.ExternalValues = {
      noExternalReceived: kpiUtility.sanitize(noReceived),
    };

    return kpiValues;
  },
);
