import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { StatusText } from 'components';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';
import { kpiUtility, timeUtility } from 'utility';

import { DEVIATION_TOLERANCE } from './constants';

type Props = {
  history: KPI.Projection[];
  actualValue: number | null;
};

const ProjectionHistory: FunctionComponent<Props> = (props) => {
  const { history, actualValue } = props;

  return (
    <List
      sx={(theme) => ({
        p: 0,
        overflowY: 'scroll',
        maxHeight: theme.sizes[233],
      })}
    >
      {history.map((projection, index) => {
        const deviation = kpiUtility.calculateDeviation(
          projection.value,
          actualValue,
        );

        return (
          <ListItem
            key={index}
            sx={(theme) => ({
              display: 'flex',
              height: theme.sizes[55],
              backgroundColor: theme.palette.background.paper,
              ':nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <ListItemText>
              <Typography variant="text2">
                {projection.value.toString()}
              </Typography>
            </ListItemText>
            {R.isDefined(deviation) && (
              <StatusText
                text={`${deviation}%`}
                status={
                  Math.abs(deviation) > DEVIATION_TOLERANCE
                    ? 'error'
                    : 'success'
                }
              />
            )}
            <Typography
              variant="text2"
              sx={(theme) => ({
                flexGrow: 1,
                textAlign: 'end',
                color: theme.palette.text.secondary,
              })}
            >
              {timeUtility.format(
                timeUtility.timestampToDay(projection.timestamp),
                'forecast-table-day',
              )}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

export { ProjectionHistory };
