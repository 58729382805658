import * as R from 'remeda';
import { domainUtility } from 'utility';

function transformForecasts(args: { forecasts: API.Forecast[] }) {
  const { forecasts } = args;
  const forecastDomain: NormalizedDomain<Data.Projection> = {
    allIds: [],
    byId: {},
  };

  for (let index = 0; index < forecasts.length; index++) {
    const currentForecast = forecasts[index];
    if (!R.isDefined(currentForecast)) continue;
    const {
      year,
      monthNumber,
      dayOfWeek,
      dayOfMonth,
      weekNumber,
      companyId,
      forecastDate,
      id,
      createdAt,
      unitOfMeasureId,
      value,
      zoneId,
    } = currentForecast;

    if (
      !R.isDefined(year) ||
      !R.isDefined(monthNumber) ||
      !R.isDefined(dayOfMonth) ||
      !R.isDefined(weekNumber) ||
      !R.isDefined(dayOfWeek) ||
      !R.isDefined(createdAt)
    )
      continue;

    const timestamp = new Date(createdAt).getTime();

    const forecast: Data.Projection = {
      id,
      dayOfWeek,
      month: monthNumber - 1,
      timestamp,
      year,
      week: weekNumber,
      dayOfMonth,
      companyId,
      unitOfMeasureId,
      value,
      forecastDate,
      zoneId,
    };

    domainUtility.mutateAdd(forecastDomain, forecast);
  }

  return { forecastDomain };
}

export { transformForecasts };
