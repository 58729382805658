import { Components, Theme } from '@mui/material';

type MuiComponent = Components<Omit<Theme, 'components'>>;

const MuiButton: MuiComponent['MuiButton'] = {
  styleOverrides: {
    root: { gap: 8 }, // ! Not spacing value
  },
};

const MuiIconButton: MuiComponent['MuiIconButton'] = {
  defaultProps: { sx: { width: 'fit-content', height: 'fit-content' } },
};

const MuiPaper: MuiComponent['MuiPaper'] = {
  styleOverrides: {
    root: {
      backgroundImage: 'unset', // stop adding gradients material ui
    },
  },
};

const components: Components<Omit<Theme, 'components'>> = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
};

export { components };
