import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';

export const domain = (state: Types.RootState) => state.data.employee;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);
