import { Box, Stack } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import AppBar from './AppBar';

const NavigationRoute: FunctionComponent = () => {
  return (
    <Stack direction="column" height="100%" width="100%">
      <AppBar />
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Stack>
  );
};

export { NavigationRoute };
