import { createAction } from 'typesafe-actions';

import { ADD, REMOVE, SET, SET_DOMAIN, UPDATE } from './constants';

const add = createAction(
  ADD,
  (payload: Data.AggregatedEmployeeHour) => payload,
)();

const update = createAction(
  UPDATE,
  (payload: PartialWithId<Data.AggregatedEmployeeHour>) => payload,
)();

const remove = createAction(REMOVE, (payload: string) => payload)();

const set = createAction(
  SET,
  (payload: Data.AggregatedEmployeeHour) => payload,
)();

const setDomain = createAction(
  SET_DOMAIN,
  (payload: NormalizedDomain<Data.AggregatedEmployeeHour>) => payload,
)();

export { add, remove, set, setDomain, update };
