import * as dateFns from 'date-fns';
import * as R from 'remeda';
import { misc } from 'variables';

import {
  DECEMBER,
  DisplayLocation,
  JANUARY,
  MONDAY,
  monthConverter,
  SUNDAY,
  weekdayConverter,
} from './constants';
import * as formatters from './formatters';

function getCalendarMonth(year: number, month: number) {
  const SHOW_NUMBER_OF_WEEKS = 6;

  const days: Time.Day[] = [];

  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);

  // Since displayed calendar month can contain days from other months
  // We need to find actual start / end date.
  // Calendar month is displayed by week, and week start with MONDAY
  // And end with SUNDAY
  while (startDate.getDay() !== MONDAY)
    startDate.setDate(startDate.getDate() - 1);
  while (endDate.getDay() !== SUNDAY) endDate.setDate(endDate.getDate() + 1);

  // To keep the UI not jumpy, we need a static number of weeks.
  // A month can at most contain 6 separate weeks.
  const weeksBetween = dateFns.differenceInWeeks(endDate, startDate, {
    roundingMethod: 'ceil',
  });

  for (let i = weeksBetween; i < SHOW_NUMBER_OF_WEEKS; i++) {
    endDate.setDate(endDate.getDate() + 7);
  }

  // Go through all dates from startDate - endDate
  const currentDate = new Date(startDate);
  const endTimestamp = endDate.getTime();
  let currentTimestamp = startDate.getTime();

  while (currentTimestamp <= endTimestamp) {
    days.push(timestampToDay(currentDate.getTime()));
    currentTimestamp = currentDate.setDate(currentDate.getDate() + 1);
  }

  // A week is 7 days long, only whole weeks are present here
  const weeks = R.pipe(days, R.chunk(7));

  // eslint-disable-next-line no-console

  const allWeeks: string[] = [];
  const byWeek: Record<string, Time.Day[]> = {};
  if (weeks.some((week) => week.length !== 7))
    return {
      allWeeks,
      byWeek,
    };

  // We want to sort on week id to get correct order
  R.forEach(weeks, (week) => {
    const weekDetails = getWeekDetails(week);
    if (!R.isDefined(weekDetails)) return;
    const { id } = weekDetails;
    allWeeks.push(id);
    byWeek[id] = week;
  });

  return { byWeek, allWeeks };
}

// Weeks is a pain.
// We have the need to reliably go on id when sorting date.
// Also we want to factory a week from days correctly.
function getWeekDetails(
  week: Time.Day[],
):
  | { id: string; year: number; week: number; startDayOfMonth: number }
  | undefined {
  const firstDay = R.first(week);
  const lastDay = R.last(week);
  if (!R.isDefined(firstDay)) return;
  if (!R.isDefined(lastDay)) return;
  const weekNbr = lastDay.week;

  let id = `y${lastDay.year}-w${lastDay.week}`;
  let year = lastDay.year;
  if (
    lastDay.month === JANUARY &&
    firstDay.month === DECEMBER &&
    weekNbr !== 1
  ) {
    id = `y${firstDay.year}-w${firstDay.week}`;
    year = firstDay.year;
  }

  return { id, year, week: weekNbr, startDayOfMonth: firstDay.dayOfMonth };
}

function weekFromDay(
  year: number,
  month: number,
  dayOfMonth: number,
): Time.Week {
  const date = new Date(year, month, dayOfMonth);
  const day = timestampToDay(date.getTime());
  const week = convertDay('week', day) as Time.Week;
  return week;
}

function timestampToDay(timestamp: number): Time.Day {
  const date = new Date(timestamp);

  const year = dateFns.getYear(date);
  const month = dateFns.getMonth(date);
  const dayOfMonth = dateFns.getDate(date);
  const dayOfWeek = dateFns.getDay(date);
  const week = dateFns.getISOWeek(date);

  return factory<Time.Day>({
    type: 'day',
    dayOfMonth,
    dayOfWeek,
    month,
    week,
    year,
  });
}

// TODO: rewrite?
function getMonthsFromYear(year: number): Record<string, Time.Month> {
  const months = R.pipe(
    monthConverter,
    R.mapValues((value, key) => {
      return factory<Time.Month>({
        type: 'month',
        month: Number(key),
        year,
      });
    }),
    R.mapKeys((_key, value) => value.id),
  );
  return months;
}

function getWeeksFromMonth(
  year: number,
  month: number,
): Record<string, Time.Week> {
  // 1st day of month
  let date = new Date(year, month);
  const weeksInMonth = dateFns.getWeeksInMonth(date, { weekStartsOn: MONDAY });

  // ISO week starts at monday
  if (!dateFns.isMonday(date)) date = dateFns.previousMonday(date);

  // how many weeks in month?

  const weekById: Record<string, Time.Week> = {};

  for (let i = 0; i < weeksInMonth; i++) {
    const isoWeek = dateFns.getISOWeek(date);
    const year = dateFns.getYear(date);
    const week = factory<Time.Week>({
      type: 'week',
      week: isoWeek,
      year,
    });

    weekById[week.id] = week;

    date = dateFns.nextMonday(date);
  }

  return weekById;
}

// TODO: Does this consider week 53 in january for example?
function getDaysFromWeek(
  year: number,
  weekNbr: number,
): Record<string, Time.Day> {
  let date = new Date(year, 3, 5);

  date = dateFns.setISOWeek(date, weekNbr); // ISO week starts at monday
  if (!dateFns.isMonday(date)) date = dateFns.previousMonday(date);
  const dayById: Record<string, Time.Day> = {};

  for (let i = 0; i < dateFns.daysInWeek; i++) {
    const year = dateFns.getYear(date);
    const month = dateFns.getMonth(date);
    const dayOfMonth = dateFns.getDate(date);
    const dayOfWeek = dateFns.getDay(date);
    const day = factory<Time.Day>({
      type: 'day',
      week: weekNbr,
      dayOfMonth,
      dayOfWeek,
      month,
      year,
    });

    dayById[day.id] = day;

    date = dateFns.addDays(date, 1);
  }

  return dayById;
}

// TODO day december week 1
function convertDay(
  type: Exclude<Time.DateType, 'day'>,
  day: Time.Day,
): Time.Date {
  switch (type) {
    case 'year':
      return factory<Time.Year>({ type: 'year', year: day.year });
    case 'month':
      return factory<Time.Month>({
        type: 'month',
        year: day.year,
        month: day.month,
      });
    case 'week':
      let year = day.year;
      if (day.week > 50 && day.month === JANUARY) year = year - 1;
      if (day.week === 1 && day.month === DECEMBER) year = year + 1;

      return factory<Time.Week>({
        type: 'week',
        year,
        week: day.week,
      });
  }
}

function step<T extends Time.Date>(args: {
  timeDate: T;
  step: System.Step;
  stepSize?: Time.DateType;
}): T {
  const { step, timeDate, stepSize = timeDate.type } = args;
  const derivate = step === 'next' ? 1 : -1;

  const steppedDate = (date: Date): Date => {
    switch (stepSize) {
      case 'year':
        return dateFns.addYears(date, derivate);
      case 'month':
        return dateFns.addMonths(date, derivate);
      case 'day':
        return dateFns.addDays(date, derivate);
      case 'week':
        return dateFns.addWeeks(date, derivate);
    }
  };

  // Year
  if (timeDate.type === 'year') {
    const yearDate = steppedDate(new Date(timeDate.year, 0));
    const year = dateFns.getYear(yearDate);
    return factory<Time.Year>({ type: 'year', year }) as T;
  }

  // Month
  else if (timeDate.type === 'month') {
    const monthDate = steppedDate(new Date(timeDate.year, timeDate.month));
    const year = dateFns.getYear(monthDate);
    const month = dateFns.getMonth(monthDate);
    return factory<Time.Month>({ type: 'month', year, month }) as T;
  }

  // Day
  else if (timeDate.type === 'day') {
    const dayDate = steppedDate(
      new Date(timeDate.year, timeDate.month, timeDate.dayOfMonth),
    );

    const year = dateFns.getYear(dayDate);
    const month = dateFns.getMonth(dayDate);
    const dayOfMonth = dateFns.getDate(dayDate);
    const dayOfWeek = dateFns.getDay(dayDate);
    const isoWeek = dateFns.getISOWeek(dayDate);

    const day = factory<Time.Day>({
      type: 'day',
      year,
      month,
      dayOfMonth,
      dayOfWeek,
      week: isoWeek,
    }) as T;

    return day;
  }

  // Week
  let weekDate = dateFns.setISOWeek(
    new Date(timeDate.year, 9, 3),
    timeDate.week,
  );

  // weekDate = dateFns.startOfISOWeek(weekDate);

  weekDate = steppedDate(weekDate);

  const year = dateFns.getISOWeekYear(weekDate);
  const isoWeek = dateFns.getISOWeek(weekDate);
  const week = factory<Time.Week>({
    type: 'week',
    year,
    week: isoWeek,
  }) as T;

  return week;
}

function hasDate(args: { date: Time.Date; dateItem: Data.DateItem }) {
  const { dateItem, date } = args;
  const isYear = date.year === dateItem.year;
  switch (date.type) {
    case 'year':
      return isYear;
    case 'month':
      if (!isYear && dateItem) return false;
      return date.month === dateItem.month;
    case 'week':
      if (!isYear) return false;
      return date.week === dateItem.week;
    case 'day':
      if (!isYear) return false;
      if (date.month !== dateItem.month) return false;
      return date.dayOfMonth === dateItem.dayOfMonth;
  }
}

function readableHours(hours: number) {
  const integer = Math.floor(hours);
  const decimal = hours % 1;

  let minutes = Number((decimal * 60).toFixed(0));
  if (minutes < 0) minutes = minutes * -1;
  return `${integer}:${minutes.toString().padStart(2, '0')}`;
}

function format(date: Time.Date, location: DisplayLocation) {
  switch (date.type) {
    case 'year':
      return formatters.formatYear(date, location);
    case 'month':
      return formatters.formatMonth(date, location);
    case 'week':
      return formatters.formatWeek(date, location);
    case 'day':
      return formatters.formatDay(date, location);
    default:
      return misc.MISSING;
  }
}

function getDisplayDate(baseDate: Data.DateItem, type: Time.DateType | 'date') {
  const { year, month, dayOfWeek, week, dayOfMonth } = baseDate;

  const yearString = `${year}`;
  const monthString = month.toString().padStart(2, '0');
  const dayOfMonthString = dayOfMonth.toString().padStart(2, '0');
  const weekString = week.toString().padStart(2, '0');
  const dayName = weekdayConverter[dayOfWeek] ?? 'ERROR';
  const monthName = monthConverter[month] ?? 'ERROR';
  switch (type) {
    case 'year':
      return yearString;
    case 'month':
      return `${yearString}, ${monthName}`;
    case 'week':
      return `${yearString}, v.${weekString}`;
    case 'day':
      return `${yearString}, v.${weekString} - ${dayName}`;
    case 'date':
      return `${yearString}-${monthString}-${dayOfMonthString}`;
  }
}

function toTimeDate(date: Date) {
  const year = dateFns.getYear(date);
  const month = dateFns.getMonth(date);
  const week = dateFns.getWeek(date);
  const dayOfMonth = dateFns.getDate(date);
  const dayOfWeek = dateFns.getDay(date);
  const day = factory<Time.Day>({
    type: 'day',
    year,
    month,
    week,
    dayOfMonth,
    dayOfWeek,
  });
  return day;
}
const generateYearId = (year: number) => `y${year}`;
const generateMonthId = (year: number, month: number) =>
  `y${year}-m${formatters.stringify(month, true)}`;

const generateWeekId = (year: number, weekNbr: number) =>
  `y${year}-w${formatters.stringify(weekNbr, true)}`;
const generateDayId = (year: number, month: number, dayOfMonth: number) =>
  `y${year}-m${formatters.stringify(month, true)}-d${formatters.stringify(
    dayOfMonth,
    true,
  )}`;

function factory<T extends Time.Date>(date: DistributiveOmit<T, 'id'>): T {
  switch (date.type) {
    case 'year':
      return { id: generateYearId(date.year), ...date } as Time.Year as T;
    case 'month':
      return {
        id: generateMonthId(date.year, date.month),
        ...date,
      } as Time.Month as T;
    case 'week':
      return {
        id: generateWeekId(date.year, date.week),
        ...date,
      } as Time.Week as T;
    case 'day':
      return {
        id: generateDayId(date.year, date.month, date.dayOfMonth),
        ...date,
      } as Time.Day as T;
  }
}

function toDate(timeDate: Time.Date) {
  const year = timeDate.year;

  switch (timeDate.type) {
    case 'year':
      return new Date(year, 0);
    case 'month':
      return new Date(year, timeDate.month);
    case 'day':
      return new Date(year, timeDate.month, timeDate.dayOfMonth);
    case 'week':
      return dateFns.setISOWeek(new Date(year, 3), timeDate.week);
  }
}

function compareDates(
  date: Date,
  dateToCompare: Date,
): 'past' | 'present' | 'future' {
  const isAfter = dateFns.isAfter(date, dateToCompare);
  if (isAfter) return 'future';

  const isBefore = dateFns.isBefore(date, dateToCompare);
  if (isBefore) return 'past';

  return 'present';
}

/**
 * Generate date from a dateId
 */
function dateFromId(dateId: string): Time.Date | null {
  let year: number | undefined;
  let month: number | undefined;
  let week: number | undefined;
  let dayOfMonth: number | undefined;

  R.pipe(
    dateId.split('-'),
    R.forEach((datePart) => {
      // charAt position 0 determines
      const type = datePart.charAt(0);
      const value = Number(datePart.substring(1, datePart.length));
      if (type === 'y') year = value;
      else if (type === 'm') month = value;
      else if (type === 'w') week = value;
      else if (type === 'd') dayOfMonth = value;
    }),
  );

  if (!R.isDefined(year)) return null;

  // Time.Day
  if (R.isDefined(month) && R.isDefined(dayOfMonth))
    return toTimeDate(new Date(year, month, dayOfMonth));

  // Time.Week
  if (R.isDefined(week))
    return factory<Time.Week>({ type: 'week', year, week });

  if (R.isDefined(month))
    return factory<Time.Month>({ type: 'month', year, month });

  return factory<Time.Year>({ type: 'year', year });
}

function temporalAssessor(day: Time.Day, compareTo?: Time.Date) {
  const date = toDate(day);

  let dateToCompare = new Date();

  if (!R.isDefined(compareTo)) return compareDates(date, dateToCompare);

  dateToCompare = toDate(compareTo);

  if (compareTo.type === 'day') return compareDates(date, dateToCompare);

  const interval: Interval = { start: new Date(), end: new Date() };

  if (compareTo.type === 'year') {
    interval.start = dateFns.set(dateToCompare, { month: 0, date: 1 });
    interval.end = dateFns.lastDayOfYear(dateToCompare);
  } else if (compareTo.type === 'month') {
    interval.start = dateFns.set(dateToCompare, { date: 1 });
    interval.end = dateFns.lastDayOfMonth(dateToCompare);
  } else if (compareTo.type === 'week') {
    interval.start = dateFns.isMonday(dateToCompare)
      ? dateToCompare
      : dateFns.previousMonday(dateToCompare);
    interval.end = dateFns.lastDayOfWeek(dateToCompare, { weekStartsOn: 1 });
  }

  if (dateFns.isWithinInterval(date, interval)) return 'present';

  return compareDates(date, interval.start as Date);
}

function getSurroundingWeeks(args: {
  fromDay: Time.Day;
  surroundingWeeks: number;
}) {
  const { fromDay, surroundingWeeks } = args;

  let days: Time.Day[] = R.pipe(
    getDaysFromWeek(fromDay.year, fromDay.week),
    R.values,
  );

  let nextWeekDay = fromDay;
  let lastWeekDay = fromDay;
  for (let i = 0; i < surroundingWeeks; i++) {
    nextWeekDay = step({
      timeDate: nextWeekDay,
      stepSize: 'week',
      step: 'next',
    });

    // Week 1 can occur in december, get correct year for week
    const nextWeekISOYear = dateFns.getISOWeekYear(
      new Date(nextWeekDay.year, nextWeekDay.month, nextWeekDay.dayOfMonth),
    );
    const nextWeek = R.pipe(
      getDaysFromWeek(nextWeekISOYear, nextWeekDay.week),
      R.values,
    );

    lastWeekDay = step({
      timeDate: lastWeekDay,
      stepSize: 'week',
      step: 'previous',
    });

    // Week 52/53 can occur in january, get correct year for week
    const lastWeekISOYear = dateFns.getISOWeekYear(
      new Date(lastWeekDay.year, lastWeekDay.month, lastWeekDay.dayOfMonth),
    );
    const lastWeek = R.pipe(
      getDaysFromWeek(lastWeekISOYear, lastWeekDay.week),
      R.values,
    );

    days = R.pipe(days, R.concat(lastWeek), R.concat(nextWeek));
  }

  const hej = R.pipe(
    days,
    R.sortBy((day) => day.id),
  );
  return hej;
}

function zoomOut(time: Time.Date) {
  if (time.type === 'month')
    return factory<Time.Year>({ type: 'year', year: time.year });

  if (time.type === 'week') {
    const month = dateFns
      .setISOWeek(new Date(time.year, 3), time.week) // week starts on thursday
      .getMonth();
    return factory<Time.Month>({ type: 'month', year: time.year, month });
  }

  if (time.type === 'day')
    return factory<Time.Week>({
      type: 'week',
      year: time.year,
      week: time.week,
    });

  // Can't zoom out, for year
  return time;
}

export {
  convertDay,
  dateFromId,
  factory,
  format,
  generateDayId,
  generateMonthId,
  generateWeekId,
  generateYearId,
  getCalendarMonth,
  getDaysFromWeek,
  getDisplayDate,
  getMonthsFromYear,
  getSurroundingWeeks,
  getWeekDetails,
  getWeeksFromMonth,
  hasDate,
  readableHours,
  step,
  temporalAssessor,
  timestampToDay,
  toTimeDate,
  weekFromDay,
  zoomOut,
};
