import * as R from 'remeda';
import { getType } from 'typesafe-actions';

import { LoadingAction, loadingActions, LoadingState } from '.';
const initialState: LoadingState = {
  'glue/app/START_INITIALIZE': { status: 'loading' },
  'glue/user/START_LOGIN': { status: 'idle' },
  'glue/user/START_VERIFY': { status: 'idle' },
  'glue/user/START_LOGOUT': { status: 'idle' },
  'page/flexHours/START_SELECT_DATES': { status: 'idle' },
  'page/dashboard/START_TOGGLE_HOURS': { status: 'idle' },
  'page/forecast/START_CREATE_FORECAST': { status: 'idle' },
  'page/forecast/START_CREATE_PREDICTION': { status: 'idle' },
  'page/zoneManagement/START_UPDATE_SUB_ZONES': { status: 'idle' },
};

const loadingReducer = (
  state: LoadingState = initialState,
  action: LoadingAction,
): LoadingState => {
  switch (action.type) {
    case getType(loadingActions.updateLoadingStatus):
      const { status, type, error } = action.payload;

      const updatedLoadingStatus = R.pipe(
        state,
        R.prop(type),
        R.set('status', status),
        R.set('error', error),
      );

      return R.set(state, type, updatedLoadingStatus);
    default:
      return state;
  }
};

export default loadingReducer;
