import * as R from 'remeda';
import { ActionType } from 'typesafe-actions';

import * as flexHoursActions from './actions';
import * as flexHoursConstants from './constants';
import flexHoursReducer from './reducer';
import * as flexHoursSagas from './sagas';
import * as flexHoursSelectors from './selectors';

export type FlexHoursState = {
  selectedDates: Time.Date[];
  chartMode: 'selected' | 'exploration';
  chartExploreDate: Time.Date;
  chartSelectedDateType: Time.DateType;
  chartFilter: Record<string, boolean>;
  pivotById: Record<string, boolean>;
};

export type FlexHoursAction = ActionType<typeof flexHoursActions>;

const flexHoursSagaActions = R.pick(flexHoursActions, ['startSelectDates']);

export type FlexHoursSagaAction = ActionType<typeof flexHoursSagaActions>;

export {
  flexHoursActions,
  flexHoursConstants,
  flexHoursReducer,
  flexHoursSagas,
  flexHoursSelectors,
};
