import * as R from 'remeda';
function pxToNumber(value: `${number}px`) {
  return Number(value.replace('px', ''));
}

function hexToRgb(hexColor: string): [number, number, number] {
  // Remove any leading '#' character
  if (hexColor.startsWith('#')) {
    hexColor = hexColor.slice(1);
  }

  // Convert hex color to RGB values
  const red = parseInt(hexColor.slice(0, 2), 16);
  const green = parseInt(hexColor.slice(2, 4), 16);
  const blue = parseInt(hexColor.slice(4, 6), 16);

  // Return RGB values as an array of three integers
  return [red, green, blue];
}

// TODO: not safe for non color
function convertToRgba(args: {
  hexColor: `#${string}`;
  opacity: number;
}): `rgba(${number}, ${number}, ${number}, ${number})` {
  const { hexColor, opacity } = args;
  const rgba = 'rgba(0, 0, 0, 1)';
  const hexString = R.pipe(hexColor.split('#'), R.last());

  const hexToDecimal = (hex: string) => parseInt(hex, 16);

  if (!R.isDefined(hexString)) return rgba;
  if (hexString.length !== 6) return rgba;

  const hexR = hexToDecimal(hexString.substring(0, 2));
  const hexG = hexToDecimal(hexString.substring(2, 4));
  const hexB = hexToDecimal(hexString.substring(4, 6));

  return `rgba(${hexR}, ${hexG}, ${hexB}, ${opacity})`;
}

export { convertToRgba, hexToRgb, pxToNumber };
