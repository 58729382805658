import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import * as R from 'remeda';
import { timeUtility } from 'utility';

import { headers } from './constants';
import { getButtonProps } from './helpers';

const MAXIMUM_WEEKS = 6;

const CALENDAR_HEIGHT = (theme: Theme) =>
  theme.sizes[34] + MAXIMUM_WEEKS * theme.sizes[55];

type Props = {
  selectedDates: Time.Date[];
  onSelectDate(date: Time.Date): void;
  month: Time.Month;
};

const Calendar: FunctionComponent<Props> = (props) => {
  const { selectedDates, onSelectDate, month } = props;
  const theme = useTheme();

  const headerRow = (
    <TableRow sx={{ height: theme.sizes[34] }}>
      {headers.map((header) => (
        <TableCell
          key={header}
          variant="head"
          align="center"
          sx={{
            width: theme.sizes[89],
            border: 0,
            borderRight: 1,
            borderBottom: 1,
            borderColor: theme.palette.divider,

            ':last-of-type': {
              borderRight: 0,
            },
            ':first-of-type': {
              borderRight: 3,
              borderColor: theme.palette.divider,
            },
          }}
        >
          <Typography
            variant="header3"
            sx={{
              color: theme.palette.text.secondary,
              textTransform: 'capitalize',
            }}
          >
            {header}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );

  const { allWeeks, byWeek } = useMemo(() => {
    return timeUtility.getCalendarMonth(month.year, month.month);
  }, [month]);

  return (
    <Table
      padding="none"
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        borderLeft: 3,
        borderRight: 3,
        borderColor: theme.palette.divider,
      }}
    >
      <TableHead>{headerRow}</TableHead>
      <TableBody>
        {allWeeks.map((week) => {
          const daysInWeek = byWeek[week];
          if (!R.isDefined(daysInWeek)) return;
          const dayInWeek = R.first(daysInWeek);
          if (!R.isDefined(dayInWeek)) return;

          const weekButtonProps = getButtonProps(
            timeUtility.factory({
              type: 'week',
              year: month.year,
              week: dayInWeek.week,
            }),

            selectedDates,
            onSelectDate,
          );

          return (
            <TableRow
              key={week}
              sx={{
                height: theme.sizes[55],
                '&:nth-of-type(odd)': {
                  backgroundColor: theme.palette.background.default,
                },
              }}
            >
              <TableCell
                variant="body"
                sx={{
                  height: '100%',
                  border: 0,
                  borderRight: 3,
                  borderBottom: 1,
                  borderColor: theme.palette.divider,
                }}
              >
                <ToggleButton
                  sx={{ border: 0, borderRadius: 0, height: '100%' }}
                  fullWidth
                  color="primary"
                  selected={weekButtonProps.pressed}
                  value={week}
                  onChange={weekButtonProps.onClick}
                >
                  <Typography variant="action1">
                    {weekButtonProps.text}
                  </Typography>
                </ToggleButton>
              </TableCell>

              {daysInWeek.map((day) => {
                const dayButtonProps = getButtonProps(
                  { ...day },
                  selectedDates,
                  onSelectDate,
                );
                return (
                  <TableCell
                    key={day.id}
                    variant="body"
                    sx={{
                      height: '100%',
                      border: 0,
                      borderRight: 1,
                      borderBottom: 1,
                      borderColor: theme.palette.divider,
                      ':last-of-type': {
                        borderRight: 0,
                      },
                    }}
                  >
                    <ToggleButton
                      sx={{ border: 0, borderRadius: 0, height: '100%' }}
                      fullWidth
                      color="primary"
                      selected={dayButtonProps.pressed}
                      value={day.id}
                      onChange={dayButtonProps.onClick}
                    >
                      <Typography variant="action1">
                        {dayButtonProps.text}
                      </Typography>
                    </ToggleButton>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export { Calendar, CALENDAR_HEIGHT };
