import { Box } from '@mui/material';
import { fontFamily } from 'material';
import React, { FunctionComponent } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';

const App: FunctionComponent = () => {
  return (
    <Box
      component="main"
      sx={{
        fontFamily: fontFamily.UNCONFIGURED_FONT,
        width: '100%',
        // css viewport height unit does not respect mobile device viewport change,
        // --vh variable and resize listener is set in global styles
        height: `calc(var(--vh, 1vh) * 100)`,
      }}
    >
      <RouterProvider router={router} />
    </Box>
  );
};

export default App;
