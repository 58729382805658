import {
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { ReactSortable } from 'react-sortablejs';
import * as R from 'remeda';

type Props<T> = {
  list: T[];
  setList: (list: T[]) => void;
};

const MuiList = forwardRef<HTMLUListElement, any>((props, ref) => {
  return <List ref={ref}>{props.children}</List>;
});
MuiList.displayName = 'MuiList';

// const Lol = <List ref={}/>

function SortableList<T extends LabelItem>(props: Props<T>) {
  const { list, setList } = props;

  const removeItem = (itemId: string) =>
    setList(
      R.pipe(
        list,
        R.filter((item) => item.id !== itemId),
      ),
    );
  return (
    <Box
      sx={{
        height: '100%',
        '.sortable-chosen.sortable-ghost': {
          opacity: 0,
        },
        '.sortable-fallback': {
          opacity: '1 !important',
        },
      }}
    >
      <ReactSortable
        list={list}
        handle=".handle"
        swapThreshold={0.5}
        setList={setList}
        sort={true}
        dragoverBubble={false}
        tag={MuiList}
      >
        {list.map((item) => (
          <ListItem
            disablePadding
            key={item.id}
            sx={(theme) => ({
              ':nth-of-type(2n)': {
                backgroundColor: theme.palette.background.paper,
              },
            })}
            secondaryAction={
              <IconButton onClick={() => removeItem(item.id)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton disableRipple className="handle">
              <ListItemIcon>
                <DragHandleIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="text2">{item.name}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </ReactSortable>
    </Box>
  );
}

export { SortableList };
