import { createAction } from 'typesafe-actions';

import {
  SET_SELECTED_DAY,
  SET_SELECTED_WEEK,
  START_TOGGLE_HOURS,
  TOGGLE_PIVOT,
} from './constants';
import { DashboardState } from './index';

export const togglePivot = createAction(
  TOGGLE_PIVOT,
  (payload: { id: string }) => payload,
)();

export const setSelectedWeek = createAction(
  SET_SELECTED_WEEK,
  (payload: DashboardState['selectedWeek']) => payload,
)();

export const setSelectedDay = createAction(
  SET_SELECTED_DAY,
  (payload: DashboardState['selectedDay']) => payload,
)();

export const startToggleHours = createAction(
  START_TOGGLE_HOURS,
  (payload: { dateId: string; entityId: string; isOff: boolean }) => payload,
)();
