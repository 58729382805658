import { Distances } from '@mui/material';

const distances: Distances = {
  tiny: 1,
  small: 2,
  medium: 4,
  large: 6,
  huge: 8,
  massive: 12,
};

export { distances };
