import { Theme, Typography } from '@mui/material';
import { StatusText, TableCell, ToggleTextButton } from 'components';
// import { PivotTableContentCell } from 'components/templates/View/PivotTable/ContentCell';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { aggregatedSelectors } from 'store/aggregated';
import { dashboardActions } from 'store/page/dashboard';
import Types from 'Types';
import { kpiUtility } from 'utility';

const mapDispatchToProps = (dispatch: Types.Dispatch, ownProps: OwnProps) => ({
  toggleHours: (isOff: boolean) =>
    dispatch(
      dashboardActions.startToggleHours({
        dateId: ownProps.dateId,
        entityId: ownProps.row.id,
        isOff,
      }),
    ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => ({
  kpiValues: aggregatedSelectors.kpi(state, {
    dateId: ownProps.dateId,
    entityIds: [...ownProps.row.ancestorIds, ownProps.row.id],
  }),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  row: PivotTable.Row;
  dateId: string;
  alternatingBackground: boolean;
  headers?: (LabelItem & { columns?: number })[];
  theme: Theme;
};

type Props = OwnProps & StateProps & DispatchProps;

const DateTableRow: FunctionComponent<Props> = (props) => {
  const { kpiValues, headers, toggleHours, theme, alternatingBackground } =
    props;

  if (!R.isDefined(headers)) return null;

  return (
    <>
      {headers.map((header, index) => (
        <TableCell
          key={header.id}
          theme={theme}
          lastInLine={index === headers.length - 1}
          alternatingBackground={alternatingBackground}
          columns={header.columns}
        >
          <Cell
            dateHeader={header as any}
            kpiValues={kpiValues}
            toggle={toggleHours}
          />
        </TableCell>
      ))}
    </>
  );
};

const Cell: FunctionComponent<{
  dateHeader: LabelItem<KPI.Key>;
  kpiValues: KPI.Values;
  toggle(isOff: boolean): void;
}> = (props) => {
  const { dateHeader, kpiValues, toggle } = props;
  switch (dateHeader.id) {
    case 'hours':
      return R.isDefined(kpiValues.hours) ? (
        <ToggleTextButton
          value={kpiUtility.readableHours(kpiValues.hours)}
          modifiedValue={kpiUtility.readableHours(kpiValues.modifiedHours ?? 0)}
          selected={kpiValues.modifiedHours !== kpiValues.hours}
          onChange={() => toggle(kpiValues.modifiedHours !== kpiValues.hours)}
        />
      ) : (
        <></>
      );

    case 'noInternalProcessed':
    case 'target':
    case 'actual':
      return (
        <Typography variant="text2">
          {kpiUtility.readableValue(kpiValues[dateHeader.id])}
        </Typography>
      );

    case 'hoursFromGoal':
      const hoursFromGoal = kpiValues[dateHeader.id];
      return R.isDefined(hoursFromGoal) ? (
        <StatusText
          status={hoursFromGoal >= 0 ? 'success' : 'error'}
          text={kpiUtility.readableHours(hoursFromGoal)}
        />
      ) : null;

    default:
      return null;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTableRow);
