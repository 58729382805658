import { Box, Stack } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = { children?: React.ReactNode; direction?: 'row' | 'column' };

/**
 * ScrollableView,
 * - Stacked component
 * - Making content scrollable
 * - Add standard padding
 * - Relative parent for fixed contet
 *
 */
const ScrollableView: FunctionComponent<Props> = (props) => {
  const { children, direction = 'row' } = props;
  return (
    <Box
      sx={(theme) => ({
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        position: 'relative',
        padding: theme.distances.medium,
      })}
    >
      <Stack
        direction={direction}
        alignItems={direction === 'column' ? 'center' : undefined}
        justifyContent={direction === 'row' ? 'center' : undefined}
        sx={(theme) => ({
          gap: theme.distances.medium,
          minWidth: '100%',
          width: 'fit-content',
          height: 'fit-content',
          flexShrink: 1,
        })}
      >
        {children}
      </Stack>
    </Box>
  );
};

export { ScrollableView };
