import React, { FunctionComponent } from 'react';
import { TabsPage } from 'templates';

import PageActions from './PageActions';
import PivotView from './PivotView';

const ProductivityPage: FunctionComponent = () => {
  return (
    <TabsPage
      tabs={[{ id: 'kpi', name: 'KPI ', component: <PivotView /> }]}
      pageActions={<PageActions />}
    />
  );
};

export default ProductivityPage;
