import { QrCodeScannerOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { userSelectors } from 'store/glue/user';
import Types from 'Types';

import QRCodeModal from './QRCodeModal';

const mapStateToProps = (state: Types.RootState) => ({
  companyId: userSelectors.companyId(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;
const PageActions: FunctionComponent<Props> = (props) => {
  const { companyId } = props;
  const [selectingDates, setSelectingDates] = useState(false);

  return (
    <>
      <QRCodeModal
        isOpen={selectingDates}
        onClose={() => setSelectingDates(false)}
        companyId={companyId}
      />

      <Button
        onClick={() => setSelectingDates(true)}
        sx={(theme) => ({
          marginLeft: theme.distances.medium,
          color: theme.palette.text.secondary,
        })}
        endIcon={<QrCodeScannerOutlined />}
        variant="text"
        disableRipple
        color="inherit"
      >
        <Typography variant="action1">Connect </Typography>
      </Button>
    </>
  );
};

export default connect(mapStateToProps)(PageActions);
