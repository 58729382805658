import {
  Logout as LogoutIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { darkTheme } from 'material';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'store/glue/user';
import Types from 'Types';

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  startLogout: () => dispatch(userActions.startLogout()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = DispatchProps;

const UserMenu: FunctionComponent<Props> = (props) => {
  const { startLogout } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="user"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-pressed="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <PersonIcon />
      </IconButton>

      <ThemeProvider theme={darkTheme}>
        <Menu
          anchorEl={anchorEl}
          id="user-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: (theme) => ({
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: theme.distances.tiny,

                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 19,
                  width: 10,
                  height: 10,
                  bgcolor: theme.palette.background.paper,
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              }),
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem disabled sx={menuItemSx}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <Typography variant="action2">Profile</Typography>
          </MenuItem>
          <Divider />

          <MenuItem disabled sx={menuItemSx}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="action2">Settings</Typography>
          </MenuItem>
          <MenuItem
            sx={menuItemSx}
            onClick={() => {
              handleClose();
              startLogout();
            }}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="action2">Logout</Typography>
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </>
  );
};

const menuItemSx: SxProps<Theme> = (theme) => ({
  pr: theme.distances.medium,
  height: theme.sizes[34],
  display: 'flex',
  gap: theme.distances.small,
  alignItems: 'center',
});

export default connect(null, mapDispatchToProps)(UserMenu);
