import { DOMAIN_TYPE } from '../constants';

const DOMAIN_NAME = 'activity';

export const ADD = `${DOMAIN_TYPE}/${DOMAIN_NAME}/ADD` as const;
export const UPDATE = `${DOMAIN_TYPE}/${DOMAIN_NAME}/UPDATE` as const;
export const REMOVE = `${DOMAIN_TYPE}/${DOMAIN_NAME}/REMOVE` as const;
export const SET = `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET` as const;

export const SET_DOMAIN = `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_DOMAIN` as const;
