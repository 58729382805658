import { Theme } from '@mui/material';
import { Dropdown, ExpandButton, StatusText, useDropdown } from 'components';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';

import { DEVIATION_TOLERANCE } from './constants';
import { ProjectionInput } from './Input';
import { ProjectionHistory } from './List';
type Props = {
  currentValue: number | null;
  history: KPI.Projection[];
  deviation: number | null;
  onAdd(value: number): void;
  actualValue: number | null;
  theme: Theme;
};

const Projection: FunctionComponent<Props> = (props) => {
  const { history, deviation, onAdd, actualValue, currentValue, theme } = props;

  const { handleClick, handleClose, open, anchorEl, widthRef } =
    useDropdown(377);

  const handleAdd = (value: number) => {
    handleClose();
    onAdd(value);
  };
  return (
    <>
      <ExpandButton
        expanded={open}
        label={currentValue}
        onClick={handleClick}
      />
      <Dropdown
        open={open}
        anchorEl={anchorEl}
        widthRef={widthRef}
        handleClose={handleClose}
      >
        <ProjectionInput onAdd={handleAdd} theme={theme} />
        <ProjectionHistory actualValue={actualValue} history={history} />
      </Dropdown>
      {R.isDefined(deviation) && (
        <StatusText
          text={`${deviation}`}
          status={
            Math.abs(deviation) > DEVIATION_TOLERANCE ? 'error' : 'success'
          }
        />
      )}
    </>
  );
};

export default Projection;
