import { useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { suggestionSelectors } from 'store/data/suggestion';
import { ScrollableView } from 'templates';
import Types from 'Types';

import Suggestion from './Suggestion';

const headers: (LabelItem<keyof Data.Suggestion> & { columns: number })[] = [
  { id: 'description', name: 'Description', columns: 3 },
  { id: 'solution', name: 'Solution', columns: 3 },
  { id: 'imageUris', name: 'Images', columns: 1 },
];
const mapStateToProps = (state: Types.RootState) => ({
  suggestionIds: suggestionSelectors.allIds(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const SuggestionsView: FunctionComponent<Props> = (props) => {
  const { suggestionIds } = props;
  const theme = useTheme();
  return (
    <ScrollableView direction="column">
      {suggestionIds.map((suggestionId) => (
        <Suggestion
          key={suggestionId}
          suggestionId={suggestionId}
          headers={headers}
          theme={theme}
        />
      ))}
    </ScrollableView>
  );
};

export default connect(mapStateToProps)(SuggestionsView);
