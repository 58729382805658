import { getDatasetColor } from 'components/Chart/helpers';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import { activityHourSelectors } from 'store/aggregated/activityHour';
import { employeeHourSelectors } from 'store/aggregated/employeeHour';
import { activitySelectors } from 'store/data/activity';
import { activityTypeSelectors } from 'store/data/activityType';
import { employeeSelectors } from 'store/data/employee';
import Types from 'Types';
import { timeUtility } from 'utility';

/**
 * From State
 */
const domain = (state: Types.RootState) => state.page.flexHours;
const chartMode = createSelector(domain, (domain) => domain.chartMode);
const selectedDates = createSelector(domain, (domain) => domain.selectedDates);
const pivotById = createSelector(domain, (domain) => domain.pivotById);

const chartExploreDate = createSelector(
  domain,
  (domain) => domain.chartExploreDate,
);

const chartSelectedDateType = createSelector(
  domain,
  (domain) => domain.chartSelectedDateType,
);

const chartFilter = createSelector(domain, (domain) => domain.chartFilter);

const chartFilterCount = createSelector(chartFilter, (chartFilter) =>
  R.pipe(chartFilter, R.keys, (keys) => keys.length),
);

/**
 * Derived, PivotView
 */
const labeledSelectedDates = createSelector(selectedDates, (selectedDates) =>
  R.pipe(
    selectedDates,
    R.map(
      (selectedDate) =>
        ({
          ...selectedDate,
          name: timeUtility.format(
            selectedDate,
            'flexhour-pivot-table-cell-header',
          ),
        }) as Time.LabeledDate,
    ),
  ),
);

const pivotStructure = createSelector(
  employeeHourSelectors.byId,
  employeeHourSelectors.byActivityType,
  employeeHourSelectors.byActivity,
  activityTypeSelectors.byId,
  activitySelectors.byId,
  employeeSelectors.byId,

  (
    byId,
    byActivityType,
    byActivity,
    activityTypeById,
    activityById,
    employeeById,
  ) => {
    const pivotTable: PivotTable.Row[] = [];
    const activityTypeIds = R.keys(byActivityType);

    function activityRows(activityIds: string[], ancestorIds: string[]) {
      const activityRows: PivotTable.Row[] = [];

      R.pipe(
        activityIds,
        R.map((activityId) => activityById[activityId]),
        R.filter(R.isDefined),
        R.sortBy((activity) => activity.code),
        R.forEach((activity) => {
          // Which ids exist for activityType
          const validIds = byActivity[activity.id];
          if (!R.isDefined(validIds)) return;

          activityRows.push({
            id: activity.id,
            name: `${activity.code} - ${activity.name}`,
            ancestorIds,
            childRows: employeeRows(validIds, [...ancestorIds, activity.id]),
            indentLevel: 2,
          });
        }),
      );
      return activityRows;
    }

    function employeeRows(validIds: string[], ancestorIds: string[]) {
      const employeeRows: PivotTable.Row[] = [];
      R.pipe(
        validIds,
        R.map((validId) => byId[validId]?.employeeId),
        R.filter(R.isDefined),
        R.uniq(),
        R.map((employeeId) => employeeById[employeeId]),
        R.filter(R.isDefined),
        R.sortBy((employee) => employee.name),
        R.forEach((employee) => {
          employeeRows.push({
            id: employee.id,
            name: employee.name,
            ancestorIds,
            indentLevel: 3,
          });
        }),
      );
      return employeeRows;
    }

    R.pipe(
      activityTypeIds,
      R.map((activityTypeId) => activityTypeById[activityTypeId]),
      R.filter(R.isDefined),
      R.sortBy((activityType) => activityType.code),
      R.forEach((activityType) => {
        // Which ids exist for activityType
        const validIds = byActivityType[activityType.id];
        if (!R.isDefined(validIds)) return;

        pivotTable.push({
          id: activityType.id,
          ancestorIds: [],
          name: `${activityType.code} - ${activityType.name}`,
          childRows: activityRows(activityType.activityIds, [activityType.id]),
          indentLevel: 1,
        });
      }),
    );

    return pivotTable;
  },
);

const displayRows = createSelector(
  pivotStructure,
  pivotById,
  (pivotStructure, pivotById) => {
    function recursiveAddRows(
      rows: PivotTable.Row[],
      returnList: PivotTable.Row[],
    ) {
      rows.forEach((row) => {
        returnList.push(row);
        const pivotted = R.isTruthy(pivotById[row.id]);
        if (pivotted && row.childRows)
          recursiveAddRows(row.childRows, returnList);
      });
    }

    const returnRows: PivotTable.Row[] = [];

    recursiveAddRows(pivotStructure, returnRows);

    return returnRows;
  },
);

/**
 * Derived, ChartView
 */

const chartSelectedLabels = createSelector(
  chartSelectedDateType,
  selectedDates,
  (dateType, selectedDates) =>
    R.pipe(
      selectedDates,
      R.filter((selectedDate) => selectedDate.type === dateType),
    ),
);

const chartExploreLabels = createSelector(chartExploreDate, (date) => {
  // should never happen
  if (!R.isDefined(date)) return [];

  if (date.type === 'year')
    return R.values(timeUtility.getMonthsFromYear(date.year)) as Time.Date[];
  if (date.type === 'month')
    return R.values(
      timeUtility.getWeeksFromMonth(date.year, date.month),
    ) as Time.Date[];
  if (date.type === 'week')
    return R.values(
      timeUtility.getDaysFromWeek(date.year, date.week),
    ) as Time.Date[];
  return [] as Time.Date[];
});

// Also chart labels
const chartLabels = createSelector(
  chartMode,
  chartExploreLabels,
  chartSelectedLabels,
  (mode, exploreLabels, selectedLabels) => {
    // should never happen

    switch (mode) {
      case 'exploration':
        return exploreLabels;
      case 'selected':
        return selectedLabels;
    }
  },
);

/**
 * Responsible for building the datasets configuration
 * For the Assembly Chart component,
 */
const datasets = createSelector(
  activityHourSelectors.byActivityType,
  activityTypeSelectors.byId,
  chartFilter,
  (byActivityTypeId, activityTypeById, chartFilter) => {
    const datasets: Charts.Dataset[] = [];

    R.pipe(
      byActivityTypeId,
      R.forEachObj.indexed((_, activityTypeId) => {
        const activityType = activityTypeById[activityTypeId.toString()];
        if (!R.isDefined(activityType)) throw 'should never happen';

        const { id, name, activityIds } = activityType;

        // filter away toggled activities
        const filterEntityIds = R.pipe(
          activityIds,
          R.filter((activityId) => R.isDefined(chartFilter[activityId])),
        );

        const dataset: Charts.Dataset = {
          id,
          name,
          color: '', // Colorize later
          entityIds: [id],
          filterEntityIds,
          kpi: 'hours',
          type: 'grouped-bar',
          xAxisId: 'xAxis',
          yAxisId: 'yAxisPrimary',
        };

        datasets.push(dataset);
      }),
    );

    // Colorize
    return R.map.indexed(datasets, (dataset, datasetIndex) => ({
      ...dataset,
      color: getDatasetColor(datasetIndex),
    }));
  },
);

export {
  chartExploreDate,
  chartFilter,
  chartFilterCount,
  chartLabels,
  chartMode,
  chartSelectedDateType,
  datasets,
  displayRows,
  labeledSelectedDates,
  pivotById,
  pivotStructure,
  selectedDates,
};
