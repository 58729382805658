import { Box } from '@mui/material';
import SchedulePageMock from 'assets/schedule-mock.jpg';
import React, { FunctionComponent } from 'react';
import { TabsPage } from 'templates';

// import DayView from './DayView';

const SchedulePage: FunctionComponent = () => {
  return (
    <TabsPage
      tabs={[
        {
          id: 'day',
          name: 'Day',
          component: (
            <Box
              component="img"
              src={SchedulePageMock}
              sx={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
          ),
        },
      ]}
    />
  );
};

export default SchedulePage;
