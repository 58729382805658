import { ActivityState } from 'store/data/activity';
import { createAction } from 'typesafe-actions';

import {
  ADD_SUBZONE,
  DISCARD,
  INIT,
  MOVE_ACTIVITY,
  REMOVE_SUBZONE,
  SAVE,
  START_UPDATE_SUB_ZONES,
} from './constants';

export const init = createAction(
  INIT,
  (payload: { activity: ActivityState }) => payload,
)();

export const moveActivity = createAction(
  MOVE_ACTIVITY,
  (payload: { activityId: string; zoneId: string }) => payload,
)();

export const startUpdateSubZones = createAction(
  START_UPDATE_SUB_ZONES,
  (payload: { activityId: string; subZoneIds: string[] }) => payload,
)();

export const addSubZone = createAction(
  ADD_SUBZONE,
  (payload: { activityId: string; subZoneId: string }) => payload,
)();

export const removeSubZone = createAction(
  REMOVE_SUBZONE,
  (payload: { activityId: string; subZoneId: string }) => payload,
)();

export const discard = createAction(
  DISCARD,
  (payload: { activity: ActivityState }) => payload,
)();

export const save = createAction(SAVE)();
