import * as R from 'remeda';
import { createSelector } from 'reselect';
import { activitySelectors } from 'store/data/activity';
import { activityTypeSelectors } from 'store/data/activityType';
import Types from 'Types';
import { aggregationUtility, kpiUtility } from 'utility';

import { onRelevantItems } from '../helpers';

export const domain = (state: Types.RootState) => state.aggregated.activityHour;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

const byDate = createSelector(list, (list) =>
  aggregationUtility.groupByDate(list),
);

export const byActivity = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'activityId'),
);
export const byActivityType = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'activityTypeId'),
);

export const byZone = createSelector(
  byActivity,
  activitySelectors.byZone,
  (byActivity, activityIdsbyZone) => {
    const byZone: Record<string, string[]> = R.pipe(
      activityIdsbyZone,
      R.mapValues((activityIds) =>
        R.pipe(
          activityIds,
          R.map((activityId) => byActivity[activityId]),
          R.filter(R.isDefined),
          R.flatten(),
          R.uniq(),
        ),
      ),
    );

    return byZone;
  },
);

export const byEntity = createSelector(
  byZone,
  byActivityType,
  byActivity,
  (byZone, byActivityType, byActivity) => ({
    ...byZone,
    ...byActivityType,
    ...byActivity,
  }),
);

export const kpi = createSelector(
  (
    state: Types.RootState,
    ownProps: {
      entityIds: string[];
      dateId: string;
      filterEntityIds?: string[];
    },
  ) => ownProps,
  byDate,
  byEntity,
  byId,
  ({ dateId, entityIds, filterEntityIds }, byDate, byEntity, byId) => {
    let hours = 0;
    let modifiedHours = 0;
    onRelevantItems({
      byId,
      byDate,
      byEntity,
      dateId,
      filterEntityIds,
      itemCallback: (item) => {
        modifiedHours = modifiedHours + item.modifiedSpentTime;
        hours = hours + item.spentTime;
      },
      entityIds,
    });

    const kpiValues: KPI.ActivityValues = {
      hours: kpiUtility.sanitize(hours),
      modifiedHours: kpiUtility.sanitize(modifiedHours),
    };

    return kpiValues;
  },
);

/**
 * Sorted hierarchy for activities available.
 * - sorted
 * - used in filter
 * - only activities reported on
 */
const activityHierarchy = createSelector(
  list,
  activityTypeSelectors.byId,
  activitySelectors.byId,
  (list, activityTypeById, activityById) => {
    const hierarchy: (LabelItem & { children: LabelItem[] })[] = [];

    function getChildren(list: Data.AggregatedActivityHour[]) {
      const children = R.pipe(
        list,
        R.groupBy((item) => item.activityId),
        R.keys,
        R.map((activityId) => {
          const activity = activityById[activityId];
          if (!R.isDefined(activity)) return;

          return {
            id: activity.id,
            name: `${activity.code} - ${activity.name}`,
          } as LabelItem;
        }),
        R.filter(R.isDefined),
        R.sortBy((activity) => activity.name),
      );
      return children;
    }

    R.pipe(
      list,
      R.groupBy((item) => item.activityTypeId),
      R.forEachObj.indexed((activityHours, activityTypeId) => {
        const activityType = activityTypeById[activityTypeId.toString()];
        if (!R.isDefined(activityType)) return;

        const children: LabelItem[] = getChildren(activityHours);

        hierarchy.push({
          id: activityType.id,
          name: `${activityType.code} - ${activityType.name}`,
          children,
        });
      }),
    );

    return R.sortBy(hierarchy, (item) => item.name);
  },
);

export { activityHierarchy, byDate };
