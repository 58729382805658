import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import * as R from 'remeda';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';

import * as zoneManagementActions from './actions';
import * as zoneManagementConstants from './constants';
import * as zoneManagementSelectors from './selectors';

function* startUpdateSubZonesSaga(
  action: ActionType<typeof zoneManagementActions.startUpdateSubZones>,
) {
  function* saga() {
    const { activityId, subZoneIds } = action.payload;

    const editedActivityDomain: ReturnType<
      typeof zoneManagementSelectors.editedActivityDomain
    > = yield select(zoneManagementSelectors.editedActivityDomain);

    const currentActivity = editedActivityDomain.byId[activityId];
    if (!R.isDefined(currentActivity)) throw 'Activity must exist';

    // We need to remove these ids from other activities
    const removeSubZoneIds = R.difference(
      subZoneIds,
      currentActivity.subZoneIds ?? [],
    );

    editedActivityDomain.byId[activityId] = { ...currentActivity, subZoneIds };

    editedActivityDomain.byId = R.mapValues(
      editedActivityDomain.byId,
      (activity) => {
        if (!R.isDefined(activity)) return undefined;

        const editiedActivity: Data.Activity = {
          ...activity,
          subZoneIds:
            activity.id === activityId
              ? subZoneIds
              : R.pipe(
                  activity.subZoneIds ?? [],
                  R.filter((subZoneId) => {
                    const isMoved = R.find(
                      removeSubZoneIds,
                      (removedSubZoneId) => removedSubZoneId === subZoneId,
                    );
                    return !isMoved;
                  }),
                ),
        };
        return editiedActivity;
      },
    );

    yield put(
      zoneManagementActions.init({ activity: R.clone(editedActivityDomain) }),
    );
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([
    takeLeading(
      zoneManagementConstants.START_UPDATE_SUB_ZONES,
      startUpdateSubZonesSaga,
    ),
  ]);
}
