import { all, spawn } from 'redux-saga/effects';
import { appSagas } from 'store/glue/app';
import { userSagas } from 'store/glue/user';
import { flexHoursSagas } from 'store/page/flexHours';

import { dashboardSagas } from './page/dashboard';
import { forecastSagas } from './page/forecast';
import { zoneManagementSagas } from './page/zoneManagement';

// ! typesafety is not very helpful from this perspective anyway
export function* rootSaga(): any {
  return yield all([
    spawn(appSagas.watcher),
    spawn(flexHoursSagas.watcher),
    spawn(userSagas.watcher),
    spawn(dashboardSagas.watcher),
    spawn(forecastSagas.watcher),
    spawn(zoneManagementSagas.watcher),
  ]);
}
