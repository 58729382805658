import { LOG_SAGA_TIME, SLOW_DOWN_SAGA } from 'config/app';
import Glue from 'Glue';
import { call, delay, put, SagaReturnType } from 'redux-saga/effects';
import { loadingActions } from 'store/glue/loading';

const SLOW_REQUEST = 500;

/**
 * call this function with an effect to achieve desired result.
 * most case, it will be called with a simple call
 */

function* sagaWrapper(args: {
  saga: () => Generator<any, /*FunctionalEvent |*/ void>;

  type: Glue.LoadingType;
  logTime?: boolean;
}) {
  const { saga, type } = args;
  const { logTime = false } = args;

  const startTime = new Date().getTime();

  let event: SagaReturnType<typeof saga>;

  try {
    yield put(loadingActions.updateLoadingStatus({ type, status: 'loading' }));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event = yield call(saga);
    // Slow down
    const excecutionTime = new Date().getTime() - startTime;
    if (SLOW_DOWN_SAGA && excecutionTime < SLOW_REQUEST)
      yield delay(SLOW_REQUEST - excecutionTime);

    yield put(
      loadingActions.updateLoadingStatus({ type, status: 'succeeded' }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(
      loadingActions.updateLoadingStatus({
        type,
        status: 'failed',
        error,
      }),
    );
  } finally {
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    yield put(loadingActions.updateLoadingStatus({ type, status: 'idle' }));
    if (logTime || LOG_SAGA_TIME)
      // eslint-disable-next-line no-console
      console.log(`EVENT: ${type} took ${duration}ms`);
  }
}

export { sagaWrapper };
