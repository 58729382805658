import {
  EmployeeAction,
  employeeActions as actions,
  EmployeeState,
} from 'store/data/employee';
import { getType } from 'typesafe-actions';
import { domainUtility } from 'utility';

const initialState: EmployeeState = { allIds: [], byId: {} };

const employeeReducer = (
  state: EmployeeState = initialState,
  action: EmployeeAction,
): EmployeeState => {
  switch (action.type) {
    case getType(actions.add):
      return domainUtility.add(state, action.payload);
    case getType(actions.set):
      return domainUtility.set(state, action.payload);
    case getType(actions.update):
      return domainUtility.update(state, action.payload);
    case getType(actions.remove):
      return domainUtility.remove(state, action.payload);
    case getType(actions.setDomain):
      const { allIds, byId } = action.payload;
      return { allIds, byId };
    default:
      return state;
  }
};

export default employeeReducer;
