import * as R from 'remeda';
import { createSelector } from 'reselect';
import { activitySelectors } from 'store/data/activity';
import { subZoneSelectors } from 'store/data/subZone';
import { zoneSelectors } from 'store/data/zone';
import Types from 'Types';
import { timeUtility } from 'utility';
import { misc } from 'variables';

const domain = (state: Types.RootState) => state.page.dashboard;

const pivotById = createSelector(domain, (domain) => domain.pivotById);

const selectedWeek = createSelector(domain, (domain) => {
  const label = timeUtility.format(
    domain.selectedWeek,
    'dashboard-pivot-table-cell-header',
  );

  const labeledDate: Time.LabeledDate = { ...domain.selectedWeek, name: label };

  return labeledDate;
});
const selectedDay = createSelector(domain, (domain) => {
  const label = timeUtility.format(
    domain.selectedDay,
    'dashboard-pivot-table-cell-header',
  );

  const labeledDate: Time.LabeledDate = { ...domain.selectedDay, name: label };

  return labeledDate;
});

/**
 * Generate pivot strucuture for KPI dashboard page.
 * The one data object which knows all the connections is Data.Activity
 */
const pivotStructure = createSelector(
  zoneSelectors.byId,
  activitySelectors.list,
  subZoneSelectors.byId,

  (zoneById, activities, subZoneById) => {
    const pivotTable: PivotTable.Row[] = [];

    // Organize activities by zoneId
    const activitiesByZone = R.pipe(
      activities,
      R.groupBy((activity) => activity.zoneId ?? misc.MISSING),
    );

    function subZoneRows(subZoneIds: string[], ancestorIds: string[]) {
      const rows: PivotTable.Row[] = R.pipe(
        subZoneIds,
        R.map((subZoneId) => {
          // extract subzone
          const subZone = subZoneById[subZoneId.toString()];
          // early return, if not defined
          if (!R.isDefined(subZone)) return;

          const row: PivotTable.Row = {
            id: subZone.id,
            name: `${subZone.code} - ${subZone.name}`,
            ancestorIds,
            indentLevel: 3,
          };

          return row;
        }),
        R.filter(R.isDefined),
        R.sortBy((row) => row.name),
      );
      return rows;
    }

    function activityRows(activities: Data.Activity[], ancestorIds: string[]) {
      const rows: PivotTable.Row[] = R.pipe(
        activities,
        R.sortBy<Data.Activity>(
          [(activity) => !R.isDefined(activity.subZoneIds), 'desc'],
          (activity) => activity.code,
        ),
        R.map((activity) => {
          const childRows = R.isDefined(activity.subZoneIds)
            ? subZoneRows(activity.subZoneIds, [...ancestorIds, activity.id])
            : undefined;

          const row: PivotTable.Row = {
            id: activity.id,
            name: `${activity.code} - ${activity.name}`,
            ancestorIds,
            childRows,
            indentLevel: 2,
          };
          return row;
        }),
      );
      return rows;
    }

    // Main iteration for activities in zone
    R.pipe(
      activitiesByZone,
      R.forEachObj.indexed((activities, zoneId) => {
        // extract zone

        const zone = zoneById[zoneId.toString()];
        // early return, if not defined
        if (!R.isDefined(zone)) return;

        pivotTable.push({
          id: zone.id,
          name: zone.name,
          ancestorIds: [],
          childRows: activityRows(activities, [zone.id]),
          indentLevel: 1,
        });
      }),
    );

    return pivotTable;
  },
);

const displayRows = createSelector(
  pivotStructure,
  pivotById,
  (pivotStructure, pivotById) => {
    function recursiveAddRows(
      rows: PivotTable.Row[],
      returnList: PivotTable.Row[],
    ) {
      rows.forEach((row) => {
        returnList.push(row);
        const pivotted = R.isTruthy(pivotById[row.id]);
        if (pivotted && row.childRows)
          recursiveAddRows(row.childRows, returnList);
      });
    }

    const returnRows: PivotTable.Row[] = [];

    recursiveAddRows(pivotStructure, returnRows);

    return returnRows;
  },
);

export { displayRows, pivotById, pivotStructure, selectedDay, selectedWeek };
