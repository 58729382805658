import * as R from 'remeda';
import { timeUtility } from 'utility';

type DateButtonProps = {
  text: string;
  pressed: boolean;
  onClick(): void;
};

function getPressed(date: Time.Date, selectedDates: Time.Date[]) {
  const pressed = R.pipe(
    selectedDates,
    R.find((selectedDate) => {
      const notThis = R.pipe(
        date,
        R.mapValues((value, key) => selectedDate[key] === date[key]),
        R.values,
        (values) => values.some((value) => value === false),
      );
      return !notThis;
    }),
  );

  return pressed;
}

function getButtonProps(
  date: Time.Date,
  selectedDates: Time.Date[],
  onSelectDate: (date: Time.Date) => void,
): DateButtonProps {
  const selectedDate = getPressed(date, selectedDates);

  const pressed = R.isDefined(selectedDate);
  const text = timeUtility.format(date, 'calendar');

  return {
    text,
    pressed,
    onClick: () => onSelectDate(selectedDate ?? { ...date }),
  };
}

export { getButtonProps, getPressed };
