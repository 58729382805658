import { useTheme } from '@mui/material';
import { Card, ExpandButton, StickyCard, Table, TableCell } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { flexHoursActions, flexHoursSelectors } from 'store/page/flexHours';
import { ScrollableView } from 'templates';
import Types from 'Types';

import DateTableRow from './DateTableRow';

const headers: (LabelItem<KPI.Key> & { columns?: number })[] = [
  { id: 'hours', name: 'hours' },
];

const mapStateToProps = (state: Types.RootState) => ({
  selectedDates: flexHoursSelectors.labeledSelectedDates(state),
  displayRows: flexHoursSelectors.displayRows(state),
  pivotById: flexHoursSelectors.pivotById(state),
});

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  togglePivot: (rowId: string) =>
    dispatch(flexHoursActions.togglePivot({ id: rowId })),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;

const PivotView: FunctionComponent<Props> = (props) => {
  const { displayRows, pivotById, togglePivot, selectedDates } = props;

  const theme = useTheme();
  return (
    <ScrollableView>
      <StickyCard
        sx={(theme) => ({ width: theme.sizes[610] })}
        title="Employee Activities"
      >
        <Table
          rows={displayRows}
          renderRow={(row, alternatingBackground) => {
            const canPivot =
              R.isDefined(row.childRows) && row.childRows.length !== 0;
            const key = `${R.last(row.ancestorIds) ?? ''}-${row.id}`;

            return (
              <TableCell
                key={key}
                theme={theme}
                lastInLine
                justifyContent="space-between"
                alternatingBackground={alternatingBackground}
              >
                <ExpandButton
                  onClick={() => togglePivot(row.id)}
                  expanded={R.isTruthy(pivotById[row.id])}
                  label={row.name}
                  disabled={!canPivot}
                  indentLevel={row.indentLevel}
                  noExpand={!canPivot}
                />
              </TableCell>
            );
          }}
        />
      </StickyCard>

      {selectedDates.map((selectedDate) => (
        <Card
          key={selectedDate.id}
          sx={(theme) => ({ width: theme.sizes[377] })}
          title={selectedDate.name}
        >
          <Table
            headers={headers}
            rows={displayRows}
            renderRow={(row, alternatingBackground, headers) => {
              const key = `${R.last(row.ancestorIds) ?? ''}-${row.id}`;
              return (
                <DateTableRow
                  key={key}
                  dateId={selectedDate.id}
                  row={row}
                  alternatingBackground={alternatingBackground}
                  headers={headers}
                  theme={theme}
                />
              );
            }}
          />
        </Card>
      ))}
    </ScrollableView>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PivotView);
