import { DETECT_MUTATIONS } from 'config/app';
import { IS_DEVELOPMENT } from 'config/env';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore, // ! it's legacy, not deprecated. Redux as is will never be replaced by redux-toolkit.
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import mutationDetector from 'redux-immutable-state-invariant';
import createSagaMiddleware from 'redux-saga';
import Types from 'Types';

import rootReducer from './root-reducer';
import { rootSaga } from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares =
  IS_DEVELOPMENT && DETECT_MUTATIONS
    ? [sagaMiddleware, mutationDetector()]
    : [sagaMiddleware];

// In QA environments, we still want devtools
const enhancer = IS_DEVELOPMENT
  ? composeWithDevTools(applyMiddleware(...middlewares))
  : compose(applyMiddleware(...middlewares));

function configureStore() {
  const store = createStore(rootReducer, enhancer) as Store<
    Types.RootState,
    Types.RootAction
  >;

  sagaMiddleware.run(rootSaga);

  return store;
}

export { configureStore };
