import { ChartData } from 'chart.js';
import { generateDataset } from 'components/Chart/helpers';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';
import { kpiUtility, timeUtility } from 'utility';

import { activityHourSelectors } from './activityHour';
import { externalSelectors } from './external';
import { forecastSelectors } from './forecast';
import { internalSelectors } from './internal';
import { predictionSelectors } from './prediction';
export const domain = (state: Types.RootState) => state.aggregated.activityHour;

export const kpi = createSelector(
  activityHourSelectors.kpi,
  internalSelectors.kpi,
  externalSelectors.kpi,
  forecastSelectors.kpi,
  predictionSelectors.kpi,
  (
    { hours, modifiedHours },
    { noInternalBacklog, noInternalProcessed, noInternalReceived, target },
    { noExternalReceived },
    { forecast, forecastHistory },
    { prediction, predictionHistory },
  ) => {
    const {
      actual,
      hoursFromGoal,
      forecastDeviation,
      predictionDeviation,
    }: KPI.AggregatedValues = {
      actual: kpiUtility.calculateProductivity(
        noInternalProcessed,
        modifiedHours,
      ),
      hoursFromGoal: kpiUtility.calculateHoursFromGoal(
        noInternalProcessed,
        modifiedHours,
        target,
      ),
      forecastDeviation: kpiUtility.calculateDeviation(
        forecast,
        noExternalReceived,
      ),
      predictionDeviation: kpiUtility.calculateDeviation(
        prediction,
        noInternalReceived,
      ),
    };

    const kpis: KPI.Values = {
      hours,
      modifiedHours,
      noExternalReceived,
      actual,
      forecastHistory,
      forecast,

      hoursFromGoal,
      noInternalBacklog,
      noInternalProcessed,
      noInternalReceived,
      target,
      prediction,
      predictionHistory,
      forecastDeviation,
      predictionDeviation,
      projectionBaseDeviation: 0,
    };

    return kpis;
  },
);

// TODO: don't know how we will treat this, so no point in making it beautiful
// ! Only works for days
function uglyHistoryDateIds(currentDateIds: string[]): string[] {
  const days = currentDateIds.length;
  const dateIds: string[] = [];
  R.times(days, (index) => {
    if (index === 0) {
      const timeDate = timeUtility.dateFromId(currentDateIds[0] ?? '');
      if (!R.isDefined(timeDate)) return;
      if (timeDate.type !== 'day') return;

      let historicTimeDate = timeUtility.step({
        timeDate,
        step: 'previous',
        stepSize: 'year',
      });

      // 0 + 1 = 1
      if (historicTimeDate.dayOfWeek === 0) {
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'next',
          stepSize: 'day',
        });
      }
      // 2 - 1 = 1;
      else if (historicTimeDate.dayOfWeek === 2) {
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'previous',
          stepSize: 'day',
        });
      }
      // 3  - 2 = 1
      else if (historicTimeDate.dayOfWeek === 3) {
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'previous',
          stepSize: 'day',
        });
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'previous',
          stepSize: 'day',
        });
        // 4 - 3 = 1;
        // 4 + 3 = 1; .... hur ska jag tänka
      } else if (historicTimeDate.dayOfWeek === 4) {
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'previous',
          stepSize: 'day',
        });
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'previous',
          stepSize: 'day',
        });
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'previous',
          stepSize: 'day',
        });
      }
      // 5 + 2 = 1;
      else if (historicTimeDate.dayOfWeek === 5) {
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'next',
          stepSize: 'day',
        });
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'next',
          stepSize: 'day',
        });
        // 6 + 1 = 1
      } else if (historicTimeDate.dayOfWeek === 6) {
        historicTimeDate = timeUtility.step({
          timeDate: historicTimeDate,
          step: 'next',
          stepSize: 'day',
        });
      }

      dateIds.push(historicTimeDate.id);
    } else {
      const previousDateId = dateIds[index - 1];
      if (!R.isDefined(previousDateId)) return;

      const previousDay = timeUtility.dateFromId(previousDateId);

      if (!R.isDefined(previousDay)) return;
      if (previousDay.type !== 'day') return;

      const newDay = timeUtility.step({
        timeDate: previousDay,
        step: 'next',
        stepSize: 'day',
      });
      dateIds.push(newDay.id);
    }
  });
  return dateIds;
}

/**
 * Idea is to call this one to get all relevant chartData
 * Rewrite a bit more to not render that often?
 */
export const chartData = createSelector(
  (
    state: Types.RootState,
    ownProps: { datasets: Charts.Dataset[]; dateIds: string[] },
  ) => {
    const chartData: ChartData = {
      datasets: [],
      labels: ownProps.dateIds.map((_dateId, index) => index + 1), // Necessary since scatter relies on labels are parsable to numbers
    };

    chartData.datasets = ownProps.datasets.map((dataset) => {
      let dateIds: string[] = [];
      if (dataset.historic) {
        dateIds = uglyHistoryDateIds(ownProps.dateIds);
      } else {
        dateIds = ownProps.dateIds;
      }

      const data = dateIds.map((dateId) => {
        const kpiValue = kpi(state, {
          dateId,
          entityIds: dataset.entityIds,
          filterEntityIds: dataset.filterEntityIds,
        })[dataset.kpi];

        return kpiValue;
      });

      return generateDataset(dataset, data);
    });

    return chartData;
  },
  (chartData) => {
    return chartData;
  },
);
