import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Divider,
  InputAdornment,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { darkTheme, lightTheme } from 'material';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';

type InputSelectItem = LabelItem & {
  isCategory?: boolean;
  children?: LabelItem[];
};

type Props = {
  id: string; // accessibility
  label?: string;
  value: string | string[];
  endAdornment?: React.ReactNode;
  select?: {
    multiple?: boolean;
    items: InputSelectItem[];
    renderValue?(value: unknown): string;
  };
  onChange(value: string | string[]): void;
  autoFocus?: boolean;
};

const Input: FunctionComponent<Props> = (props) => {
  const {
    label,
    id,
    onChange,
    select,
    value,
    endAdornment,
    autoFocus = false,
  } = props;
  const theme = useTheme();

  const invertedTheme = theme.palette.mode === 'dark' ? lightTheme : darkTheme;
  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const value = e.target.value;
    onChange(value);
  }

  const generateMenuItems = (
    returnArray: React.ReactNode[],
    selectItems?: InputSelectItem[],
  ) => {
    selectItems?.forEach((item, index) => {
      const { id, name, children, isCategory } = item;

      const hasDivider = isCategory && index !== selectItems.length - 1;

      if (isCategory)
        returnArray.push(
          <ListSubheader key={id}>
            <Typography variant="header4">{name}</Typography>
          </ListSubheader>,
        );
      else
        returnArray.push(
          <MenuItem key={id} value={id} sx={{ height: theme.sizes[34] }}>
            <Typography variant="action2">{name}</Typography>
          </MenuItem>,
        );

      generateMenuItems(returnArray, children);

      if (hasDivider)
        returnArray.push(
          <Divider
            key={R.randomString(10)}
            // sx={{ borderColor: invertedPalette.divider }}
          />,
        );
    });

    return returnArray;
  };
  // MuiList-root MuiList-padding MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-3rxc4d-MuiList-root-MuiPaper-root
  // MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list
  const dropdown = generateMenuItems([], select?.items);

  const MenuListWrapper = React.forwardRef<any>((props, ref) => {
    const { className, ...rest } = props as any;
    return (
      <ThemeProvider theme={invertedTheme}>
        <Paper {...rest} ref={ref} component={MenuList} />
      </ThemeProvider>
    );
  });
  MenuListWrapper.displayName = 'SomeContent';
  // MenuListWrapper.muiName = Paper.name;

  return (
    <TextField
      id={id}
      label={label}
      select={R.isDefined(select)}
      onChange={handleChange}
      variant="standard"
      autoFocus={autoFocus}
      size="medium"
      value={value}
      autoComplete="off"
      color="primary"
      InputProps={{
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        sx: {
          ...theme.typography.text2,
          color: 'inherit',
        },
      }}
      InputLabelProps={{
        sx: {
          pl: theme.distances.tiny,
          ...theme.typography.action2,
          color: 'inherit',
          textTransform: 'capitalize',
        },
      }}
      SelectProps={{
        IconComponent: KeyboardArrowDown,
        MenuProps: {
          MenuListProps: {
            component: MenuListWrapper,
          },
        },
        renderValue: select?.renderValue,
        sx: {
          ...theme.typography.action2,
          '.MuiSelect-icon': {
            fill: theme.palette.primary.main,
          },
        },
        multiple: select?.multiple,
      }}
      sx={{
        '& .MuiInputBase-input': { p: theme.distances.tiny }, // Just looks better
        width: theme.sizes[377],
        maxWidth: '100%',
      }}
    >
      {dropdown}
    </TextField>
  );
};

export { Input, InputSelectItem };
