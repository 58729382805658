import { Theme } from '@mui/material';
import { InputSelectItem } from 'components';
import * as R from 'remeda';

export type ProjectionChart =
  | 'external-forecast-vs-recieved'
  | 'external-forecast-accuracy'
  | 'external-history'
  | 'internal-prediction-vs-recieved'
  | 'internal-prediction-accuracy'
  | 'internal-history'
  | 'internal-accumulated';

const externalCharts: LabelItem<ProjectionChart>[] = [
  { id: 'external-forecast-vs-recieved', name: 'Recieved' },
  { id: 'external-forecast-accuracy', name: 'Accuracy' },
  { id: 'external-history', name: 'History' },
];

const internalCharts: LabelItem<ProjectionChart>[] = [
  { id: 'internal-prediction-vs-recieved', name: 'Recieved' },
  { id: 'internal-prediction-accuracy', name: 'Accuracy' },
  { id: 'internal-history', name: 'History' },
  { id: 'internal-accumulated', name: 'Accumulated' },
];
const charts: InputSelectItem[] = [
  {
    id: 'external',
    name: 'External',
    isCategory: true,
    children: externalCharts,
  },
  {
    id: 'internal',
    name: 'Internal',
    isCategory: true,
    children: internalCharts,
  },
];

function generateDatasets(
  entityId: string,
  chart: ProjectionChart,
  theme: Theme,
): Charts.Dataset[] {
  const datasets: Charts.Dataset[] = [];

  // Is external or internal
  const isExternal = R.pipe(
    chart.split('-'),
    R.first(),
    (a) => a === 'external',
  );
  switch (chart) {
    case 'external-forecast-vs-recieved':
    case 'internal-prediction-vs-recieved':
      // External / Customers world?
      datasets.push({
        id: 'recieved',
        color: theme.palette.datasets.line,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: isExternal ? 'noExternalReceived' : 'noInternalReceived',
        name: 'Recieved',
        type: 'line',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });
      datasets.push({
        id: 'projection',
        color: theme.palette.datasets.scatter,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: isExternal ? 'forecast' : 'prediction',
        name: isExternal ? 'Forecast' : 'Prediction',
        type: 'scatter',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });

      datasets.push({
        id: 'projectionHistory',
        color: theme.palette.datasets.scatter_secondary,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: isExternal ? 'forecastHistory' : 'predictionHistory',
        name: isExternal ? 'Forecast history' : 'Prediction history',
        type: 'scatter',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });

      break;
    case 'external-forecast-accuracy':
    case 'internal-prediction-accuracy':
      datasets.push({
        id: 'projectionDeviation',
        color: theme.palette.datasets.line,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: isExternal ? 'forecastDeviation' : 'predictionDeviation',
        name: isExternal ? 'Forecast deviation' : 'Prediction deviation',
        type: 'line-fill-to-dataset',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });

      datasets.push({
        id: 'deviation',
        color: theme.palette.datasets.stepped,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: 'projectionBaseDeviation',
        name: 'Baseline',
        type: 'stepped-line',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });

      break;

    case 'external-history':
    case 'internal-history':
      datasets.push({
        id: 'recieved',
        color: theme.palette.datasets.line,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: isExternal ? 'noExternalReceived' : 'noInternalReceived',
        name: 'Recieved',
        type: 'line',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });

      datasets.push({
        id: 'recieved-history',
        color: theme.palette.datasets.line_secondary,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: isExternal ? 'noExternalReceived' : 'noInternalReceived',
        historic: true,
        name: 'Recieved, historic',
        type: 'line',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });
      break;

    case 'internal-accumulated':
      datasets.push({
        id: 'recieved',
        color: theme.palette.datasets.line,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: 'noInternalReceived',
        name: 'Recieved',
        type: 'grouped-bar',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });

      datasets.push({
        id: 'backlog',
        color: theme.palette.datasets.line_secondary,
        entityIds: [entityId],
        filterEntityIds: [],
        kpi: 'noInternalBacklog',
        name: 'Backlog',
        type: 'grouped-bar',
        xAxisId: 'xAxis',
        yAxisId: 'yAxisPrimary',
      });
      break;
  }

  return datasets;
}

export { charts, externalCharts, generateDatasets, internalCharts };
