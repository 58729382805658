import {
  ChartPaletteColorOptions,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material';
import {
  amber,
  blueGrey,
  deepOrange,
  green,
  indigo,
  lightBlue,
  lightGreen,
  pink,
  red,
  yellow,
} from '@mui/material/colors';
import createPalette from '@mui/material/styles/createPalette';

export const primary: SimplePaletteColorOptions = {
  main: lightBlue['500'],
  dark: lightBlue['800'],
  light: lightBlue['200'],
  contrastText: lightBlue['50'],
};

export const secondary: SimplePaletteColorOptions = {
  main: amber['500'],
  dark: amber['800'],
  light: amber['200'],
  contrastText: '#000',
};

export const error: SimplePaletteColorOptions = {
  main: red['400'],
  dark: red['700'],
  light: red['200'],
  contrastText: red['50'],
};

export const success: SimplePaletteColorOptions = {
  main: lightGreen['400'],
  dark: lightGreen['700'],
  light: lightGreen['200'],
  contrastText: lightGreen['50'],
};

export const warning: SimplePaletteColorOptions = {
  main: yellow['600'],
  dark: yellow['800'],
  light: yellow['400'],
  contrastText: yellow['50'],
};

// ! To clearly show the developers that we are not using some variants.
const DONT_USE_COLOR: SimplePaletteColorOptions = {
  main: '#000',
  dark: '#000',
  light: '#000',
  contrastText: '#000',
};

const datasets: ChartPaletteColorOptions = {
  line: indigo[400],
  line_secondary: lightGreen[400],
  stepped: pink[400],
  complimentary_bar: blueGrey[400],
  scatter: green[400],
  scatter_secondary: deepOrange[400],
};

const darkPalette = createPalette({
  mode: 'dark',
  datasets,
  primary,
  secondary,
  error,
  success,
  warning,
  background: { paper: blueGrey[700], default: '#4A616B' },
  info: DONT_USE_COLOR,
});

const lightPalette: PaletteOptions = {
  mode: 'light',
  datasets,
  primary,
  secondary,
  error,
  success,
  warning,
  background: { paper: '#ffffff', default: '#f4f5fa' },
  info: DONT_USE_COLOR,
};

export { darkPalette, lightPalette };
