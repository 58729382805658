import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import * as R from 'remeda';
import {
  activityHourActions,
  activityHourSelectors,
} from 'store/aggregated/activityHour';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';
import { arrayUtility } from 'utility';
import { mutateSet } from 'utility/domain';

import * as dashboardActions from './actions';
import * as dashboardConstants from './constants';

/**
 * This will update modifiedSpentTime which will recalculate view
 */
function* startToggleHourSaga(
  action: ActionType<typeof dashboardActions.startToggleHours>,
) {
  function* saga() {
    const { dateId, entityId, isOff } = action.payload;

    // Get Activity hour ids on clicked date
    const byDate: ReturnType<typeof activityHourSelectors.byDate> =
      yield select(activityHourSelectors.byDate);
    const dateIds = byDate[dateId];
    if (!R.isDefined(dateIds)) return;

    const byEntity: ReturnType<typeof activityHourSelectors.byEntity> =
      yield select(activityHourSelectors.byEntity);
    const entityIds = byEntity[entityId];
    if (!R.isDefined(entityIds)) return;

    const intersectingIds = arrayUtility.intersectingIds(dateIds, entityIds);

    const domain: ReturnType<typeof activityHourSelectors.domain> =
      yield select(activityHourSelectors.domain);

    intersectingIds.forEach((id) => {
      const item = domain.byId[id];
      // will not happen
      if (!R.isDefined(item)) throw 'will not happen';

      if (isOff) item.modifiedSpentTime = item.spentTime;
      else item.modifiedSpentTime = 0;

      mutateSet(domain, item);
    });

    yield put(activityHourActions.setDomain(domain));
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([
    takeLeading(dashboardConstants.START_TOGGLE_HOURS, startToggleHourSaga),
  ]);
}
