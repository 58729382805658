import { Box, Stack } from '@mui/material';
import { Modal } from 'components';
import React, { FunctionComponent } from 'react';

type Props = {
  isOpen: boolean;
  imageSource?: string;
  onClose(): void;
};

const TableRowChartModal: FunctionComponent<Props> = (props) => {
  const { onClose, isOpen, imageSource } = props;

  return (
    <Modal title="Image" isOpen={isOpen} onClose={onClose} fullWidth>
      <Stack width="100%" justifyContent="center" alignItems="center">
        <Box
          sx={(theme) => ({
            height: theme.sizes[610],
            width: theme.sizes[610],
            backgroundColor: theme.palette.background.default,
            padding: theme.distances.small,
            // borderRadius: theme.shape.borderRadius,
            border: 2,
            borderColor: theme.palette.divider,
          })}
        >
          <Box
            component="img"
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'scale-down',
              justifySelf: 'center',
            }}
            alt="issue image"
            src={imageSource}
          />
        </Box>
      </Stack>
    </Modal>
  );
};

export default TableRowChartModal;
