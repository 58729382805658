import { CircularProgress, Stack } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'store/glue/user';
import Types from 'Types';

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  startLogin: () => dispatch(userActions.startLogin()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = DispatchProps;

let isInitialized = false;

const LoginPage: FunctionComponent<Props> = (props) => {
  const { startLogin } = props;

  useEffect(() => {
    if (isInitialized) return;
    isInitialized = true;
    startLogin();
  }, [startLogin]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100%' }}
    >
      <CircularProgress />
    </Stack>
  );
};

export default connect(null, mapDispatchToProps)(LoginPage);
