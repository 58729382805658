import { createTheme } from '@mui/material';

import { components } from './components';
import { distances } from './distances';
import { darkPalette, lightPalette } from './palette';
import { sizes } from './sizes';
import { fontFamily, typography } from './typography';

const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  components,
  sizes,
  distances,
});

const darkTheme = createTheme({
  palette: darkPalette,
  typography,
  components,
  sizes,
  distances,
});

export { darkTheme, fontFamily, lightTheme };
