type Fibonacci =
  | '_1'
  | '_2'
  | '_3'
  | '_5'
  | '_8'
  | '_13'
  | '_21'
  | '_34'
  | '_55'
  | '_89'
  | '_144'
  | '_233'
  | '_377'
  | '_610'
  | '_987'
  | '_1597';

const spacing: Record<Fibonacci, `${number}px`> = {
  _1: '1px',
  _2: '2px',
  _3: '3px',
  _5: '5px',
  _8: '8px',
  _13: '13px',
  _21: '21px',
  _34: '34px',
  _55: '55px',
  _89: '89px',
  _144: '144px',
  _233: '233px',
  _377: '377px',
  _610: '610px',
  _987: '987px',
  _1597: '1597px',
};

export { spacing };
