import {
  SuggestionAction,
  suggestionActions as actions,
  SuggestionState,
} from 'store/data/suggestion';
import { getType } from 'typesafe-actions';
import { domainUtility } from 'utility';

const initialState: SuggestionState = { allIds: [], byId: {} };

const suggestionReducer = (
  state: SuggestionState = initialState,
  action: SuggestionAction,
): SuggestionState => {
  switch (action.type) {
    case getType(actions.add):
      return domainUtility.add(state, action.payload);
    case getType(actions.set):
      return domainUtility.set(state, action.payload);
    case getType(actions.update):
      return domainUtility.update(state, action.payload);
    case getType(actions.remove):
      return domainUtility.remove(state, action.payload);
    case getType(actions.setDomain):
      const { allIds, byId } = action.payload;
      return { allIds, byId };
    default:
      return state;
  }
};

export default suggestionReducer;
