import { DOMAIN_TYPE } from '../constants';

const DOMAIN_NAME = 'forecast';

export const SET_SELECTED_WEEK =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_SELECTED_WEEK` as const;

export const START_CREATE_FORECAST =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_CREATE_FORECAST` as const;

export const START_CREATE_PREDICTION =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_CREATE_PREDICTION` as const;
