import { Theme } from '@mui/material';
import { Card, ExpandButton, Table } from 'components';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { suggestionSelectors } from 'store/data/suggestion';
import Types from 'Types';

import ImageModal from './ImageModal';
import SuggestionRowContent from './SuggestionRowContent';

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => ({
  suggestion: suggestionSelectors.item(state, { id: ownProps.suggestionId }),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

type OwnProps = {
  suggestionId: string;
  theme: Theme;
  headers: (LabelItem<keyof Data.Suggestion> & { columns: number })[];
};

const Suggestion: FunctionComponent<Props> = (props) => {
  const { suggestion, theme, headers } = props;
  const [expanded, setExpanded] = useState(false);

  const [modalImageSource, setModalImageSource] = useState<string>();
  if (!R.isDefined(suggestion)) return null;

  return (
    <>
      <ImageModal
        imageSource={modalImageSource}
        isOpen={R.isDefined(modalImageSource)}
        onClose={() => setModalImageSource(undefined)}
      />
      <Card
        key={suggestion.id}
        title={suggestion.name}
        sx={{ width: '100%' }}
        largeHeader
        action={
          <ExpandButton
            expanded={expanded}
            label={null}
            onClick={() => setExpanded((expanded) => !expanded)}
            large
          />
        }
      >
        {expanded && (
          <Table
            headers={headers}
            renderRow={(row, alternatingBackground, headers) => (
              <SuggestionRowContent
                key={row.id}
                alternatingBackground={alternatingBackground}
                headers={headers as any}
                theme={theme}
                suggestion={suggestion}
                setModalImageSource={setModalImageSource}
              />
            )}
            rows={[
              {
                id: suggestion.id,
                name: suggestion.name,
                ancestorIds: [],
                indentLevel: 0,
                childRows: [],
              },
            ]}
          />
        )}
      </Card>
    </>
  );
};

export default connect(mapStateToProps)(Suggestion);
