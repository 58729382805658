import { Button, Stack, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  activityActions,
  activitySelectors,
  ActivityState,
} from 'store/data/activity';
import {
  zoneManagementActions,
  zoneManagementSelectors,
} from 'store/page/zoneManagement';
import { TabsPage } from 'templates';
import { Types } from 'Types';

import View from './View';
import ChangeModal from './View/ChangeModal';

const mapStateToProps = (state: Types.RootState) => ({
  activityDomain: activitySelectors.domain(state),
  activityDomainDiff: zoneManagementSelectors.activityDomainDiff(state),
  editedActivityDomain: zoneManagementSelectors.editedActivityDomain(state),
});

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  init: (activity: ActivityState) =>
    dispatch(zoneManagementActions.init({ activity })),
  abort: (activity: ActivityState) =>
    dispatch(zoneManagementActions.discard({ activity })),

  saveChanges: (domain: NormalizedDomain<Data.Activity>) => {
    dispatch(activityActions.setDomain(domain));
  },
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;

const ZoneManagement: FunctionComponent<Props> = ({
  activityDomain,
  activityDomainDiff,
  init,
  abort,
  saveChanges,
  editedActivityDomain,
}) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = React.useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = React.useState(false);

  useEffect(() => {
    if (activityDomain) {
      init(activityDomain);
    }
  }, [activityDomain, init]);

  return (
    <TabsPage
      pageActions={
        <>
          <ChangeModal
            title="Discard"
            isOpen={isDiscardModalOpen}
            activityDomainDiff={activityDomainDiff}
            onClose={() => setIsDiscardModalOpen(false)}
            onConfirm={() => abort(activityDomain)}
          />
          <ChangeModal
            title="Save"
            isOpen={isSaveModalOpen}
            activityDomainDiff={activityDomainDiff}
            onClose={() => setIsSaveModalOpen(false)}
            onConfirm={() => saveChanges(editedActivityDomain)}
          />
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              disabled={!Object.keys(activityDomainDiff).length}
              onClick={() => setIsDiscardModalOpen(true)}
            >
              <Typography variant="action1">Discard</Typography>
            </Button>
            <Button
              disableElevation
              variant="contained"
              disabled={!Object.keys(activityDomainDiff).length}
              onClick={() => setIsSaveModalOpen(true)}
            >
              <Typography variant="action1">Save</Typography>
            </Button>
          </Stack>
        </>
      }
      tabs={[
        {
          id: 'zoneview',
          name: 'Configure',
          component: <View />,
        },
      ]}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagement);
