import {
  ActivityHourAction,
  activityHourActions as actions,
  ActivityHourState,
} from 'store/aggregated/activityHour';
import { getType } from 'typesafe-actions';
import { domainUtility } from 'utility';

const initialState: ActivityHourState = { allIds: [], byId: {} };

const activityHourReducer = (
  state: ActivityHourState = initialState,
  action: ActivityHourAction,
): ActivityHourState => {
  switch (action.type) {
    case getType(actions.add):
      return domainUtility.add(state, action.payload);
    case getType(actions.set):
      return domainUtility.set(state, action.payload);
    case getType(actions.update):
      return domainUtility.update(state, action.payload);
    case getType(actions.remove):
      return domainUtility.remove(state, action.payload);
    case getType(actions.setDomain):
      const { allIds, byId } = action.payload;
      return { allIds, byId };
    default:
      return state;
  }
};

export default activityHourReducer;
