import { Button, Stack, Typography } from '@mui/material';
import { Modal } from 'components';
import React, { FunctionComponent } from 'react';
import { ActivityDomainDiff } from 'store/page/zoneManagement/selectors'; // TODO: move somewhere

type Props = {
  activityDomainDiff: ActivityDomainDiff;
  onClose: () => void;
  onConfirm: () => void;
  title: 'Save' | 'Discard';
  isOpen: boolean;
};

const ChangeModal: FunctionComponent<Props> = (props) => {
  const { onClose, isOpen, onConfirm, activityDomainDiff, title } = props;

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <Typography variant="action1">Abort</Typography>
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            disableElevation
            onClick={handleConfirm}
          >
            <Typography variant="action1">{title}</Typography>
          </Button>
        </>
      }
    >
      <Stack
        direction="column"
        sx={(theme) => ({ gap: theme.distances.medium })}
      >
        {Object.values(activityDomainDiff).map((value) => (
          <Stack
            key={value.activityName}
            direction="column"
            sx={(theme) => ({ gap: theme.distances.small })}
          >
            <Typography variant="header3">{value.activityName}</Typography>
            {value.changes.map((change, index) => (
              <Typography key={index} variant="text1">
                {`• ${change}`}
              </Typography>
            ))}
          </Stack>
        ))}
      </Stack>
    </Modal>
  );
  // return (
  //   <Dialog
  //     isOpen={isOpen}
  //     onClose={onClose}
  //     title={title}
  //     actions={

  //     }
  //   >

  //   </Dialog>
  // );
};

export default ChangeModal;
