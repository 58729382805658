import { Theme, Typography } from '@mui/material';
import { TableCell } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { employeeHourSelectors } from 'store/aggregated/employeeHour';
import Types from 'Types';
import { kpiUtility } from 'utility';

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => ({
  kpiValues: employeeHourSelectors.kpi(state, {
    dateId: ownProps.dateId,
    entityIds: [...ownProps.row.ancestorIds, ownProps.row.id],
  }),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  row: PivotTable.Row;
  dateId: string;
  alternatingBackground: boolean;
  headers?: (LabelItem & { columns?: number })[];
  theme: Theme;
};

type Props = OwnProps & StateProps;

const DateTableRow: FunctionComponent<Props> = (props) => {
  const { kpiValues, headers, theme, alternatingBackground } = props;
  if (!R.isDefined(headers)) return null;

  return (
    <>
      {headers.map((header, index) => (
        <TableCell
          key={header.id}
          theme={theme}
          lastInLine={index === headers.length - 1}
          alternatingBackground={alternatingBackground}
          columns={header.columns}
        >
          <Cell dateHeader={header as any} kpiValues={kpiValues} />
        </TableCell>
      ))}
    </>
  );
};

const Cell: FunctionComponent<{
  dateHeader: LabelItem<KPI.Key>;
  kpiValues: KPI.ActivityValues;
}> = (props) => {
  const { dateHeader, kpiValues } = props;
  switch (dateHeader.id) {
    case 'hours':
      return (
        <Typography variant="text2">
          {kpiUtility.readableHours(kpiValues[dateHeader.id])}
        </Typography>
      );
    default:
      return null;
  }
};

export default connect(mapStateToProps)(DateTableRow);
