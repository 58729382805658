import { createAction } from 'typesafe-actions';

import {
  SET_TOKEN,
  START_LOGIN,
  START_LOGOUT,
  START_VERIFY,
} from './constants';

export const setToken = createAction(
  SET_TOKEN,
  (payload: string | null) => payload,
)();

export const startLogin = createAction(START_LOGIN)();
export const startLogout = createAction(START_LOGOUT)();

export const startVerify = createAction(
  START_VERIFY,
  (payload: { savedToken?: string }) => payload,
)();
