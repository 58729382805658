const JANUARY = 0;
const DECEMBER = 11;
const MONDAY = 1;
const SUNDAY = 0;
const FIRST_DAY_OF_MONTH = 1;
const MS_DAY = 24 * 60 * 60 * 1000;
const MS_WEEK = MS_DAY * 7;

export type DisplayLocation =
  | 'calendar'
  | 'selected-list'
  | 'flexhour-pivot-table-cell-header'
  | 'dashboard-pivot-table-cell-header'
  | 'x-axis-productivity-modal'
  | 'x-axis'
  | 'x-axis-detailed'
  | 'stepper'
  | 'dashboard-selected-week'
  | 'forecast-selected-week'
  | 'forecast-table-day';

export type StrippedDate<T extends Time.Date> = Omit<T, 'id' | 'type'>;
const weekdayConverter: Record<number, string> = {
  0: 'sunday',
  1: 'monday',
  2: 'thuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

const shortMonthConverter: Record<number, string> = {
  0: 'jan',
  1: 'feb',
  2: 'mar',
  3: 'apr',
  4: 'may',
  5: 'jun',
  6: 'jul',
  7: 'aug',
  8: 'sep',
  9: 'oct',
  10: 'nov',
  11: 'dec',
};
const monthConverter: Record<number, string> = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
};

export {
  DECEMBER,
  FIRST_DAY_OF_MONTH,
  JANUARY,
  MONDAY,
  monthConverter,
  MS_DAY,
  MS_WEEK,
  shortMonthConverter,
  SUNDAY,
  weekdayConverter,
};
