import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';

type Props = {
  tabs: (LabelItem & { component: React.ReactNode })[];
  pageActions?: React.ReactNode;
};

const TabsPage: FunctionComponent<Props> = (props) => {
  const { pageActions, tabs } = props;
  const [value, setValue] = React.useState(0);

  if (!R.isDefined(value)) throw 'No tab passed to TabPage template';

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack direction="column" sx={{ height: '100%', width: '100%' }}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        sx={(theme) => ({
          pl: theme.distances.medium,
          pr: theme.distances.medium,
          pt: theme.distances.small,
          pb: theme.distances.small,
        })}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
          sx={{
            ['& .MuiTabs-indicator']: { height: 3 },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              label={
                <Typography
                  variant="action1"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {tab.name}
                </Typography>
              }
              id={tab.id}
            />
          ))}
        </Tabs>
        <Box>{pageActions}</Box>
      </Stack>
      {tabs.map((tab, index) => (
        <Box
          key={tab.id}
          role="tabpanel"
          hidden={value !== index}
          sx={{
            flexGrow: 1,
            height: 0,
          }}
        >
          {tab.component}
        </Box>
      ))}
    </Stack>
  );
};

export { TabsPage };
