import { PhotoSizeSelectActualOutlined } from '@mui/icons-material';
import { Button, Stack, Theme, Typography } from '@mui/material';
import { TableCell } from 'components';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';

type Props = {
  suggestion: Data.Suggestion;
  alternatingBackground: boolean;
  headers: (LabelItem<keyof Data.Suggestion> & { columns?: number })[];
  theme: Theme;
  setModalImageSource(source: string): void;
};

const SuggestionRowContent: FunctionComponent<Props> = (props) => {
  const {
    headers,
    theme,
    alternatingBackground,
    suggestion,
    setModalImageSource,
  } = props;

  if (!R.isDefined(headers)) return null;

  return (
    <>
      {headers.map((header, index) => (
        <TableCell
          key={header.id}
          theme={theme}
          lastInLine={index === headers.length - 1}
          alternatingBackground={alternatingBackground}
          columns={header.columns}
          sx={{
            alignItems: 'start',
            color: theme.palette.text.secondary,
            height: 'auto',
            padding: theme.distances.medium,
          }}
        >
          <Cell
            header={header}
            suggestion={suggestion}
            setModalImageSource={setModalImageSource}
          />
        </TableCell>
      ))}
    </>
  );
};

const Cell: FunctionComponent<{
  header: LabelItem<keyof Data.Suggestion>;
  suggestion: Data.Suggestion;
  setModalImageSource(source: string): void;
}> = (props) => {
  const { header, suggestion, setModalImageSource } = props;
  switch (header.id) {
    case 'description':
    case 'solution':
      return <Typography variant="text2">{suggestion[header.id]}</Typography>;

    case 'imageUris':
      return (
        <Stack sx={(theme) => ({ gap: theme.distances.tiny })}>
          {suggestion[header.id].map((imageUri, index) => (
            <Button
              key={index}
              onClick={() => setModalImageSource(imageUri)}
              variant="text"
              color="inherit"
              disableRipple
              endIcon={<PhotoSizeSelectActualOutlined />}
            >
              <Typography variant="action1">{`Image ${index + 1}`}</Typography>
            </Button>
          ))}
        </Stack>
      );

    default:
      return null;
  }
};

export default SuggestionRowContent;
