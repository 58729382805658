import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { Button, Icon, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = {
  expanded: boolean;
  label: string | number | null;
  onClick(event: React.MouseEvent<HTMLElement>): void;
  disabled?: boolean;
  indentLevel?: number;
  noExpand?: boolean;
  large?: boolean;
};

const ExpandButton: FunctionComponent<Props> = (props) => {
  const {
    onClick,
    indentLevel = 1,
    large = false,
    expanded,
    disabled = false,
    noExpand = false,
    label,
  } = props;

  const theme = useTheme();

  return (
    <Button
      disabled={disabled}
      color="inherit"
      variant="text"
      size={large ? 'large' : 'small'}
      disableRipple
      onClick={onClick}
      startIcon={
        !noExpand ? (
          expanded ? (
            <KeyboardArrowUpIcon color="primary" />
          ) : (
            <KeyboardArrowDownIcon color="primary" />
          )
        ) : (
          <Icon sx={{ opacity: 1 }} />
        )
      }
      sx={{
        minWidth: 'fit-content',
        svg: {
          borderRadius: 1,
          backgroundColor: theme.palette.primary.main,
          fill: theme.palette.primary.contrastText,
          stroke: theme.palette.primary.contrastText,
        },
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        paddingLeft: theme.distances.small * (indentLevel - 1),
        ':hover': {
          backgroundColor: 'inherit',
          '& .MuiTypography-root': {
            color: theme.palette.text.primary,
          },
        },
      }}
    >
      {label && (
        <Typography
          variant="action2"
          noWrap
          sx={{
            color: disabled
              ? theme.palette.text.disabled
              : theme.palette.text.secondary,
          }}
        >
          {label}
        </Typography>
      )}
    </Button>
  );
};

export { ExpandButton };
