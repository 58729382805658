import * as R from 'remeda';

function readableHours(hours: number | null) {
  if (!R.isDefined(hours)) return '';
  const integer = Math.floor(hours);
  const decimal = hours % 1;

  let minutes = Number((decimal * 60).toFixed(0));
  if (minutes < 0) minutes = minutes * -1;
  return `${integer}:${minutes.toString().padStart(2, '0')}`;
}

function readableValue(value: number | null) {
  if (!R.isDefined(value)) return '';

  const [integer, decimal] = value.toString().split('.');

  if (!R.isDefined(integer)) return '';

  const thousandSeparated = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add comma separator to the integer part
  return R.isDefined(decimal)
    ? [thousandSeparated, decimal].join('.')
    : thousandSeparated;
}

function sanitize(value: number) {
  value = Number(value.toFixed(2));

  if (value === 0) return null;
  return value;
}

function calculateProductivity(
  noPickingRows: number | null,
  hours: number | null,
) {
  // can't calculate productivity if value is missing or if something is 0
  if (!R.isTruthy(noPickingRows)) return null;
  if (!R.isTruthy(hours)) return null;

  const productivity = sanitize(noPickingRows / hours);

  return productivity;
}

function calculateHoursFromGoal(
  noPickingRows: number | null,
  hours: number | null,
  target: number | null,
) {
  if (!R.isTruthy(noPickingRows)) return null;
  if (!R.isTruthy(hours)) return null;
  if (!R.isTruthy(target)) return null;

  const hoursFromGoal = sanitize(noPickingRows / target - hours);

  return hoursFromGoal;
}

function calculateDeviation(
  projectedValue: number | null,
  actualValue: number | null,
) {
  if (!R.isTruthy(actualValue)) return null; // can't be zero

  if (!R.isDefined(projectedValue)) return null; // 0 is allowed

  const deviation = ((projectedValue - actualValue) / actualValue) * 100;
  return sanitize(deviation);
}

function latestProjection(projections: KPI.Projection[]) {
  // first in list is most current projection
  const currentProjection = R.pipe(projections, R.first());
  return R.isDefined(currentProjection) ? currentProjection.value : null;
}

export {
  calculateDeviation,
  calculateHoursFromGoal,
  calculateProductivity,
  latestProjection,
  readableHours,
  readableValue,
  sanitize,
};
