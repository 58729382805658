import { timeUtility } from 'utility';

function findTarget(
  targets: Data.ProductivityTarget[],
  date: Time.Date,
): number | null {
  let productivityTarget: number | null = null;

  targets.every((target) => {
    const targetTemporalPosition = timeUtility.temporalAssessor(
      target.date,
      date,
    );

    if (targetTemporalPosition === 'future') return false;

    productivityTarget = target.productivity;
    return true;
  });

  return productivityTarget;
}

export { findTarget };
