import * as R from 'remeda';

function add<T extends Record<'id', string>>(
  state: NormalizedDomain<T>,
  item: T,
): NormalizedDomain<T> {
  return {
    byId: R.addProp(state.byId, item.id, item),
    allIds: [...state.allIds, item.id],
  };
}

function update<T extends Record<'id', string>>(
  state: NormalizedDomain<T>,
  item: PartialWithId<T>,
): NormalizedDomain<T> {
  return {
    ...state,
    byId: R.set(state.byId, item.id, R.merge(state.byId[item.id], item)),
  };
}

function set<T extends Record<'id', string>>(
  state: NormalizedDomain<T>,
  item: T,
): NormalizedDomain<T> {
  return {
    byId: R.set(state.byId, item.id, item),
    allIds: state.allIds,
  };
}

function remove<T extends Record<'id', string>>(
  state: NormalizedDomain<T>,
  id: string,
): NormalizedDomain<T> {
  return {
    byId: R.omit(state.byId, [id]),
    allIds: R.reject(state.allIds, (i) => i === id),
  };
}

/**
 * Immutable update would be slower.
 * Since it could be alot of items,
 * and we're only interested in the final normalized domain.
 * @param normalizedDomain, the domain in question
 * @param item, current domainObject
 */
function mutateAdd<T extends BaseItem>(
  normalizedDomain: NormalizedDomain<T>,
  item: T,
) {
  const { allIds, byId } = normalizedDomain;

  const { id } = item;

  allIds.push(id);
  byId[id] = item;
}

function mutateSet<T extends BaseItem>(
  normalizedDomain: NormalizedDomain<T>,
  item: T, // ! if used from multiple locations in future, this should not be undefined
) {
  normalizedDomain.byId[item.id] = item;
}
export { add, mutateAdd, mutateSet, remove, set, update };
