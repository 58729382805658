import { Palette } from '@mui/material';
import { ChartDataset, ChartOptions, Point } from 'chart.js';
import { error, success } from 'material/palette';
import * as R from 'remeda';
import { arrayUtility, stylingUtility, timeUtility } from 'utility';

import {
  AXIS_OFFSET,
  AXIS_WIDTH,
  BAR_PERCENTAGE,
  CATEGORY_PERCENTAGE,
  chartColors,
  scaleFont,
} from './constants';

function xAxisCustom(
  config: Charts.XAxisConfigCustom,
  stacked: boolean,
): ChartOptions['scales'] {
  return {
    [config.id]: {
      stacked: stacked,
      display: false,
    },
  };
}

function xAxisDaysToWeek(
  config: Charts.XAxisConfigDaysToWeek,
  dateIds: string[],
  stacked: boolean,
  palette: Palette,
  xAxisHeight: number,
): ChartOptions['scales'] {
  return {
    [config.id]: {
      display: true,
      position: 'bottom',
      stacked,
      afterFit(scaleInstance) {
        scaleInstance.height = xAxisHeight;
      },

      grid: { color: palette.divider, offset: true },

      ticks: {
        autoSkip: false,

        callback(_tickValue, index, _ticks) {
          const dateId = dateIds[index];

          if (!R.isDefined(dateId)) throw 'date id should be defined';

          const date = timeUtility.dateFromId(dateId);
          if (!R.isDefined(date)) throw 'Not a date passed in';
          if (date.type !== 'day') throw 'date.type !== "day"';

          if ((index - 3) % 7 === 0) {
            const week = timeUtility.weekFromDay(
              date.year,
              date.month,
              date.dayOfMonth,
            );
            return timeUtility.format(week, 'x-axis-productivity-modal');
          }

          return '';
        },

        font: {
          family: scaleFont.family,
          size: scaleFont.size,
          weight: scaleFont.weight,
        },
        color: palette.text.secondary,
        padding: 5,
      },
    },
  };
}

function xAxis(
  config: Charts.XAxisConfig,
  dateIds: string[],
  stacked: boolean,
  palette: Palette,
  xAxisHeight: number,
): ChartOptions['scales'] {
  switch (config.type) {
    case 'x_axis_custom':
      return xAxisCustom(config, stacked);
    case 'x_axis_days_to_week':
      return xAxisDaysToWeek(config, dateIds, stacked, palette, xAxisHeight);
    default:
      return undefined;
  }
}

function yAxis(
  config: Charts.YAxisConfig,
  multiAxis: boolean,
  palette: Palette,
  yAxisWidth: number,
): ChartOptions['scales'] {
  return {
    [config.id]: {
      display: true,
      stacked: config.stacked,
      beginAtZero: false,

      afterFit(scaleInstance) {
        scaleInstance.width = yAxisWidth;
      },

      ...(R.isDefined(config.expandDataLimit) && {
        afterDataLimits(axis) {
          if (config.stacked) return;

          const diff = (axis.max - axis.min) * 0.1;

          axis.max = axis.max + diff;
          axis.min = axis.min - diff;
        },
      }),

      grid: { color: palette.divider, display: !multiAxis },
      position: config.id === 'yAxisPrimary' ? 'left' : 'right',
      ticks: {
        callback(tickValue, index) {
          if (index % 2 === 0) return ''; // cleaner y axis
          if (!R.isDefined(config.tickPrefix)) return tickValue;
          return `${tickValue} ${config.tickPrefix}`;
        },
        font: {
          family: scaleFont.family,
          size: scaleFont.size,
          weight: scaleFont.weight,
        },
        align: 'end',
        color: palette.text.secondary,
      },
    },
  };
}

/**
 * Annotation is for now used to mark out different weeks in chart area
 */
function getAnnotationPlugin(args: {
  xAxisConfig: Charts.XAxisConfig;
  palette: Palette;
}): ChartOptions['plugins'] {
  const { xAxisConfig, palette } = args;

  if (xAxisConfig.type !== 'x_axis_days_to_week') return undefined;

  function weekAnnotation(week: number, backgroundColor: string): any {
    const OFFSET = 0.5;

    const startDay = (week - 1) * 7 - OFFSET;
    const endDay = (week - 1) * 7 + 7 - OFFSET;

    return {
      type: 'box',
      borderWidth: 0,
      xMin: startDay,
      xMax: endDay,
      yMin: '100%',
      yMax: '100%',
      drawTime: 'beforeDraw',
      backgroundColor,
    };
  }

  const plugins: any = {
    annotation: {
      annotations: {},
    },
  };

  const thisWeek = xAxisConfig.surroundingWeeks + 1;
  const nbrOWeeks = thisWeek + xAxisConfig.surroundingWeeks;

  R.times(nbrOWeeks, (time) => {
    const color =
      time % 2 !== 0 ? palette.background.default : palette.background.paper;

    const week = time + 1;
    plugins.annotation.annotations[`week${week}`] = weekAnnotation(week, color);
  });

  return plugins as ChartOptions['plugins'];
}

/**
 * Generates chart options, not considering charts with no x or y axis.
 */
function generateOptions(args: {
  xAxisConfig: Charts.XAxisConfig;
  yAxisConfigs: Charts.YAxisConfig[];
  dateIds: string[];
  fastAnimation: boolean;
  stacked: boolean;
  palette: Palette;
  yAxisWidth: number;
  xAxisHeight: number;
}) {
  const {
    fastAnimation,
    xAxisConfig,
    yAxisConfigs,
    dateIds,
    stacked,
    palette,
    yAxisWidth,
    xAxisHeight,
  } = args;

  const multiAxis = yAxisConfigs.length !== 1;

  const scaleX = xAxis(xAxisConfig, dateIds, stacked, palette, xAxisHeight);
  const scalesY = R.pipe(
    yAxisConfigs,
    R.mapToObj((yAxisConfig) => {
      const scale = yAxis(yAxisConfig, multiAxis, palette, yAxisWidth);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return [yAxisConfig.id, R.pipe(scale, R.prop(yAxisConfig.id))];
    }),
  );

  // ! does not work for some reason
  const annotation = getAnnotationPlugin({ xAxisConfig, palette });
  const options: ChartOptions = {
    /**
     * Responsive charts,
     * Resizes the chart when its container does.
     * Don't care about maintaining aspect ratio when resizing
     * I think scroll behaviour can be implemented with responsive: false
     */
    responsive: true,
    maintainAspectRatio: false,

    ...(fastAnimation && { animation: { duration: 100 } }),
    layout: {
      padding: {
        // top: AXIS_OFFSET + AXIS_WIDTH,
        // right: multiAxis ? undefined : AXIS_OFFSET + AXIS_WIDTH + yAxisWidth,
        top: AXIS_OFFSET + AXIS_WIDTH,
        right: multiAxis ? undefined : yAxisWidth,
        bottom: 10, // arrow size + tail size
      },
      autoPadding: true, // Apply automatic padding so visible elements are completely drawn
    },
    scales: {
      ...scaleX,
      ...scalesY,
    },
    parsing: {},
    plugins: {
      filler: {
        propagate: true,
      },
      legend: { display: false },
      ...annotation,
    },
  };

  return options;
}

/**
 * Used when an uncertain number of categories exist
 * Index of dataset is used as color decider.
 * Will generate a
 */
function getDatasetColor(datasetIndex: number) {
  const index = arrayUtility.wrapAroundIndex(datasetIndex, chartColors);
  const color = chartColors[index];

  if (!R.isDefined(color)) throw 'Color should always be defined';

  return color;
}

/**
 * dataset unique properties, could probably work on naming a bit
 */
function generateDataset(
  dataset: Charts.Dataset,
  _data: (number | null | KPI.Projection[])[],
): ChartDataset {
  const { color, name, xAxisId: xAxisID, yAxisId: yAxisID } = dataset;

  const successColor = stylingUtility.convertToRgba({
    hexColor: success.main as any,
    opacity: 0.5,
  });

  const failureColor = stylingUtility.convertToRgba({
    hexColor: error.main as any,
    opacity: 0.5,
  });

  const data: (number | null | Point)[] = [];
  // Make scatter points of projections
  R.forEach.indexed(_data, (item, index) => {
    if (Array.isArray(item)) {
      item.forEach((entry, i) => {
        if (i === 0) return;
        data.push({ x: index + 1, y: entry.value }); // we don't want to draw current
      });
    } else {
      data.push(item);
    }
  });

  switch (dataset.type) {
    case 'grouped-bar':
      return {
        data,
        label: name,
        type: 'bar',
        backgroundColor: color,
        xAxisID,
        yAxisID,
        categoryPercentage: CATEGORY_PERCENTAGE,
        barPercentage: BAR_PERCENTAGE,

        hoverBorderWidth: 0,

        borderWidth: 0,
      };
    case 'line-fill-to-dataset':
      return {
        data,
        type: 'line',
        label: name,
        borderColor: color,
        fill: { target: 1, above: successColor, below: failureColor },
        xAxisID,
        yAxisID,
        spanGaps: true,
        borderJoinStyle: 'bevel',
        cubicInterpolationMode: 'monotone',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',

        borderWidth: 10,
      };

    case 'stepped-line':
      return {
        data,
        type: 'line',
        label: name,
        borderColor: color,
        borderDash: [2, 16],
        borderCapStyle: 'square',
        stepped: true,
        xAxisID,
        yAxisID,
        spanGaps: true,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderJoinStyle: 'bevel',
        borderWidth: 8,
      };

    case 'line':
      return {
        data,
        type: 'line',
        label: name,
        borderColor: color,
        xAxisID,
        yAxisID,

        spanGaps: true,
        borderJoinStyle: 'bevel',
        cubicInterpolationMode: 'monotone',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',

        borderWidth: 10,
      };

    case 'bar':
      return {
        data,
        label: name,
        type: 'bar',
        backgroundColor: color,
        xAxisID,
        yAxisID,
      };

    case 'scatter':
      return {
        data,
        label: name,
        pointRadius: 7,
        type: 'scatter',
        borderColor: color,
        backgroundColor: color,
        xAxisID,
        yAxisID,
      };
  }
}

export { generateDataset, generateOptions, getDatasetColor };
