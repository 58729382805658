import { combineReducers } from 'redux';

import { activityReducer } from './activity';
import { activityTypeReducer } from './activityType';
import { areaReducer } from './area';
import { branchReducer } from './branch';
import { companyReducer } from './company';
import { distributionCenterReducer } from './distributionCenter';
import { employeeReducer } from './employee';
import { errorMessageReducer } from './errorMessage';
import { producedActivityReducer } from './producedActivity';
import { subZoneReducer } from './subZone';
import { suggestionReducer } from './suggestion';
import { zoneReducer } from './zone';

const dataReducers = combineReducers({
  company: companyReducer,
  branch: branchReducer,
  zone: zoneReducer,
  subZone: subZoneReducer,
  distributionCenter: distributionCenterReducer,
  area: areaReducer,
  activity: activityReducer,
  activityType: activityTypeReducer,
  employee: employeeReducer,
  producedActivity: producedActivityReducer,
  errorMessage: errorMessageReducer,
  suggestion: suggestionReducer,
});

export default dataReducers;
