import { AddCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Theme } from '@mui/material';
import { Input } from 'components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import * as R from 'remeda';

const EMPTY_WARNING_TEXT = ' ';

type Props = {
  theme: Theme;
  onAdd(value: number): void;
};

const ProjectionInput: FunctionComponent<Props> = (props) => {
  const { onAdd, theme } = props;

  const [projection, setProjection] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [warningText, setWarningText] = useState(EMPTY_WARNING_TEXT);

  useEffect(() => {
    setWarningText(EMPTY_WARNING_TEXT);
  }, [projection]);

  const handleSubmit = () => {
    const EMPTY_STRING = '';
    if (projection === EMPTY_STRING) {
      setWarningText('Cannot submit empty text');
      return; // We don't want to submit an empty value
    }

    const numericProjection = Number(projection);

    if (!R.isNumber(numericProjection)) {
      setWarningText('Non-numeric values forbidden');
    }

    setProjection(''); // clear projection
    onAdd(numericProjection); // save projection
  };

  const handleChange = (value: string | string[]) => {
    if (typeof value !== 'string') return;
    const numericValue = Number(value);
    if (!R.isNumber(numericValue)) {
      setWarningText('Non-numeric values forbidden');
      return;
    }

    if (numericValue < 0) {
      setWarningText('Value should be over 0');
      return;
    }
    setProjection(value);
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      sx={{
        p: 1,
        pt: 2,
        pb: 2,
        borderBottom: 1,
        borderColor: theme.palette.divider,
      }}
    >
      <Input
        id="projection"
        onChange={handleChange}
        label="Add projection"
        value={projection}
        autoFocus
        endAdornment={
          <IconButton
            type="submit"
            color="primary"
            disabled={projection === ''}
          >
            <AddCircleOutline />
          </IconButton>
        }
      />
    </Box>
  );
};

export { ProjectionInput };
