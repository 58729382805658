import { Theme, Typography } from '@mui/material';
import { Input, TableCell } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { subZoneSelectors } from 'store/data/subZone';
import { zoneManagementActions } from 'store/page/zoneManagement';
import { selectableSubZones } from 'store/page/zoneManagement/selectors';
import { Types } from 'Types';

import { ColumnName, headers } from './constants';

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => ({
  selectableSubZones: selectableSubZones(state, { zoneId: ownProps.zoneId }),
  bySubZone: subZoneSelectors.byId(state),
});

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  moveActivity: (activityId: string, zoneId: string) =>
    dispatch(zoneManagementActions.moveActivity({ activityId, zoneId })),

  updateSubZones: (activityId: string, subZoneIds: string[]) =>
    dispatch(
      zoneManagementActions.startUpdateSubZones({ activityId, subZoneIds }),
    ),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

type OwnProps = {
  row: PivotTable.Row;
  zoneId: string;
  theme: Theme;
  moveToZones: LabelItem[];
  alternatingBackground: boolean;
  headers: (LabelItem<ColumnName> & { columns: number })[];
};

const ActivityTableRow: FunctionComponent<Props> = (props) => {
  const {
    row,
    bySubZone,
    updateSubZones,
    moveActivity,
    selectableSubZones,
    alternatingBackground,
    moveToZones,
    theme,
  } = props;

  const handleSubZoneChange = (subZoneIds: string | string[]) => {
    if (typeof subZoneIds === 'string') return;

    updateSubZones(row.id, subZoneIds);
  };

  const connectedSubZonesSelect = (
    <Input
      id="subzone-select"
      // onChange={(e) => console.log(e)}
      value={row.childRows?.map((subZone) => subZone.id) ?? []}
      onChange={handleSubZoneChange}
      select={{
        items: selectableSubZones,
        multiple: true,
        renderValue: (subZoneIds) =>
          (subZoneIds as string[])
            .map((subZoneId) => bySubZone[subZoneId]?.code)
            .join(', '),
      }}
    />
  );

  const moveActivitySelect = (
    <Input
      id="subzone-select"
      onChange={(e) => moveActivity(row.id, e as any)}
      value="1"
      select={{
        items: moveToZones,
      }}
    />
  );

  return headers.map((header) => {
    switch (header.id) {
      case 'activity':
        return (
          <TableCell
            key={header.id}
            columns={header.columns}
            theme={theme}
            alternatingBackground={alternatingBackground}
            justifyContent="space-between"
          >
            <Typography variant="text2">{row.name}</Typography>
          </TableCell>
        );

      case 'move':
        return (
          <TableCell
            key={header.id}
            columns={header.columns}
            theme={theme}
            alternatingBackground={alternatingBackground}
            justifyContent="space-between"
          >
            {moveActivitySelect}
          </TableCell>
        );

      case 'connected-subzones':
        return (
          <TableCell
            key={header.id}
            columns={header.columns}
            theme={theme}
            alternatingBackground={alternatingBackground}
            justifyContent="space-between"
          >
            {connectedSubZonesSelect}
          </TableCell>
        );
    }
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTableRow);
