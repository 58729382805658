import {
  Box,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuCategory } from 'router/constants';

type Props = MenuCategory & { navigate(routeId: string): void };

const NavigationMenuCategory: FunctionComponent<Props> = (props) => {
  const { imageSource, name, routes, navigate } = props;
  const { pathname } = useLocation();
  const theme = useTheme();
  return (
    <Stack
      component="section"
      sx={{
        p: theme.distances.huge,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          component="img"
          sx={{
            height: theme.sizes[55],
            width: theme.sizes[55],
            objectFit: 'scale-down',
            justifySelf: 'center',
          }}
          alt={name}
          src={imageSource}
        />
      </Box>

      <Typography
        variant="header1"
        sx={{
          p: theme.distances.medium,
          pt: theme.distances.medium,
          pb: theme.distances.tiny,
        }}
      >
        {name}
      </Typography>
      <MenuList sx={{}}>
        {routes.map((route) => {
          const selected = route.id === pathname;
          return (
            <MenuItem
              key={route.id}
              selected={selected}
              disabled={route.disabled}
              sx={{
                p: theme.distances.medium,
                pt: theme.distances.tiny,
                pb: theme.distances.tiny,
                mb: theme.distances.tiny,
              }}
              onClick={() => {
                !selected && navigate(route.id);
              }}
            >
              <Typography variant="action1" component="span">
                {route.name}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Stack>
  );
};

export default NavigationMenuCategory;
