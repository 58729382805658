import styled from '@emotion/styled';
import { Palette, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = {
  className?: string;
  status: Extract<keyof Palette, 'success' | 'error' | 'warning'>;
  text: string;
  sx?: SxProps<Theme>;
};

const StatusText: FunctionComponent<Props> = (props) => {
  const { className, text, status, sx } = props;

  const theme = useTheme();

  const { main, contrastText } = theme.palette[status];

  return (
    <Typography
      className={className}
      variant="text2"
      sx={[
        {
          display: 'inline-block',
          backgroundColor: main,
          color: contrastText,
          width: 'fit-content',
          alignItems: 'center',
          pl: theme.distances.small,
          pr: theme.distances.small,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {text}
    </Typography>
  );
};

const StyledStatusText = styled(StatusText)();

export { StyledStatusText as StatusText };
