import * as R from 'remeda';
import * as timeUtility from 'utility/time';
import { misc } from 'variables';

function addDateId(
  record: Record<string, string[]>,
  dateId: string,
  id: string,
) {
  if (!R.isDefined(record[dateId])) record[dateId] = [id];
  else record[dateId]?.push(id);
}

// Organize into dateId & ids[]
// precalulation to make it easy to calculate date-specifics
function groupByDate(list: Data.PartialDateItem[]) {
  const byDate: Record<string, string[]> = {};

  const length = list.length;

  for (let i = 0; i < length; i++) {
    const current = list[i];
    if (!R.isDefined(current)) continue;

    const { dayOfMonth, year, month, week, id } = current;

    // Add year
    const yearId = timeUtility.generateYearId(year);
    addDateId(byDate, yearId, id);

    // Add Month

    if (R.isDefined(month)) {
      const monthId = timeUtility.generateMonthId(year, month);
      addDateId(byDate, monthId, id);
    }

    // Add Week
    if (R.isDefined(week)) {
      const weekId = timeUtility.generateWeekId(year, week);
      addDateId(byDate, weekId, id);
    }

    if (R.isDefined(month) && R.isDefined(dayOfMonth)) {
      // Add Day
      const dayId = timeUtility.generateDayId(year, month, dayOfMonth);
      addDateId(byDate, dayId, id);
    }
  }

  return byDate;
}

function groupBy<T extends BaseItem>(list: T[], _key: keyof T) {
  const key = R.isDefined(_key) ? (_key as string) : misc.MISSING;
  return R.pipe(
    list,
    R.groupBy((item) => R.pipe(item, R.prop(key as any))),
    R.mapValues((items) => R.map(items, (item) => item.id)),
  );
}

export { groupBy, groupByDate };
