import { UndoSharp } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Card, Chart, Input, Stepper } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import {
  flexHoursActions,
  flexHoursSelectors,
  FlexHoursState,
} from 'store/page/flexHours';
import Types from 'Types';
import { arrayUtility, timeUtility } from 'utility';

import FilterButton from './FilterButton';

// import Control from './Control';
const labelItems: LabelItem<FlexHoursState['chartMode']>[] = [
  { id: 'exploration', name: 'Exploration' },
  { id: 'selected', name: 'Selected dates' },
];

const mapStateToProps = (state: Types.RootState) => ({
  chartMode: flexHoursSelectors.chartMode(state),
  datasets: flexHoursSelectors.datasets(state),
  dates: flexHoursSelectors.chartLabels(state),
  selectedDateType: flexHoursSelectors.chartSelectedDateType(state),
  exploreDate: flexHoursSelectors.chartExploreDate(state),
});
const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  setExploreDate: (date: Time.Date) =>
    dispatch(flexHoursActions.setChartExploreDate(date)),
  setChartMode: (mode: FlexHoursState['chartMode']) =>
    dispatch(flexHoursActions.setChartMode(mode)),
  setSelectedDateType: (dateType: Time.DateType) =>
    dispatch(flexHoursActions.setChartSelectedDateType(dateType)),
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & DispatchProps;

const ChartView: FunctionComponent<Props> = (props) => {
  const orderDateTypes: Time.DateType[] = ['year', 'month', 'week', 'day'];

  const {
    chartMode,
    dates,
    datasets,
    setExploreDate,
    setChartMode,
    setSelectedDateType,
    exploreDate,
    selectedDateType,
  } = props;
  if (!R.isDefined(dates)) return null;

  function getDisplayDate() {
    if (chartMode === 'exploration')
      return timeUtility.format(exploreDate, 'stepper');
    return selectedDateType;
  }

  function isDisabled() {
    if (chartMode === 'exploration' && exploreDate.type !== 'year')
      return false;
    return true;
  }

  // TODO: Zoom out correctly
  function step(step: System.Step) {
    if (chartMode === 'exploration')
      setExploreDate(timeUtility.step({ timeDate: exploreDate, step }));
    else if (chartMode === 'selected') {
      const currentIndex = R.findIndex(
        orderDateTypes,
        (dateType) => dateType === selectedDateType,
      );

      const derivate = step === 'next' ? +1 : -1;

      const newIndex = arrayUtility.wrapAroundIndex(
        currentIndex + derivate,
        orderDateTypes,
      );

      const newSelectedDateType = orderDateTypes[newIndex];
      if (!R.isDefined(newSelectedDateType)) return;

      setSelectedDateType(newSelectedDateType);
    }
  }

  return (
    <Box sx={(theme) => ({ padding: theme.distances.large, height: '100%' })}>
      <Card
        largeHeader
        sx={{ height: '100%', position: 'relative' }}
        noPadding
        title={getDisplayDate()}
        action={
          <Stack
            direction="row"
            alignItems="center"
            sx={(theme) => ({ gap: theme.distances.huge })}
          >
            <Input
              id="select-mode"
              label="select mode"
              onChange={(value) => {
                setChartMode(value as FlexHoursState['chartMode']);
              }}
              value={chartMode}
              select={{ items: labelItems }}
            />
            <FilterButton />

            <Button
              onClick={() => setExploreDate(timeUtility.zoomOut(exploreDate))}
              disabled={isDisabled()}
              color="inherit"
              startIcon={<UndoSharp />}
            >
              <Typography variant="action1">Back</Typography>
            </Button>
            <Stepper
              next={() => step('next')}
              previous={() => step('previous')}
            />
          </Stack>
        }
      >
        <Box
          sx={(theme) => ({
            height: '100%',
            width: '100%',
            pt: theme.distances.large,
          })}
        >
          <Chart
            dateIds={dates.map((date) => date.id)}
            datasets={datasets}
            hasLegend
            stacked
            type="bar"
            xAxisConfig={{
              id: 'xAxis',
              type: 'x_axis_custom',
              displayLocation: 'x-axis',
              interactable: chartMode === 'exploration',
              onLabelClick: setExploreDate,
            }}
            yAxisConfigs={[
              { id: 'yAxisPrimary', tickPrefix: 'h', stacked: true },
            ]}
            xAxisHeight="massive"
            yAxisWidth="massive"
          />
        </Box>
      </Card>
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartView);
