import { Popover, Sizes, useTheme } from '@mui/material';
import React, { FunctionComponent, useRef, useState } from 'react';
import * as R from 'remeda';
const useDropdown = (size?: keyof Sizes): HookProps => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const widthRef = useRef<number>();

  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    widthRef.current = R.isDefined(size)
      ? theme.sizes[size]
      : event.currentTarget.clientWidth;
    setAnchorEl(event.currentTarget);
  };

  return { open, handleClose, handleClick, anchorEl, widthRef };
};

type HookProps = {
  open: boolean;
  handleClose(): void;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  widthRef: React.MutableRefObject<number | undefined>;
  anchorEl: HTMLElement | null;
};

type Props = {
  children: React.ReactNode;
} & Omit<HookProps, 'handleClick'>;

const Dropdown: FunctionComponent<Props> = (props) => {
  const { children, handleClose, open, anchorEl, widthRef } = props;

  return (
    <Popover
      id="projection"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      slotProps={{
        paper: {
          sx: {
            width: widthRef.current,
          },
        },
      }}
    >
      {children}
    </Popover>
  );
};

export { Dropdown, useDropdown };
