import * as R from 'remeda';
import { ActionType } from 'typesafe-actions';

import * as userActions from './actions';
import * as userConstants from './constants';
import userReducer from './reducer';
import * as userSagas from './sagas';
import * as userSelectors from './selectors';

export type UserState = {
  bearerToken: null | string;
};

export type UserAction = ActionType<typeof userActions>;

const userSagaActions = R.pick(userActions, [
  'startLogin',
  'startVerify',
  'startLogout',
]);

export type UserSagaAction = ActionType<typeof userSagaActions>;

export {
  userActions,
  userConstants,
  userReducer,
  userSagaActions,
  userSagas,
  userSelectors,
};
