import {
  amber,
  deepOrange,
  lightBlue,
  lightGreen,
  teal,
} from '@mui/material/colors';
import { typography } from 'material/typography';

const scaleFontVariant = typography.text2;

const scaleFont = {
  family: scaleFontVariant.fontFamily,
  size: scaleFontVariant.fontSize as any,
  weight: scaleFontVariant.fontWeight as any,
  letterSpacing:
    scaleFontVariant.letterSpacing && `${scaleFontVariant.letterSpacing}px`,
};

const chartColors = [
  amber['300'],
  lightGreen['400'],
  lightBlue['400'],
  teal['300'],
  deepOrange['400'],
];

const CATEGORY_PERCENTAGE = 1;
const BAR_PERCENTAGE = 0.5;

const AXIS_WIDTH = 2;

const AXIS_OFFSET = 55;

export {
  AXIS_OFFSET,
  AXIS_WIDTH,
  BAR_PERCENTAGE,
  CATEGORY_PERCENTAGE,
  chartColors,
  scaleFont,
};
