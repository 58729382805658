import axios from 'axios';
import { USE_MOCK } from 'config/app';
import * as R from 'remeda';

import { baseEndpoint } from './config';
import { transformForecasts } from './transformers';

const getPrediction = (params: {
  companyId: string;
  zoneId?: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId, zoneId } = params;

  return axios
    .post<
      API.Forecast[]
    >(`${baseEndpoint}/companies/${companyId}/predictions`, { zoneId }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const { forecastDomain: predictionDomain } = transformForecasts({
        forecasts: data,
      });
      return { predictionDomain };
    });
};

const getForecasts = (params: {
  companyId: string;
  zoneId?: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId, zoneId } = params;

  return axios
    .post<
      API.Forecast[]
    >(`${baseEndpoint}/forecasts/${companyId}`, { zoneId }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const transformed = transformForecasts({ forecasts: data });
      return transformed;
    });
};

const createForecast = (params: {
  companyId: string;
  zoneId: string;
  date: Time.Day;
  value: number;
  unitOfMeasureId?: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const {
    bearerToken,
    companyId,
    zoneId,
    date,
    unitOfMeasureId = '4977c495-137c-4de8-a589-d80438d7111a',
    value,

    useMocked = USE_MOCK,
  } = params;
  // "2021-03-31T00:00:00.0000000+00:00"
  const today = new Date();

  const forecast: Data.Projection = {
    id: R.randomString(10),
    companyId,
    year: date.year,
    month: date.month,
    dayOfMonth: date.dayOfMonth,
    week: date.week,
    dayOfWeek: date.dayOfWeek,
    forecastDate: today.toISOString(),
    timestamp: today.getTime(),
    unitOfMeasureId,
    value,
    zoneId,
  };

  if (useMocked) {
    return Promise.resolve(forecast);
  }

  return axios
    .post<{ id: string }>(
      `${baseEndpoint}/forecasts/${companyId}/create`,
      {
        zoneId,
        year: date.year,
        monthNumber: date.month + 1, // Javascript month normalization
        dayOfMonth: date.dayOfMonth,
        value,
        unitOfMeasureId,
      },
      { headers: { Authorization: `Bearer ${bearerToken}` } },
    )
    .then((response) => {
      const { data } = response;

      forecast.id = data.id;

      return forecast;
    });
};

const createPrediction = (params: {
  companyId: string;
  zoneId: string;
  date: Time.Day;
  value: number;
  unitOfMeasureId?: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const {
    bearerToken,
    companyId,
    zoneId,
    date,
    unitOfMeasureId = '4977c495-137c-4de8-a589-d80438d7111a',
    value,

    useMocked = USE_MOCK,
  } = params;
  // "2021-03-31T00:00:00.0000000+00:00"
  const today = new Date();

  const prediction: Data.Projection = {
    id: R.randomString(10),
    companyId,
    year: date.year,
    month: date.month,
    dayOfMonth: date.dayOfMonth,
    week: date.week,
    dayOfWeek: date.dayOfWeek,
    forecastDate: today.toISOString(),
    timestamp: today.getTime(),
    unitOfMeasureId,
    value,
    zoneId,
  };

  if (useMocked) {
    return Promise.resolve(prediction);
  }

  return axios
    .post<{ id: string }>(
      `${baseEndpoint}/companies/${companyId}/predictions/create`,
      {
        zoneId,
        year: date.year,
        monthNumber: date.month + 1, // Javascript month normalization
        dayOfMonth: date.dayOfMonth,
        value,
        unitOfMeasureId,
      },
      { headers: { Authorization: `Bearer ${bearerToken}` } },
    )
    .then((response) => {
      const { data } = response;

      prediction.id = data.id;

      return prediction;
    });
};

export { createForecast, createPrediction, getForecasts, getPrediction };
