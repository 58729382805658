import * as R from 'remeda';

function wrapAroundIndex(index: number, array: any[]): number {
  const length = array.length;

  // Convert negative index to positive equivalent
  if (index < 0) {
    index = (index % length) + length;
  }

  // Wrap around for positive index
  if (index >= length) {
    index = index % length;
  }

  return index;
}

// Using hash table intersecting approach.
// two-pointer approach is on average faster though if sorted.
function intersectingIds(list1: string[], list2: string[]) {
  const hashTable: Record<string, true> = {};
  const intersections: string[] = [];
  // Add to hashtable
  for (let i = 0; i < list1.length; i++) {
    const id = list1[i] as string;
    hashTable[id] = true;
  }

  for (let i = 0; i < list2.length; i++) {
    const id = list2[i] as string;
    const exists = R.isTruthy(hashTable[id]);
    if (!exists) continue;
    intersections.push(id);
  }
  return intersections;
}

/**
 *
 * @param list1 unique ids in this list will remain
 * @param list2 filter away these ids
 * @returns ids unique from list1
 */
function rejectIds(list1: string[], list2: string[]) {
  const hashTable: Record<string, true> = {};
  const remaining: string[] = [];

  // Add to hashtable
  for (let i = 0; i < list2.length; i++) {
    const id = list2[i] as string;
    hashTable[id] = true;
  }

  for (let i = 0; i < list1.length; i++) {
    const id = list1[i] as string;
    const exists = R.isTruthy(hashTable[id]);
    if (exists) continue;
    remaining.push(id);
  }

  return remaining;
}

export { intersectingIds, rejectIds, wrapAroundIndex };
