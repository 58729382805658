import React, { FunctionComponent } from 'react';
import { TabsPage } from 'templates';

import PageActions from './PageActions';
import SuggestionsView from './SuggestionsView';

const ImprovementsPage: FunctionComponent = () => {
  return (
    <TabsPage
      tabs={[
        {
          id: 'suggestions',
          name: 'suggestions',
          component: <SuggestionsView />,
        },
      ]}
      pageActions={<PageActions />}
    />
  );
};

export default ImprovementsPage;
