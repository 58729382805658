import * as R from 'remeda';
import {
  ForecastAction,
  forecastActions as actions,
  ForecastState,
} from 'store/page/forecast';
import { getType } from 'typesafe-actions';

const selectedWeek: Time.Week = {
  id: 'y2021-w16',
  type: 'week',
  week: 16,
  year: 2021,
};
const initialState: ForecastState = {
  selectedWeek,
};

const forecastReducer = (
  state: ForecastState = initialState,
  action: ForecastAction,
): ForecastState => {
  switch (action.type) {
    case getType(actions.setSelectedWeek):
      const selectedWeek = action.payload;
      return R.set(state, 'selectedWeek', selectedWeek);
    default:
      return state;
  }
};

export default forecastReducer;
