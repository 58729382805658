import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';
import { aggregationUtility, kpiUtility } from 'utility';

import { onRelevantItems } from '../helpers';
export const domain = (state: Types.RootState) => state.aggregated.employeeHour;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

export const byDate = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'dateId'),
);

export const byActivity = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'activityId'),
);

export const byActivityType = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'activityTypeId'),
);

export const byEmployee = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'employeeId'),
);

export const byEntity = createSelector(
  byActivity,
  byActivityType,
  byEmployee,
  (byActivity, byActivityType, byEmployee) => ({
    ...byActivity,
    ...byActivityType,
    ...byEmployee,
  }),
);
export const kpi = createSelector(
  (state: Types.RootState, ownProps: { entityIds: string[]; dateId: string }) =>
    ownProps,
  byDate,
  byEntity,
  byId,
  ({ dateId, entityIds }, byDate, byEntity, byId) => {
    let hours = 0;

    onRelevantItems({
      byId,
      byDate,
      byEntity,
      dateId,
      itemCallback: (item) => {
        hours = hours + item.spentTime;
      },
      entityIds,
    });

    const kpiValues: KPI.ActivityValues = {
      hours: kpiUtility.sanitize(hours),
      modifiedHours: null,
    };

    return kpiValues;
  },
);
