import { ShowChartOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { IconButton } from '@mui/material';
import {
  Card,
  ExpandButton,
  Stepper,
  StickyCard,
  Table,
  TableCell,
} from 'components';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { dashboardActions, dashboardSelectors } from 'store/page/dashboard';
import { ScrollableView } from 'templates';
import Types from 'Types';
import { timeUtility } from 'utility';

import DateTableRow from './DateTableRow';
import TableRowChartModal from './TableRowChartModal';

// const timeslots: LabelItem[] = [
//   {
//     id: '6',
//     name: '06:00 - 07:00',
//   },
//   {
//     id: '7',
//     name: '07:00 - 08:00',
//   },
//   {
//     id: '8',
//     name: '08:00 - 09:00',
//   },
//   {
//     id: '9',
//     name: '09:00 - 10:00',
//   },
//   {
//     id: '10',
//     name: '10:00 - 11:00',
//   },
//   {
//     id: '11',
//     name: '11:00 - 12:00',
//   },
//   {
//     id: '12',
//     name: '12:00 - 13:00',
//   },
//   {
//     id: '13',
//     name: '13:00 - 14:00',
//   },
//   {
//     id: '14',
//     name: '14:00 - 15:00',
//   },
//   {
//     id: '15',
//     name: '15:00 - 16:00',
//   },
//   {
//     id: '16',
//     name: '16:00 - 17:00',
//   },
//   {
//     id: '17',
//     name: '17:00 - 18:00',
//   },
// ];

const headers: (LabelItem<KPI.Key> & { columns?: number })[] = [
  { id: 'hours', name: 'hours', columns: 1.5 },
  { id: 'noInternalProcessed', name: 'rows' },
  { id: 'target', name: 'target' },
  { id: 'actual', name: 'actual' },
  { id: 'hoursFromGoal', name: 'h. from' },
];

const mapStateToProps = (state: Types.RootState) => ({
  selectedWeek: dashboardSelectors.selectedWeek(state),
  selectedDay: dashboardSelectors.selectedDay(state),
  displayRows: dashboardSelectors.displayRows(state),
  pivotById: dashboardSelectors.pivotById(state),
});

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  togglePivot: (rowId: string) =>
    dispatch(dashboardActions.togglePivot({ id: rowId })),
  setSelectedDay: (day: Time.Day) =>
    dispatch(dashboardActions.setSelectedDay(day)),

  setSelectedWeek: (week: Time.Week) =>
    dispatch(dashboardActions.setSelectedWeek(week)),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;

const PivotView: FunctionComponent<Props> = (props) => {
  const {
    selectedDay,
    selectedWeek,
    displayRows,
    pivotById,
    togglePivot,
    setSelectedDay,
    setSelectedWeek,
  } = props;

  // Don't remember this code?
  // let newRows: PivotTable.Row[] = [];
  // let parentIndex: number = -1;
  // let previousLastIndent = false;

  // displayRows.forEach((row) => {
  //   if (row.indentLevel == 3 && !previousLastIndent) {
  //     const timeslotRows = timeslots.map(
  //       (timeslot) =>
  //         ({
  //           id: newRows[parentIndex]!.id + '/' + timeslot.id,
  //           name: timeslot.name,
  //           ancestorIds: [],
  //           indentLevel: 3,
  //         }) as PivotTable.Row,
  //     );

  //     newRows[parentIndex - 1]!.childRows = timeslotRows;
  //     newRows = [...newRows, ...timeslotRows];

  //     previousLastIndent = true;
  //   } else {
  //     if (row.indentLevel === 2) newRows.push({ ...row, childRows: [] });
  //     else newRows.push(row);
  //     parentIndex = newRows.length - 1;
  //     previousLastIndent = false;
  //   }
  // });

  const theme = useTheme();

  const [modalRow, setModalRow] = useState<PivotTable.Row>();

  function stepDate(step: System.Step, stepSize: Time.DateType) {
    const newDay = timeUtility.step({ step, timeDate: selectedDay, stepSize });

    const newWeek = timeUtility.weekFromDay(
      newDay.year,
      newDay.month,
      newDay.dayOfMonth,
    );

    setSelectedWeek(newWeek);
    setSelectedDay(newDay);
  }

  const stickyPivotCard = (
    <StickyCard
      title="Zone Activities"
      sx={(theme) => ({ width: theme.sizes[610] })}
    >
      <Table
        rows={displayRows}
        renderRow={(row, alternatingBackground) => {
          const hasCharts =
            R.isDefined(row.childRows) && row.childRows.length !== 0;

          const canPivot =
            R.isDefined(row.childRows) && row.childRows.length !== 0;
          return (
            <TableCell
              key={row.id}
              theme={theme}
              lastInLine
              justifyContent="space-between"
              alternatingBackground={alternatingBackground}
            >
              <ExpandButton
                onClick={() => togglePivot(row.id)}
                expanded={R.isTruthy(pivotById[row.id])}
                label={row.name}
                disabled={!canPivot}
                indentLevel={row.indentLevel}
                noExpand={!canPivot}
              />

              {hasCharts && (
                <IconButton color="primary" onClick={() => setModalRow(row)}>
                  <ShowChartOutlined />
                </IconButton>
              )}
            </TableCell>
          );
        }}
      />
    </StickyCard>
  );

  const weekCard = (
    <Card
      sx={(theme) => ({ width: theme.sizes[610] })}
      title={selectedWeek.name}
      action={
        <Stepper
          next={() => stepDate('next', 'week')}
          previous={() => stepDate('previous', 'week')}
        />
      }
    >
      <Table
        headers={headers}
        rows={displayRows}
        renderRow={(row, alternatingBackground, headers) => (
          <DateTableRow
            key={row.id}
            dateId={selectedWeek.id}
            row={row}
            alternatingBackground={alternatingBackground}
            headers={headers}
            theme={theme}
          />
        )}
      />
    </Card>
  );

  const dayCard = (
    <Card
      sx={(theme) => ({ width: theme.sizes[610] })}
      title={selectedDay.name}
      action={
        <Stepper
          next={() => stepDate('next', 'day')}
          previous={() => stepDate('previous', 'day')}
        />
      }
    >
      <Table
        headers={headers}
        rows={displayRows}
        renderRow={(row, alternatingBackground, headers) => (
          <DateTableRow
            key={row.id}
            dateId={selectedDay.id}
            alternatingBackground={alternatingBackground}
            row={row}
            headers={headers}
            theme={theme}
          />
        )}
      />
    </Card>
  );

  return (
    <ScrollableView>
      <TableRowChartModal
        row={modalRow}
        isOpen={R.isDefined(modalRow)}
        onClose={() => setModalRow(undefined)}
      />
      {stickyPivotCard}
      {weekCard}
      {dayCard}
    </ScrollableView>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PivotView);
