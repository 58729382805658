import { CssBaseline, ThemeProvider } from '@mui/material';
import { REACT_STRICT_MODE } from 'config/app';
import { lightTheme } from 'material';
import React, { FunctionComponent } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store';

import App from './App';

/**
 * Wrapper function which adds React Strict Mode on app config.
 * ----------------
 * Strict mode is causing components to render twice to catch issues ibefore production.
 * Leads to duplicated logs if used in useEffect(() => { console.log("I print twice") }, [])
 */

const StrictWrapper: FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) =>
  REACT_STRICT_MODE ? (
    <React.StrictMode>{children}</React.StrictMode>
  ) : (
    <>{children}</>
  );

const rootElement = document.getElementById('root');

if (rootElement !== null) {
  const root = createRoot(rootElement);

  root.render(
    <StrictWrapper>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline enableColorScheme />
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </StrictWrapper>,
  );
}
