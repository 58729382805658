import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { forecastSelectors } from 'store/page/forecast';
import { TabsPage } from 'templates';
import Types from 'Types';

import PageActions from './PageActions';
import ZoneView from './ZoneView';
const mapStateToProps = (state: Types.RootState) => ({
  zoneCards: forecastSelectors.zoneCards(state),
  selectedDays: forecastSelectors.selectedDays(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;
const ForecastPage: FunctionComponent<Props> = (props) => {
  const { selectedDays, zoneCards } = props;
  return (
    <TabsPage
      tabs={zoneCards.map((zoneCard) => ({
        id: zoneCard.id,
        name: zoneCard.name,
        component: <ZoneView selectedDays={selectedDays} zone={zoneCard} />,
      }))}
      pageActions={<PageActions />}
    />
  );
};

export default connect(mapStateToProps)(ForecastPage);
