import { Box, useTheme } from '@mui/material';
import { Card, Modal } from 'components';
import { QRCodeSVG } from 'qrcode.react';
import React, { FunctionComponent } from 'react';
type Props = {
  isOpen: boolean;
  companyId: string;
  onClose(): void;
};

const QRCodeModal: FunctionComponent<Props> = (props) => {
  const { onClose, isOpen, companyId } = props;

  return (
    <Modal title="Connect" isOpen={isOpen} onClose={onClose} fullWidth>
      <Card
        title="Scan QR-code"
        sx={(theme) => ({
          height: theme.sizes[610],
          width: theme.sizes[610],
          margin: 'auto',
        })}
      >
        <Box
          sx={(theme) => ({
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: theme.palette.divider,
          })}
        >
          <QrCode companyId={companyId} />
        </Box>
      </Card>
    </Modal>
  );
};

const QrCode: FunctionComponent<{ companyId: string }> = (props) => {
  const { companyId } = props;

  const theme = useTheme();
  return (
    <QRCodeSVG
      value={companyId}
      width="80%"
      height="80%"
      bgColor={theme.palette.background.paper}
      fgColor={theme.palette.text.primary}
    />
  );
};

export default QRCodeModal;
