import { useLocation } from 'react-router-dom';
import { routeNames } from 'router/constants';

function useRouteName(): string[] {
  const location = useLocation();
  const name = routeNames[location.pathname] ?? ['no name / no place'];

  return name;
}

export { useRouteName };
