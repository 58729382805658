import { DOMAIN_TYPE } from '../constants';

const DOMAIN_NAME = 'flexHours';

export const TOGGLE_PIVOT =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/TOGGLE_PIVOT` as const;

export const SET_SELECTED_DATES =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_SELECTED_DATES` as const;

export const SET_CHART_MODE =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_CHART_MODE` as const;

export const SET_CHART_EXPLORE_DATE =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_CHART_EXPLORE_DATE_HISTORY` as const;

export const SET_CHART_SELECTED_DATE_TYPE =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_CHART_SELECTED_DATE_TYPE` as const;

export const SET_CHART_FILTER =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_CHART_FILTER` as const;

export const START_SELECT_DATES =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_SELECT_DATES` as const;
