import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  title?: string | React.ReactNode;
  action?: React.ReactNode;
  largeHeader?: boolean;
  elevated?: boolean;
  noPadding?: boolean;
};

const CardComponent: FunctionComponent<Props> = (props) => {
  const {
    children,
    sx,
    elevated = false,
    action,
    title,
    largeHeader = false,
    noPadding = false,
  } = props;
  const theme = useTheme();

  const isDarkTheme = theme.palette.mode === 'dark';
  const borderWidth = elevated ? 0 : 1;

  const hasHeader =
    R.isTruthy(largeHeader) || R.isDefined(title) || R.isDefined(action);

  return (
    <Card
      variant={elevated ? 'elevation' : 'outlined'}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          // border: borderWidth,
          // borderColor: theme.palette.divider,

          height: 'fit-content',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {hasHeader && (
        <CardHeader
          sx={{
            p: 0,
            pl: theme.distances.medium,
            pr: theme.distances.small,
            display: 'flex',
            alignItems: 'center',
            height: largeHeader ? theme.sizes[89] : theme.sizes[55],
            '& .MuiCardHeader-action': {
              margin: 0,
              alignSelf: 'initial',
            },
          }}
          action={action}
          title={
            typeof title === 'string' ? (
              <Typography
                variant="header3"
                sx={{
                  color: theme.palette.text.secondary,
                  textTransform: 'capitalize',
                }}
              >
                {title}
              </Typography>
            ) : (
              title
            )
          }
        />
      )}
      <CardContent
        sx={(theme) => ({
          p: noPadding ? 0 : theme.distances.tiny,
          pt: 0,
          flexGrow: 1,
          ...(noPadding && {
            ':last-child': {
              p: 0,
            },
          }),
        })}
      >
        {children}
      </CardContent>
    </Card>
  );
};

const StickyCard: FunctionComponent<Props> = (props) => {
  return (
    <Stack
      direction="row"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        position: 'sticky',
        zIndex: 1,
        left: 0,
        gap: theme.distances.medium,
      })}
    >
      <CardComponent {...props} />
      <Divider flexItem orientation="vertical" />
    </Stack>
  );
};
export { CardComponent as Card, StickyCard };
