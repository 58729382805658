import * as R from 'remeda';
import { domainUtility, timeUtility } from 'utility';
import { mutateAdd } from 'utility/domain';

function transformProducedActivities(
  producedActivities: API.ProducedActivity[],
  errorMessages: API.ErrorMessage[],
) {
  const producedActivityDomain: NormalizedDomain<Data.ProducedActivity> = {
    allIds: [],
    byId: {},
  };

  const errorMessageDomain: NormalizedDomain<Data.ErrorMessage> = {
    allIds: [],
    byId: {},
  };

  for (let index = 0; index < errorMessages.length; index++) {
    const currentErrorMessage = errorMessages[index];

    if (!R.isDefined(currentErrorMessage)) break;

    const {
      id,
      action,
      companyId,
      error,
      errorTypeId,
      isAcknowledged,
      message,
      producedActivityId,
    } = currentErrorMessage;

    if (!R.isDefined(producedActivityId)) continue;

    const errorMessage: Data.ErrorMessage = {
      id,
      action,
      companyId,
      error,
      errorTypeId,
      isAcknowledged,
      message,
      producedActivityId,
    };

    domainUtility.mutateAdd(errorMessageDomain, errorMessage);
  }

  const errorBySomething = R.pipe(
    errorMessageDomain.allIds,
    R.map((id) => R.pipe(errorMessageDomain.byId, R.prop(id))),
    R.filter(R.isDefined),
    R.groupBy((errorMessage) => errorMessage.producedActivityId),
    R.mapValues((errors) => R.map(errors, (error) => error.id)),
  );

  for (let index = 0; index < producedActivities.length; index++) {
    const currentProducedActivity = producedActivities[index];

    if (!R.isDefined(currentProducedActivity)) continue;

    const {
      id,
      activityId,
      activityTypeId,
      companyId,
      date,
      dayOfWeek,
      employeeId,
      importedActivitCode,
      importedDate,
      monthNumber,
      importedActivityTypeCode,
      importedEmployeeNumber,
      spentTime,
      weekNumber,
      year,
    } = currentProducedActivity;

    const producedActivity: Data.ProducedActivity = {
      id,
      activityId,
      activityTypeId,
      companyId,
      date,
      dayOfWeek,
      employeeId,
      importedDate,
      monthNumber: monthNumber ? monthNumber - 1 : null, // goes from 1-12
      importedActivitCode,
      importedActivityTypeCode,
      importedEmployeeNumber,
      spentTime,
      weekNumber,
      year,
      errorIds: errorBySomething[id] ?? null,
    };

    domainUtility.mutateAdd(producedActivityDomain, producedActivity);
  }

  return { errorMessageDomain, producedActivityDomain };
}

function transformAggregatedEmployeeProducedActivities(args: {
  aggregatedProducedActivities: API.AggregatedProducedActivity[];
}) {
  const { aggregatedProducedActivities } = args;

  const employeeHourDomain: NormalizedDomain<Data.AggregatedEmployeeHour> = {
    allIds: [],
    byId: {},
  };

  for (let index = 0; index < aggregatedProducedActivities.length; index++) {
    const currentAggregatedProducedActivity =
      aggregatedProducedActivities[index];
    if (!R.isDefined(currentAggregatedProducedActivity)) continue;
    let { monthNumber } = currentAggregatedProducedActivity;
    const {
      year,
      dayOfWeek,
      dayOfMonth,
      weekNumber,
      activityId,
      activityTypeId,
      employeeId,
      spentTime,
    } = currentAggregatedProducedActivity;

    if (
      !R.isDefined(employeeId) ||
      !R.isDefined(activityId) ||
      !R.isDefined(activityTypeId) ||
      !R.isDefined(year)
    )
      continue;

    let dateId = '';
    if (R.isDefined(monthNumber)) monthNumber = monthNumber - 1;
    // Day
    if (
      R.isDefined(monthNumber) &&
      R.isDefined(dayOfMonth)
      // && R.isDefined(weekNumber) should contain weeknumber
    ) {
      dateId = timeUtility.generateDayId(year, monthNumber, dayOfMonth);
    }
    // Week
    else if (
      !R.isDefined(monthNumber) &&
      R.isDefined(weekNumber) &&
      !R.isDefined(dayOfMonth)
    )
      dateId = timeUtility.generateWeekId(year, weekNumber);
    // Month
    else if (
      R.isDefined(monthNumber) &&
      !R.isDefined(weekNumber) &&
      !R.isDefined(dayOfMonth)
    )
      dateId = timeUtility.generateMonthId(year, monthNumber);
    // Year
    else if (
      !R.isDefined(monthNumber) &&
      !R.isDefined(weekNumber) &&
      !R.isDefined(dayOfMonth)
    )
      dateId = timeUtility.generateYearId(year);
    // Never
    else {
    }
    const employeeHour: Data.AggregatedEmployeeHour = {
      id: R.randomString(12),
      dateId,
      activityId,
      activityTypeId,
      employeeId,
      spentTime,
      year,
      dayOfMonth: R.isDefined(dayOfMonth) ? dayOfMonth : undefined,
      // ! day of week always null for this call
      dayOfWeek: R.isDefined(dayOfWeek) ? dayOfWeek : undefined,
      month: R.isDefined(monthNumber) ? monthNumber : undefined,
      week: R.isDefined(weekNumber) ? weekNumber : undefined,
    };

    mutateAdd(employeeHourDomain, employeeHour);
  }
  return { employeeHourDomain };
}

function transformAggregatedProducedActivities(args: {
  aggregatedProducedActivities: API.AggregatedProducedActivity[];
}) {
  const { aggregatedProducedActivities } = args;
  const activityHourDomain: NormalizedDomain<Data.AggregatedActivityHour> = {
    allIds: [],
    byId: {},
  };

  for (let index = 0; index < aggregatedProducedActivities.length; index++) {
    const currentAggregatedProducedActivity =
      aggregatedProducedActivities[index];
    if (!R.isDefined(currentAggregatedProducedActivity)) continue;

    const {
      year,
      monthNumber,
      dayOfWeek,
      dayOfMonth,
      weekNumber,
      activityId,
      activityTypeId,
      spentTime,
    } = currentAggregatedProducedActivity;
    if (
      !R.isDefined(year) ||
      !R.isDefined(monthNumber) ||
      !R.isDefined(dayOfMonth) ||
      !R.isDefined(weekNumber) ||
      !R.isDefined(dayOfWeek) ||
      !R.isDefined(activityId)
    )
      continue;

    const activityHour: Data.AggregatedActivityHour = {
      id: R.randomString(12),
      dayOfWeek,
      month: monthNumber - 1,
      year,
      week: weekNumber,
      dayOfMonth,
      activityId,
      activityTypeId,
      modifiedSpentTime: spentTime,
      spentTime,
    };

    mutateAdd(activityHourDomain, activityHour);
  }

  return { activityHourDomain };
}

function transformCalculatedInternal(args: {
  item: API.CalculatedInternal;
  domain: NormalizedDomain<Data.Internal>;
}) {
  const { domain, item } = args;

  const {
    year,
    monthNumber,
    dayOfWeek,
    dayOfMonth,
    weekNumber,
    companyId,
    id,
    zoneId,
    backlogItemCount,
    processedItemCount,
    receivedItemCount,
    subZoneId,
  } = item;

  if (
    !R.isDefined(year) ||
    !R.isDefined(monthNumber) ||
    !R.isDefined(dayOfMonth) ||
    !R.isDefined(weekNumber) ||
    !R.isDefined(dayOfWeek) ||
    !R.isDefined(zoneId) ||
    !R.isDefined(subZoneId)
  )
    throw 'something fishy';

  const internal: Data.Internal = {
    id,
    dayOfWeek,
    month: monthNumber - 1,
    year,
    week: weekNumber,
    dayOfMonth,
    companyId,
    noReceived: receivedItemCount ?? 0,
    zoneId,
    subZoneId,
    noBacklog: backlogItemCount ?? 0,
    noProcessed: processedItemCount ?? 0,
  };

  domainUtility.mutateAdd(domain, internal);
}

function transformaCalculatedExternal(args: {
  item: API.CalculatedExternal;
  domain: NormalizedDomain<Data.External>;
}) {
  const { domain, item } = args;
  const {
    year,
    monthNumber,
    dayOfWeek,
    dayOfMonth,
    weekNumber,
    companyId,
    id,
    zoneId,
    receivedItemCount,
  } = item;

  if (
    !R.isDefined(year) ||
    !R.isDefined(monthNumber) ||
    !R.isDefined(dayOfMonth) ||
    !R.isDefined(weekNumber) ||
    !R.isDefined(dayOfWeek) ||
    !R.isDefined(zoneId)
  )
    throw 'something fishy';

  const external: Data.External = {
    id,
    dayOfWeek,
    month: monthNumber - 1,
    year,
    week: weekNumber,
    dayOfMonth,
    companyId,
    noReceived: receivedItemCount ?? 0,
    zoneId,
  };

  domainUtility.mutateAdd(domain, external);
}

function transformCalculatedInternals(args: {
  calculatedInternals: API.CalculatedInternal[];
}) {
  const { calculatedInternals } = args;

  const internalDomain: NormalizedDomain<Data.Internal> = {
    allIds: [],
    byId: {},
  };

  for (let index = 0; index < calculatedInternals.length; index++) {
    const currentCalculatedInternal = calculatedInternals[index];
    if (!R.isDefined(currentCalculatedInternal)) continue;

    transformCalculatedInternal({
      item: currentCalculatedInternal,
      domain: internalDomain,
    });
  }

  return internalDomain;
}

function transformCalculatedExternals(args: {
  calculatedExternals: API.CalculatedExternal[];
}) {
  const { calculatedExternals } = args;

  const externalDomain: NormalizedDomain<Data.External> = {
    allIds: [],
    byId: {},
  };

  for (let index = 0; index < calculatedExternals.length; index++) {
    const currentCalculatedExternal = calculatedExternals[index];
    if (!R.isDefined(currentCalculatedExternal)) continue;
    transformaCalculatedExternal({
      item: currentCalculatedExternal,
      domain: externalDomain,
    });
  }

  return externalDomain;
}

export {
  transformAggregatedEmployeeProducedActivities,
  transformAggregatedProducedActivities,
  transformCalculatedExternals,
  transformCalculatedInternals,
  transformProducedActivities,
};
