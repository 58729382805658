import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { flexHoursActions, flexHoursSelectors } from 'store/page/flexHours';
import Types from 'Types';

const mapStateToProps = (state: Types.RootState) => ({
  chartFilter: flexHoursSelectors.chartFilter(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  setChartFilter: (chartFilter: Record<string, boolean>) =>
    dispatch(flexHoursActions.setChartFilter(chartFilter)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  current: LabelItem & { children: LabelItem[] };
};
type Props = StateProps & DispatchProps & OwnProps;

const Group: FunctionComponent<Props> = (props) => {
  const { current, chartFilter, setChartFilter } = props;
  // const theme = useTheme()

  function toggle() {
    let clonedChartFilter = R.clone(chartFilter);

    // Non existing value means it is checked.
    const checked = !clonedChartFilter[current.id];

    const allIds = [current.id, ...current.children.map((child) => child.id)];

    if (!checked) clonedChartFilter = R.omit(clonedChartFilter, allIds);
    else if (checked)
      allIds.forEach((id) => {
        clonedChartFilter[id] = true;
      });

    setChartFilter(clonedChartFilter);
  }

  function toggleChild(childId: string) {
    let clonedChartFilter = R.clone(chartFilter);

    const checked = !clonedChartFilter[childId];

    if (!checked) clonedChartFilter = R.omit(clonedChartFilter, [childId]);
    else if (checked) clonedChartFilter[childId] = true;

    const childLength = current.children.length;

    let childCheckedCount = 0;

    current.children.forEach((child) => {
      const checked = !clonedChartFilter[child.id];
      if (checked) childCheckedCount = childCheckedCount + 1;
    });

    const uncheck = childCheckedCount < childLength;
    const check = childCheckedCount === childLength;

    if (uncheck) clonedChartFilter[current.id] = true;

    if (check) clonedChartFilter = R.omit(clonedChartFilter, [current.id]);

    setChartFilter(clonedChartFilter);
  }

  // ActivityType indeterminate
  const indeterminate = R.pipe(
    current.children,
    R.map((child) => chartFilter[child.id]),
    R.filter(R.isDefined),
    (filtered) => filtered.length,
    (filteredLength) =>
      filteredLength !== 0 && filteredLength !== current.children.length,
  );

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
      {current.children.map((child) => (
        <FormControlLabel
          key={child.id}
          label={<Typography variant="action2">{child.name}</Typography>}
          onChange={() => toggleChild(child.id)}
          control={<Checkbox checked={!chartFilter[child.id]} />}
        />
      ))}
    </Box>
  );

  return (
    <>
      <Stack>
        <FormControlLabel
          label={<Typography variant="action2">{current.name}</Typography>}
          onChange={toggle}
          control={
            <Checkbox
              indeterminate={indeterminate}
              checked={!indeterminate && !chartFilter[current.id]}
            />
          }
        />
        {children}
      </Stack>
      <Divider />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);
