import { COMPANY_ID } from 'config/app';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';
import { jwtUtility } from 'utility';

const domain = (state: Types.RootState) => state.glue.user;

const bearerToken = createSelector(domain, (domain) => domain.bearerToken);

const companyId = createSelector(bearerToken, (token) => {
  if (!R.isDefined(token)) return COMPANY_ID;
  const parsedToken = jwtUtility.parseJwt<{ company_id: string }>(token);
  if (!R.isDefined(parsedToken)) return COMPANY_ID;
  const companyId = parsedToken.company_id;
  if (!R.isDefined(companyId)) return COMPANY_ID;
  return companyId;
});
export { bearerToken, companyId };
