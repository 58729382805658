import { DOMAIN_TYPE } from '../constants';

export const MISSING_VALUE = '-';
export const OUTSIDE_ZONE_NAME = 'Outside zone';

const DOMAIN_NAME = 'zoneManagement';

export const INIT = `${DOMAIN_TYPE}/${DOMAIN_NAME}/INIT` as const;

export const MOVE_ACTIVITY =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/MOVE_ACTIVITY` as const;

export const ADD_SUBZONE = `${DOMAIN_TYPE}/${DOMAIN_NAME}/ADD_SUBZONE` as const;

export const START_UPDATE_SUB_ZONES =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_UPDATE_SUB_ZONES` as const;

export const REMOVE_SUBZONE =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/REMOVE_SUBZONE` as const;

export const DISCARD = `${DOMAIN_TYPE}/${DOMAIN_NAME}/DISCARD` as const;

export const SAVE = `${DOMAIN_TYPE}/${DOMAIN_NAME}/SAVE` as const;
