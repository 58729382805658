import * as R from 'remeda';
import { ActionType } from 'typesafe-actions';

import * as dashboardActions from './actions';
import * as dashboardConstants from './constants';
import dashboardReducer from './reducer';
import * as dashboardSagas from './sagas';
import * as dashboardSelectors from './selectors';

export type DashboardState = {
  selectedWeek: Time.Week;
  selectedDay: Time.Day;
  kpis: KPI.Key[];
  pivotById: Record<string, boolean>;
};
export type DashboardAction = ActionType<typeof dashboardActions>;

const dashboardSagaActions = R.pick(dashboardActions, ['startToggleHours']);

export type DashboardSagaAction = ActionType<typeof dashboardSagaActions>;
export {
  dashboardActions,
  dashboardConstants,
  dashboardReducer,
  dashboardSagas,
  dashboardSelectors,
};
