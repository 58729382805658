import React, { FunctionComponent } from 'react';
import { TabsPage } from 'templates';

import ChartView from './ChartView';
import PageActions from './PageActions';
import PivotView from './PivotView';

const TimePage: FunctionComponent = () => {
  return (
    <TabsPage
      tabs={[
        { id: 'table', name: 'Pivot Table', component: <PivotView /> },
        { id: 'charts', name: 'Chart ', component: <ChartView /> },
      ]}
      pageActions={<PageActions />}
    />
  );
};

export default TimePage;
