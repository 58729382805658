import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';
import { aggregationUtility, kpiUtility } from 'utility';

import { onRelevantItems } from '../helpers';

export const domain = (state: Types.RootState) => state.aggregated.forecast;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

const byDate = createSelector(list, (list) =>
  aggregationUtility.groupByDate(list),
);

export const byZone = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'zoneId'),
);

export const byEntity = createSelector(byZone, (byZone) => ({
  ...byZone,
}));

export const kpi = createSelector(
  (
    state: Types.RootState,
    ownProps: {
      entityIds: string[];
      dateId: string;
      filterEntityIds?: string[];
    },
  ) => ownProps,
  byDate,
  byEntity,
  byId,
  ({ dateId, entityIds, filterEntityIds }, byDate, byEntity, byId) => {
    let forecasts: KPI.Projection[] = [];

    onRelevantItems({
      byId,
      byDate,
      byEntity,
      dateId,
      filterEntityIds,
      itemCallback: (item) => {
        const value = kpiUtility.sanitize(item.value);
        if (!R.isDefined(value)) {
          return;
        }
        const timestamp = item.timestamp;
        forecasts.push({ value, timestamp });
      },
      entityIds,
    });

    // First in list is prediction
    forecasts = R.pipe(
      forecasts,
      R.sortBy([(forecast) => forecast.timestamp, 'desc']),
    );

    const forecast = R.first(forecasts);

    const kpi: KPI.ForecastValues = {
      forecast: forecast?.value ?? null,
      forecastHistory: forecasts,
    };
    return kpi;
  },
);

export { byDate };
