// import { LoaderWithOverlay } from 'components/common';
import { CircularProgress, Stack } from '@mui/material';
import { START_PAGE } from 'config/app';
import React, { FunctionComponent, useRef } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';
import * as R from 'remeda';
import { routes } from 'router/constants';
import { loadingSelectors } from 'store/glue/loading';
import { userSelectors } from 'store/glue/user';
import Types from 'Types';

const mapStateToProps = (state: Types.RootState) => ({
  isLoading: loadingSelectors.isLoading(state, {
    types: [
      'glue/user/START_LOGIN',
      'glue/user/START_LOGOUT',
      'glue/user/START_VERIFY',
    ],
  }),
  token: userSelectors.bearerToken(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

// Make sure that the user is authenticated and bounce correctly
const AuthLayout: FunctionComponent<Props> = (props) => {
  const { isLoading, token } = props;

  // const isFirstRender = useIsFirstRender();

  const isAuthenticated = R.isDefined(token);
  const outlet = useOutlet();
  const { pathname } = useLocation();

  const startPath = useRef(
    [routes.login, routes.signinOidc, routes.base].some(
      (route) => route === pathname,
    )
      ? START_PAGE
      : pathname,
  );

  if (isLoading)
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <CircularProgress />
      </Stack>
    );

  if (pathname === routes.login || pathname === routes.signinOidc) {
    if (isAuthenticated) return <Navigate to={startPath.current} />;
    return outlet;
  }

  if (!isAuthenticated) return <Navigate to={routes.login} />;

  if (pathname === routes.base) return <Navigate to={startPath.current} />;

  return outlet;
};

export default connect(mapStateToProps)(AuthLayout);
