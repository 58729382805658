import { DOMAIN_TYPE } from '../constants';

const DOMAIN_NAME = 'user';

export const SET_TOKEN = `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_TOKEN` as const;

export const START_LOGIN = `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_LOGIN` as const;

export const START_LOGOUT =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_LOGOUT` as const;

export const START_VERIFY =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_VERIFY` as const;
