import { DOMAIN_TYPE } from '../constants';

const DOMAIN_NAME = 'dashboard';

export const SET_SELECTED_WEEK =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_SELECTED_WEEK` as const;

export const SET_SELECTED_DAY =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_SELECTED_DAY` as const;

export const TOGGLE_PIVOT =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/TOGGLE_PIVOT` as const;

export const START_TOGGLE_HOURS =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_TOGGLE_HOURS` as const;
