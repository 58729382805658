import { TypographyOptions } from '@mui/material/styles/createTypography';

/**
 * We are using two fonts.
 * In the application we should only use 2 fonts:
 *  - Roboto:
 *    https://fonts.google.com/specimen/Roboto
 *  - Roboto Condensed:
 *    https://fonts.google.com/specimen/Roboto+Condensed
 *
 * However, we have a third "default font to signal that we are using the wrong one:
 *  - Permanent Marker
 *    https://fonts.google.com/specimen/Permanent+Marker
 *
 * TODO: Remove unused fonts from index.html
 */

const fontFamily = {
  UNCONFIGURED_FONT: 'Permanent Marker',
  ROBOTO: 'Roboto',
  ROBOTO_CONDENSED: 'Roboto Condensed',
};

// font-family: 'Permanent Marker', cursive;
// font-family: 'Roboto', sans-serif;
// font-family: 'Roboto Condensed', sans-serif;

const typography: TypographyOptions = {
  fontFamily: [
    fontFamily.ROBOTO,
    fontFamily.ROBOTO_CONDENSED,
    fontFamily.UNCONFIGURED_FONT,
  ].join(','),

  text1: {
    fontFamily: fontFamily.ROBOTO,
    fontSize: 20,
    letterSpacing: 1.5,
    lineHeight: 1.5,
  },
  text2: {
    fontFamily: fontFamily.ROBOTO,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 1,
    lineHeight: 1.5,
  },

  action1: {
    fontFamily: fontFamily.ROBOTO_CONDENSED,
    fontSize: 20,
    letterSpacing: 2,
  },
  action2: {
    fontFamily: fontFamily.ROBOTO_CONDENSED,
    fontSize: 16,
    letterSpacing: 1.5,
  },

  header1: {
    fontFamily: fontFamily.ROBOTO,
    fontSize: 26,
    fontWeight: 'bold',
    letterSpacing: 3,
  },
  header2: { fontFamily: fontFamily.ROBOTO, fontSize: 22, letterSpacing: 2 },
  header3: {
    fontFamily: fontFamily.ROBOTO,
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  header4: {
    fontFamily: fontFamily.ROBOTO,
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: 500,
  },

  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  button: undefined,
  caption: undefined,
  overline: undefined,
};

export { fontFamily, typography };
