import styled from '@emotion/styled';
import { Box, Distances, useTheme } from '@mui/material';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import React, { FunctionComponent } from 'react';
import { Chart } from 'react-chartjs-2';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { aggregatedSelectors } from 'store/aggregated';
import Types from 'Types';
import { stylingUtility } from 'utility';

import { generateOptions } from './helpers';
import LegendList from './LegendList';
import { axisArrowsPlugin, labelPlugin } from './plugins';
import XAxis from './XAxis';

ChartJS.register(
  LinearScale,
  ScatterController,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  annotationPlugin,
  Filler,
);

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => ({
  chartData: aggregatedSelectors.chartData(state, {
    dateIds: ownProps.dateIds,
    datasets: ownProps.datasets,
  }),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  dateIds: string[];
  datasets: Charts.Dataset[];

  // A bit weird, since you can have dataset type different to chart type here...
  // Perhaps set it to bar and be done with it?
  type: 'bar' | 'line';
  fastAnimation?: boolean;
  hasLegend?: boolean;
  xAxisConfig: Charts.XAxisConfig;
  yAxisConfigs: Charts.YAxisConfig[];

  stacked?: boolean; // bar chart are stacked
  xAxisHeight: keyof Distances; // We want to use distance values for padding
  yAxisWidth: keyof Distances; // We want to use distance values for padding
};

type Props = OwnProps & StateProps;

const ChartComponent: FunctionComponent<Props> = (props) => {
  const {
    dateIds,
    type,
    chartData,
    datasets,
    hasLegend = false,
    fastAnimation = false,
    xAxisConfig,
    yAxisConfigs,
    stacked = false,
    xAxisHeight,
    yAxisWidth,
  } = props;

  const theme = useTheme();
  const hasSecondaryYAxis = yAxisConfigs.length === 2;

  const Y_AXIS_WIDTH = stylingUtility.pxToNumber(
    theme.spacing(theme.distances[yAxisWidth]) as any,
  );

  const X_AXIS_HEIGHT = stylingUtility.pxToNumber(
    theme.spacing(theme.distances[xAxisHeight]) as any,
  );
  const LARGE = stylingUtility.pxToNumber(
    theme.spacing(theme.distances['large']) as any,
  );

  const SMALL = stylingUtility.pxToNumber(
    theme.spacing(theme.distances['small']) as any,
  );

  const legendList = hasLegend && (
    <LegendList datasets={datasets} left={Y_AXIS_WIDTH + LARGE} top={SMALL} />
  );
  const xAxis = xAxisConfig.type === 'x_axis_custom' && (
    <Box
      sx={{
        width: '100%',
        height: X_AXIS_HEIGHT,
        paddingLeft: `${Y_AXIS_WIDTH}px`,
        paddingRight: `${Y_AXIS_WIDTH}px`,
      }}
    >
      <XAxis dateIds={dateIds} {...xAxisConfig} />
    </Box>
  );

  const firstDataset = R.pipe(datasets, R.first());

  if (!R.isDefined(firstDataset)) return null;
  return (
    <Wrapper>
      <ChartWrapper>
        {legendList}
        <Chart
          type={type}
          data={chartData}
          options={generateOptions({
            xAxisConfig,
            fastAnimation,
            yAxisConfigs,
            yAxisWidth: Y_AXIS_WIDTH,
            xAxisHeight: X_AXIS_HEIGHT,
            dateIds,
            stacked,
            palette: theme.palette,
          })}
          plugins={[
            axisArrowsPlugin(hasSecondaryYAxis, theme.palette),
            labelPlugin,
          ]}
        />
      </ChartWrapper>
      {xAxis}
    </Wrapper>
  );
};

const Wrapper = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const ChartWrapper = styled.div({
  position: 'relative',
  flexGrow: 1,
  width: '100%',
  height: 0,
});

const ExportComponent = connect(mapStateToProps)(ChartComponent);

export { ExportComponent as Chart };
