import { Sizes } from '@mui/material';

const sizes: Sizes = {
  '21': 21,
  '34': 34,
  '55': 55,
  '89': 89,
  '144': 144,
  '233': 233,
  '377': 377,
  '610': 610,
  '987': 987,
};

export { sizes };
