import * as R from 'remeda';
import { getType } from 'typesafe-actions';

import { ZoneManagementAction, ZoneManagementState } from '.';
import * as actions from './actions';

const initialState: ZoneManagementState = {
  editedActivityDomain: { allIds: [], byId: {} },
};

export const zoneManagementReducer = (
  state: ZoneManagementState = initialState,
  action: ZoneManagementAction,
): ZoneManagementState => {
  switch (action.type) {
    case getType(actions.init):
      return R.set(state, 'editedActivityDomain', action.payload.activity);
    case getType(actions.moveActivity):
      const { activityId, zoneId } = action.payload;

      const editedActivityDomain = R.clone(state.editedActivityDomain);

      editedActivityDomain.byId[activityId] = {
        ...editedActivityDomain.byId[activityId]!,
        zoneId, // Update zone id
        subZoneIds: [], // When moving to another zone, remove connected subzones
      };

      return R.set(state, 'editedActivityDomain', editedActivityDomain);

    case getType(actions.discard):
      return R.set(state, 'editedActivityDomain', action.payload.activity);
    case getType(actions.save):
      return initialState;
    default:
      return state;
  }
};

export default zoneManagementReducer;
