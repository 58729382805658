import { IS_DEVELOPMENT } from 'config/env';
import { UserManagerSettings } from 'oidc-client-ts';

const baseEndpoint = IS_DEVELOPMENT
  ? '/api/identityserver'
  : 'https://identity.rope.software';

const redirectUri = IS_DEVELOPMENT
  ? 'http://localhost:3000/signin-oidc'
  : 'https://rope.software/signin-oidc';

const localSettings: UserManagerSettings = {
  client_id: 'MvcTestApplication',
  client_secret: 'secret',
  authority: baseEndpoint,
  response_type: 'code',
  redirect_uri: redirectUri,
  extraTokenParams: { company_id: '' },
  scope:
    'openid profile CompanyAPI ActualAPI ForecastAPI ScheduleAPI ColdStorageAPI offline_access',
};

export { baseEndpoint, localSettings };
