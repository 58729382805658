import { Box } from '@mui/material';
import LogoImage from 'assets/logo.png';
import React, { FunctionComponent } from 'react';
const Logo: FunctionComponent = () => {
  return <Box sx={(theme) => ({ width: theme.sizes['144'] })} />;
  // return (
  //   <Box
  //     component="img"
  //     sx={(theme) => ({ width: theme.sizes['144'] })}
  //     alt="logo"
  //     // src={LogoImage}
  //   />
  // );
};

export default Logo;
