import * as R from 'remeda';
import { ActionType } from 'typesafe-actions';

import * as forecastActions from './actions';
import * as forecastConstants from './constants';
import forecastReducer from './reducer';
import * as forecastSagas from './sagas';
import * as forecastSelectors from './selectors';
export type ForecastState = { selectedWeek: Time.Week };

export type ForecastAction = ActionType<typeof forecastActions>;

const forecastSagaActions = R.pick(forecastActions, [
  'startCreateForecast',
  'startCreatePrediction',
]);

export type ForecastSagaAction = ActionType<typeof forecastSagaActions>;

export {
  forecastActions,
  forecastConstants,
  forecastReducer,
  forecastSagas,
  forecastSelectors,
};
