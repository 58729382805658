import { SxProps, Theme, Unstable_Grid2 as Grid } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = {
  isHeader?: boolean;
  noBorder?: boolean;
  lastInLine?: boolean;
  children?: React.ReactNode;
  justifyContent?: 'space-between' | 'center';
  alternatingBackground: boolean;
  theme: Theme;
  columns?: number;
  sx?: SxProps<Theme>;
};

const TableCell: FunctionComponent<Props> = (props) => {
  const {
    theme,
    isHeader = false,
    noBorder = false,
    children,
    justifyContent = 'center',
    alternatingBackground,
    lastInLine = false,
    sx,
    columns = 1,
  } = props;

  return (
    <Grid
      xs={columns}
      sx={[
        {
          display: 'flex',
          justifyContent,
          alignItems: 'center',
          pl: theme.distances.small,
          pr: theme.distances.small,
          height: isHeader ? theme.sizes[34] : theme.sizes[55],
          borderRight: 1,
          borderTop: 1,
          // Last item should not have border
          ...(lastInLine && { borderRight: 0 }),
          // Empty header should not have border at all
          ...(noBorder && { border: 0 }),
          borderColor: theme.palette.divider,

          backgroundColor: alternatingBackground
            ? theme.palette.background.default
            : theme.palette.background.paper,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Grid>
  );
};

export { TableCell };
