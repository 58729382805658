import {
  ForecastAction,
  forecastActions as actions,
  ForecastState,
} from 'store/aggregated/forecast';
import { getType } from 'typesafe-actions';
import { domainUtility } from 'utility';

const initialState: ForecastState = { allIds: [], byId: {} };

const forecastReducer = (
  state: ForecastState = initialState,
  action: ForecastAction,
): ForecastState => {
  switch (action.type) {
    case getType(actions.add):
      return domainUtility.add(state, action.payload);
    case getType(actions.set):
      return domainUtility.set(state, action.payload);
    case getType(actions.update):
      return domainUtility.update(state, action.payload);
    case getType(actions.remove):
      return domainUtility.remove(state, action.payload);
    case getType(actions.setDomain):
      const { allIds, byId } = action.payload;
      return { allIds, byId };
    default:
      return state;
  }
};

export default forecastReducer;
