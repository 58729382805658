import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, Drawer, IconButton, Stack, ThemeProvider } from '@mui/material';
import { darkTheme } from 'material';
import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { menuCategories } from 'router/constants';

import NavigationMenuCategory from './NavigationMenuCategory';

const NavigationMenu: FunctionComponent = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  function handleNavigate(routeId: string) {
    setOpen(false);
    navigate(routeId);
  }

  // time / productivity
  const menuButton = (
    <IconButton
      aria-controls={open ? 'navigation-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={() => setOpen(true)}
    >
      <MenuIcon />
    </IconButton>
  );

  const navigationDrawer = (
    <ThemeProvider theme={darkTheme}>
      <Drawer
        id="navigation-menu"
        open={open}
        anchor="top"
        onClose={() => setOpen((open) => !open)}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.default,
          }),
        }}
      >
        <Stack direction="row" justifyContent="center">
          {menuCategories.map((category) => (
            <NavigationMenuCategory
              key={category.id}
              {...category}
              navigate={handleNavigate}
            />
          ))}
        </Stack>
      </Drawer>
    </ThemeProvider>
  );

  return (
    <Box component="nav" aria-label="navigation menu">
      {menuButton}
      {navigationDrawer}
    </Box>
  );
};

export default NavigationMenu;
