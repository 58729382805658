import { Box, Button, Stack, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';
import { timeUtility } from 'utility';
import { DisplayLocation } from 'utility/time/constants';

type Props = {
  dateIds: string[];
  interactable: boolean; // labels are interactable or not
  displayLocation: Extract<DisplayLocation, 'x-axis' | 'x-axis-detailed'>;
  onLabelClick(date: Time.Date): void;
};

const XAxis: FunctionComponent<Props> = (props) => {
  const { dateIds, interactable, onLabelClick, displayLocation } = props;

  const labels: Time.Date[] = R.pipe(
    dateIds,
    R.map((dateId) => timeUtility.dateFromId(dateId)),
    R.filter(R.isDefined),
  );

  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      {labels.map((label) => (
        <Box
          key={label.id}
          sx={{
            width: `calc(100% / ${labels.length})`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="text"
            color="inherit"
            disabled={!interactable || label.type === 'day'}
            onClick={() => onLabelClick(label)}
            sx={{ ['&:disabled']: { color: 'inherit' } }}
          >
            <Typography variant="action2">
              {timeUtility.format(label, displayLocation)}
            </Typography>
          </Button>
        </Box>
      ))}
    </Stack>
  );
};

export default XAxis;
