import { Button, Stack, Typography } from '@mui/material';
import {
  Calendar,
  CALENDAR_HEIGHT,
  CalendarControl,
  Card,
  Modal,
  MODAL_CONTENT_GAP,
  SortableList,
} from 'components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { flexHoursActions, flexHoursSelectors } from 'store/page/flexHours';
import Types from 'Types';
import { timeUtility } from 'utility';

const mapStateToProps = (state: Types.RootState) => ({
  savedSelectedDates: flexHoursSelectors.selectedDates(state),
});

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  saveSelectedDates: (selectedDates: Time.Date[]) =>
    dispatch(flexHoursActions.startSelectDates({ selectedDates })),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  isOpen: boolean;
  onClose(): void;
};

type Props = OwnProps & DispatchProps & StateProps;

const SelectDatesModal: FunctionComponent<Props> = (props) => {
  const { onClose, isOpen, saveSelectedDates, savedSelectedDates } = props;
  const [selectedDates, setSelectedDates] = useState(savedSelectedDates);
  const [currentMonth, setCurrentMonth] = useState(
    timeUtility.factory<Time.Month>({ type: 'month', month: 8, year: 2021 }),
  );

  const onSelectDate = useCallback(
    (selectedDate: Time.Date) => {
      const index = R.pipe(
        selectedDates,
        R.findIndex((_selectedDate) => _selectedDate.id === selectedDate.id),
      );
      if (R.isDefined(index)) {
        setSelectedDates((selectedDates) =>
          R.pipe(
            selectedDates,
            R.reject.indexed((_selectedDate, i) => i === index),
          ),
        );
      } else
        setSelectedDates((selectedDates) =>
          selectedDates.concat([selectedDate]),
        );
    },
    [selectedDates],
  );

  const actions = (
    <>
      <Button
        variant="text"
        color="inherit"
        onClick={() => {
          setSelectedDates(savedSelectedDates);
          saveSelectedDates(savedSelectedDates);
          onClose();
        }}
      >
        <Typography variant="action1">Abort</Typography>
      </Button>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => {
          onClose();
          saveSelectedDates(selectedDates);
        }}
      >
        <Typography variant="action1">Save</Typography>
      </Button>
    </>
  );

  return (
    <Modal
      title="Date picker"
      isOpen={isOpen}
      onClose={onClose}
      actions={actions}
    >
      <Stack
        direction="row"
        sx={(theme) => ({ gap: theme.distances[MODAL_CONTENT_GAP] })}
        alignItems="flex-end"
      >
        <Stack
          direction="column"
          sx={(theme) => ({ gap: theme.distances[MODAL_CONTENT_GAP] })}
        >
          <CalendarControl
            onSelectDate={onSelectDate}
            selectedDates={selectedDates}
            month={currentMonth}
            setMonth={setCurrentMonth}
          />

          <Calendar
            onSelectDate={onSelectDate}
            selectedDates={selectedDates}
            month={currentMonth}
          />
        </Stack>
        <Card
          sx={(theme) => ({
            flexGrow: 1,
            height: CALENDAR_HEIGHT(theme),
            overflowY: 'scroll',
          })}
        >
          <SortableList<Time.LabeledDate<Time.Date>>
            list={selectedDates.map((date) => ({
              ...date,
              name: timeUtility.format(date, 'selected-list'),
            }))}
            setList={setSelectedDates}
          />
        </Card>
      </Stack>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDatesModal);
