import * as R from 'remeda';
import { ActionType } from 'typesafe-actions';

import * as appActions from './actions';
import * as appConstants from './constants';
import * as appSagas from './sagas';

const appSagaActions = R.pick(appActions, ['startInitialize']);

export type AppAction = ActionType<typeof appActions>;
export type AppSagaAction = ActionType<typeof appSagaActions>;

export { appActions, appConstants, appSagas };
