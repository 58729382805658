import { combineReducers } from 'redux';

import { activityHourReducer } from './activityHour';
import { employeeHourReducer } from './employeeHour';
import { externalReducer } from './external';
import { forecastReducer } from './forecast';
import { internalReducer } from './internal';
import { predictionReducer } from './prediction';

const aggregatedReducers = combineReducers({
  employeeHour: employeeHourReducer,
  activityHour: activityHourReducer,
  forecast: forecastReducer,
  prediction: predictionReducer,
  internal: internalReducer,
  external: externalReducer,
});

export default aggregatedReducers;
