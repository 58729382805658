import { Check } from '@mui/icons-material';
import { Icon, Stack, ToggleButton, Typography } from '@mui/material';
import { Stepper } from 'components';
import React, { FunctionComponent, useCallback } from 'react';
import { timeUtility } from 'utility';

import { getButtonProps } from './helpers';

type Props = {
  selectedDates: Time.Date[];
  onSelectDate(date: Time.Date): void;
  month: Time.Month;
  setMonth(month: Time.Month): void;
};

const CalendarControl: FunctionComponent<Props> = (props) => {
  const { onSelectDate, selectedDates, month, setMonth } = props;
  const {
    onClick: yearOnClick,
    pressed: yearPressed,
    text: yearText,
  } = getButtonProps(
    timeUtility.factory({ type: 'year', year: month.year }),
    selectedDates,
    onSelectDate,
  );

  const {
    onClick: monthOnClick,
    pressed: monthPressed,
    text: monthText,
  } = getButtonProps(
    timeUtility.factory({
      type: 'month',
      year: month.year,
      month: month.month,
    }),
    selectedDates,
    onSelectDate,
  );

  const step = useCallback(
    (step: System.Step, stepSize: Extract<Time.DateType, 'year' | 'month'>) => {
      const steppedDate = timeUtility.step<Time.Month>({
        timeDate: month,
        stepSize,
        step,
      });

      setMonth(steppedDate);
    },
    [month, setMonth],
  );

  const generateStepperToggleButton = (
    text: string,
    pressed: boolean,
    onChange: () => void,
  ) => (
    <ToggleButton
      sx={(theme) => ({
        border: 0,
        borderRadius: 0,
        color: theme.palette.text.primary,
        ['&.Mui-selected']: {
          color: theme.palette.text.secondary,
        },
      })}
      fullWidth
      selected={pressed}
      color="primary"
      value={text}
      onChange={onChange}
    >
      <Icon fontSize="small" />
      <Typography variant="action1">{text}</Typography>
      {pressed ? (
        <Check fontSize="small" />
      ) : (
        <Icon color="primary" fontSize="small" />
      )}
    </ToggleButton>
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={(theme) => theme.distances.huge}
    >
      <Stepper
        next={() => step('next', 'year')}
        previous={() => step('previous', 'year')}
        sx={{ width: '50%' }}
      >
        {generateStepperToggleButton(yearText, yearPressed, yearOnClick)}
      </Stepper>

      <Stepper
        next={() => step('next', 'month')}
        previous={() => step('previous', 'month')}
        sx={{ width: '50%' }}
      >
        {generateStepperToggleButton(monthText, monthPressed, monthOnClick)}
      </Stepper>
    </Stack>
  );
};

export { CalendarControl };
