import axios from 'axios';
import { USE_MOCK } from 'config/app';
import * as R from 'remeda';

import { baseEndpoint } from './config';
import mockedGetCompaniesJson from './mocked/getCompanies.json';
import mockedGetCompanyJson from './mocked/getCompany.json';
import mockedGetSuggestionsJson from './mocked/getSuggestions.json';
import {
  transformCompanies,
  transformCompany,
  transformSuggestions,
} from './transformers';

const getCompanies = (params: { bearerToken: string; useMocked?: boolean }) => {
  const { bearerToken, useMocked = USE_MOCK } = params;

  if (useMocked)
    return Promise.resolve(
      mockedGetCompaniesJson as ReturnType<typeof transformCompanies>,
    );

  return axios
    .get<API.Company[]>(`${baseEndpoint}/companies`, {
      headers: { Authorization: `Bearer ${bearerToken}` },
    })
    .then((response) => {
      const { data } = response;
      const transformed = transformCompanies(data);
      return transformed;
    });
};

function getOrganization(params: {
  companyId: string;
  useMocked?: boolean;
  bearerToken: string;
}) {
  const { companyId, useMocked = USE_MOCK, bearerToken } = params;

  if (useMocked)
    return Promise.resolve(
      mockedGetCompanyJson as ReturnType<typeof transformCompany>,
    );

  return axios
    .get<API.Company>(`${baseEndpoint}/companies/${companyId}`, {
      headers: { Authorization: `Bearer ${bearerToken}` },
    })
    .then((response) => {
      const { data: company } = response;
      if (!R.isDefined(company)) return;
      const transformed = transformCompany({
        _company: company,
      });

      return transformed;
    });
}
function getSuggestions(params: {
  companyId: string;
  useMocked?: boolean;
  bearerToken: string;
}) {
  const { companyId, useMocked = USE_MOCK, bearerToken } = params;

  if (useMocked)
    return Promise.resolve(
      mockedGetSuggestionsJson as ReturnType<typeof transformSuggestions>,
    );

  return axios
    .get<API.Suggestion[]>(
      `${baseEndpoint}/companies/${companyId}/suggestions`,
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
      },
    )
    .then((response) => {
      const { data: suggestions } = response;
      const transformed = transformSuggestions(suggestions);
      return transformed;
    });
}

export { getCompanies, getOrganization, getSuggestions };
