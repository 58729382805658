import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Distances,
  IconButton,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { darkTheme } from 'material';
import React, { FunctionComponent } from 'react';

const MODAL_CONTENT_HORIZONTAL_PADDING: keyof Distances = 'large';
const MODAL_CONTENT_VERTICAL_PADDING: keyof Distances = 'medium';
const MODAL_CONTENT_GAP: keyof Distances = 'large';
type Props = {
  isOpen: boolean;
  onClose(): void;
  title: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  fullWidth?: boolean;
};

const Modal: FunctionComponent<Props> = (props) => {
  const {
    onClose,
    isOpen,
    title,
    children,
    actions,
    fullWidth = false,
  } = props;

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.default,
          }),
        }}
      >
        <DialogTitle
          align="center"
          sx={(theme) => ({
            p: theme.distances.small,
            pb: theme.distances.medium,
          })}
        >
          <Typography
            variant="header2"
            sx={(theme) => ({ color: theme.palette.text.secondary })}
          >
            {title}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="inherit"
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon color="inherit" />
        </IconButton>
        <DialogContent
          sx={(theme) => ({
            pt: theme.distances[MODAL_CONTENT_VERTICAL_PADDING],
            pb: theme.distances[MODAL_CONTENT_VERTICAL_PADDING],
            pl: theme.distances[MODAL_CONTENT_HORIZONTAL_PADDING],
            pr: theme.distances[MODAL_CONTENT_HORIZONTAL_PADDING],
            ...(fullWidth && { pl: 0, pr: 0 }),
          })}
        >
          {children}
        </DialogContent>
        {actions && (
          <DialogActions
            sx={(theme) => ({
              display: 'flex',
              gap: theme.distances.small,
              padding: theme.distances.medium,
              pt: 0,
            })}
          >
            {actions}
          </DialogActions>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export {
  Modal,
  MODAL_CONTENT_GAP,
  MODAL_CONTENT_HORIZONTAL_PADDING,
  MODAL_CONTENT_VERTICAL_PADDING,
};
