import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = {
  datasets: Charts.Dataset[];
  top: number;
  left: number;
};

const LegendItems: FunctionComponent<Props> = (props) => {
  const { datasets, left, top } = props;
  const theme = useTheme();
  return (
    <Stack
      gap={theme.distances.medium}
      direction="row"
      sx={{
        position: 'absolute',
        left,
        top,
      }}
    >
      {datasets.map((dataset) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            gap={theme.distances.tiny}
            key={dataset.id}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: dataset.color,
                width: theme.sizes[21],
                height: theme.sizes[21],
                borderRadius: '50%',
              })}
            />
            <Typography
              variant="header4"
              sx={{ color: theme.palette.text.secondary }}
            >
              {dataset.name}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default LegendItems;
