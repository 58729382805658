import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';

import BreadcrumbRoute from './BreadcrumbRoute';
import Logo from './Logo';
import NavigationMenu from './NavigationMenu';
import UserMenu from './UserMenu';

const AppBarComponent: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      variant="outlined"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Toolbar
        sx={(theme) => ({
          pt: theme.distances.tiny,
          pb: theme.distances.tiny,
          display: 'flex',
          justifyContent: 'space-between',
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.distances.huge,
            alignItems: 'center',
            width: '50%',
          })}
        >
          <NavigationMenu />
          <BreadcrumbRoute />
        </Box>

        <Logo />

        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
