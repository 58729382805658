import { Divider, Stack, ToggleButton, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = {
  value: string | number;
  modifiedValue: string | number;
  selected: boolean;
  onChange(): void;
};

const ToggleTextButton: FunctionComponent<Props> = (props) => {
  const { modifiedValue, selected, value, onChange } = props;

  return (
    <ToggleButton
      value="Modified hours"
      selected={selected}
      size="small"
      color="primary"
      onChange={onChange}
    >
      <Stack
        direction="row"
        gap={(theme) => theme.distances.tiny}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography
          variant="action2"
          sx={(theme) => ({
            position: 'relative',
            ...(selected && {
              ':after': {
                position: 'absolute',
                width: '100%',
                left: 0,
                top: `calc(50% - 1px)`,
                content: "''",
                height: '1px',
                backgroundColor: theme.palette.primary.dark,
              },
            }),
          })}
        >
          {value}
        </Typography>
        {selected && (
          <>
            <Typography variant="action2">{modifiedValue}</Typography>
          </>
        )}
      </Stack>
    </ToggleButton>
  );
};

export { ToggleTextButton };
