import { useTheme } from '@mui/material';
import { Card, Table } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { zoneStructure } from 'store/page/zoneManagement/selectors';
import { ScrollableView } from 'templates';
import { Types } from 'Types';

import ActivityTableRow from './ActivityTableRow';
import { headers } from './constants';

const mapStateToProps = (state: Types.RootState) => ({
  zoneStructure: zoneStructure(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const Overview: FunctionComponent<Props> = (props) => {
  const { zoneStructure } = props;
  const theme = useTheme();

  return (
    <ScrollableView direction="column">
      {zoneStructure.map((zone) => {
        // Which zones are possible to move to?
        const moveToZones = R.pipe(
          zoneStructure,
          R.map((_zone) => {
            if (_zone.id === zone.id) return;
            return {
              id: _zone.id,
              name: _zone.name,
            } as LabelItem;
          }),
          R.filter(R.isDefined),
        );

        return (
          <Card
            key={zone.id}
            title={zone.name}
            sx={(theme) => ({ width: theme.sizes[987] })}
          >
            <Table
              headers={headers}
              rows={zone.children}
              renderRow={(row, alternatingBackground, headers) => (
                <ActivityTableRow
                  key={row.id}
                  headers={headers as any}
                  zoneId={zone.id}
                  moveToZones={moveToZones}
                  row={row}
                  theme={theme}
                  alternatingBackground={alternatingBackground}
                />
              )}
            />
          </Card>
        );
      })}
    </ScrollableView>
  );
};

export default connect(mapStateToProps, null)(Overview);
