import { Breadcrumbs, Typography } from '@mui/material';
import { useRouteName } from 'hooks';
import React, { FunctionComponent } from 'react';

const BreadcrumbRoute: FunctionComponent = () => {
  const route = useRouteName();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {route.map((name, index) => (
        <Typography
          variant="header2"
          color={index === route.length - 1 ? 'text.primary' : 'inherit'}
          key={index}
        >
          {name}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbRoute;
