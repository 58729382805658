import { Box, Typography, useTheme } from '@mui/material';
import { Card, Chart, Input, Stepper, Table, TableCell } from 'components';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { forecastActions, forecastSelectors } from 'store/page/forecast';
import { ScrollableView } from 'templates';
import Types from 'Types';
import { timeUtility } from 'utility';

import {
  charts,
  externalCharts,
  generateDatasets,
  internalCharts,
  ProjectionChart,
} from './charts';
import TableRow from './TableRow';

const SURROUNDING_WEEKS = 2;

const mapStateToProps = (state: Types.RootState) => ({
  selectedWeek: forecastSelectors.selectedWeek(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  onAddForecast: (args: { date: Time.Day; zoneId: string; value: number }) =>
    dispatch(forecastActions.startCreateForecast(args)),

  onAddPrediction: (args: { date: Time.Day; zoneId: string; value: number }) =>
    dispatch(forecastActions.startCreatePrediction(args)),
  setSelectedWeek: (week: Time.Week) =>
    dispatch(forecastActions.setSelectedWeek(week)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = { selectedDays: Time.Day[]; zone: LabelItem };
type Props = OwnProps & DispatchProps & StateProps;

const ZoneView: FunctionComponent<Props> = (props) => {
  const {
    selectedDays,
    zone,
    onAddForecast,
    onAddPrediction,
    selectedWeek,
    setSelectedWeek,
  } = props;

  const [activeChartId, setActiveChartId] = useState<ProjectionChart>(
    'external-forecast-vs-recieved',
  );
  const theme = useTheme();

  function stepSelectedWeek(step: System.Step) {
    const steppedWeek = timeUtility.step({
      timeDate: selectedWeek,
      step,
      stepSize: 'week',
    });

    setSelectedWeek(steppedWeek);
  }

  const fromDay = selectedDays[0];
  if (!R.isDefined(fromDay)) return null;

  const chartDateIds = timeUtility
    .getSurroundingWeeks({
      fromDay,
      surroundingWeeks: SURROUNDING_WEEKS,
    })
    .map((d) => d.id);

  const datasets = generateDatasets(zone.id, activeChartId, theme);

  const stacked = activeChartId === 'internal-accumulated';

  const chartSelect = (
    <Input
      id="select-chart"
      value={activeChartId}
      label="Select chart"
      onChange={(value) => {
        setActiveChartId(value as any);
      }}
      select={{
        items: charts,
        renderValue: (e) => {
          if (typeof e !== 'string') return '- not id -';
          const internalChart = R.find(
            internalCharts,
            (chart) => chart.id === e,
          );
          if (R.isDefined(internalChart))
            return `Internal - ${internalChart?.name}`;

          const externalChart = R.find(
            externalCharts,
            (chart) => chart.id === e,
          );

          if (R.isDefined(externalChart))
            return `External - ${externalChart.name}`;
          return 'Unexpected value';
        },
      }}
    />
  );

  const rows: PivotTable.Row[] = R.pipe(
    selectedDays,
    R.map((day) => {
      const row: PivotTable.Row = {
        id: day.id,
        name: timeUtility.format(day, 'forecast-table-day'),
        ancestorIds: [],
        indentLevel: 1,
      };

      return row;
    }),
  );

  const chartCard = (
    <Card
      largeHeader
      noPadding
      action={chartSelect}
      sx={(theme) => ({ flexGrow: 1, height: 'auto', width: theme.sizes[610] })}
    >
      <Box
        sx={(theme) => ({
          height: '100%',
          width: '100%',
          pt: theme.distances.small,
        })}
      >
        <Chart
          xAxisHeight="massive"
          yAxisWidth="massive"
          datasets={datasets}
          type="bar"
          dateIds={chartDateIds}
          xAxisConfig={{
            type: 'x_axis_days_to_week',
            surroundingWeeks: SURROUNDING_WEEKS,
            id: 'xAxis',
          }}
          yAxisConfigs={[
            { id: 'yAxisPrimary', stacked, expandDataLimit: !stacked },
          ]}
          hasLegend
          fastAnimation
          stacked={stacked}
        />
      </Box>
    </Card>
  );

  const dateCard = (
    <Card
      title={timeUtility.format(selectedWeek, 'forecast-selected-week')}
      sx={(theme) => ({ width: theme.sizes[377] })}
      action={
        <Stepper
          next={() => stepSelectedWeek('next')}
          previous={() => stepSelectedWeek('previous')}
        />
      }
    >
      <Table
        rows={rows}
        renderRow={(row, alternatingBackground) => (
          <TableCell
            key={row.id}
            theme={theme}
            alternatingBackground={alternatingBackground}
            lastInLine
          >
            <Typography variant="text2" noWrap>
              {row.name}
            </Typography>
          </TableCell>
        )}
      />
    </Card>
  );

  const externalCard = (
    <Card title="Customer order" sx={{ width: 610 }}>
      <Table
        rows={rows}
        renderRow={(row, alternatingBackground, headers) => (
          <TableRow
            key={row.id}
            zoneId={zone.id}
            row={row}
            theme={theme}
            headers={headers}
            alternatingBackground={alternatingBackground}
            onAdd={(value) =>
              onAddForecast({
                date: timeUtility.dateFromId(row.id) as Time.Day,
                zoneId: zone.id,
                value,
              })
            }
          />
        )}
        headers={[
          { id: 'forecast', name: 'forecast', columns: 2 },
          { id: 'noExternalReceived', name: 'recieved', columns: 1 },
        ]}
      />
    </Card>
  );

  const internalCard = (
    <Card title="Picking rows" sx={(theme) => ({ width: theme.sizes[610] })}>
      <Table
        rows={rows}
        renderRow={(row, alternatingBackground, headers) => (
          <TableRow
            key={row.id}
            zoneId={zone.id}
            row={row}
            theme={theme}
            headers={headers}
            alternatingBackground={alternatingBackground}
            onAdd={(value) =>
              onAddPrediction({
                date: timeUtility.dateFromId(row.id) as Time.Day,
                zoneId: zone.id,
                value,
              })
            }
          />
        )}
        headers={[
          { id: 'prediction', name: 'prediction', columns: 2 },
          { id: 'noInternalReceived', name: 'recieved', columns: 1 },
        ]}
      />
    </Card>
  );

  return (
    <ScrollableView>
      {chartCard}
      {dateCard}
      {externalCard}
      {internalCard}
    </ScrollableView>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoneView);
