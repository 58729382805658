import * as R from 'remeda';

function iterator<A extends API.Base>(args: {
  list: A[] | null;
  transformer(item: A): void;
}) {
  const { list, transformer } = args;

  if (!R.isDefined(list)) return;

  const length = list.length;

  for (let index = 0; index < length; index++) {
    const item = list[index];
    if (!R.isDefined(item)) continue;
    transformer(item);
  }
}

function toDateResolution(dates: Time.Date[]) {
  const dateResolutions = dates.map((date) => {
    const year = date.year;
    let monthNumber: number | undefined = undefined;
    let weekNumber: number | undefined = undefined;
    let dayOfMonth: number | undefined = undefined;

    switch (date.type) {
      case 'year':
        break;
      case 'month':
        monthNumber = date.month + 1;
        break;
      case 'week':
        weekNumber = date.week;
        break;
      case 'day':
        monthNumber = date.month + 1;
        dayOfMonth = date.dayOfMonth;
    }
    const dateResolution: API.DateResolution = {
      year,
      monthNumber,
      weekNumber,
      dayOfMonth,
    };

    return dateResolution;
  });
  return dateResolutions;
}

export { iterator, toDateResolution };
