import * as R from 'remeda';
import { arrayUtility } from 'utility';

/**
 * Will do a callback on each relevant item
 * used for extracting KPI in a reusable way
 */
function onRelevantItems<T extends BaseItem>(args: {
  entityIds: string[];
  filterEntityIds?: string[];
  dateId: string;
  byDate: Record<string, string[]>;
  byEntity: Record<string, string[]>;
  byId: Dictionary<T>;
  itemCallback(entity: T): void;
}) {
  const {
    byDate,
    byEntity,
    dateId,
    byId,
    entityIds,
    filterEntityIds,
    itemCallback,
  } = args;

  const dateIds = byDate[dateId];
  let ids: string[] = [];

  entityIds.forEach((entityId) => {
    const rowIds = byEntity[entityId] ?? []; // If entity does not have any matching ids, no result should be found
    ids = ids.length === 0 ? rowIds : arrayUtility.intersectingIds(ids, rowIds);
  });

  if (!R.isDefined(dateIds)) return;
  if (!R.isDefined(entityIds)) return;

  ids = arrayUtility.intersectingIds(dateIds, ids);

  if (R.isDefined(filterEntityIds)) {
    let filteredIds: string[] = [];

    filterEntityIds.forEach((entityId) => {
      const rowIds = byEntity[entityId] ?? []; // If entity does not have any matching ids, no result should be found
      filteredIds = R.pipe(filteredIds, R.concat(rowIds), R.uniq());
    });

    ids = arrayUtility.rejectIds(ids, filteredIds);
  }

  R.pipe(
    ids,
    R.map((id) => byId[id]),
    R.filter(R.isDefined),
    R.forEach((item) => itemCallback(item)),
  );
}

export { onRelevantItems };
