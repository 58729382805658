import { ChevronLeftTwoTone, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps, Theme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';
type Props = {
  next(): void;
  previous(): void;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
};

const Stepper: FunctionComponent<Props> = (props) => {
  const { next, previous, sx, children } = props;

  return (
    <Stack
      direction="row"
      gap={(theme) => theme.distances.medium}
      alignItems="center"
      justifyContent="space-between"
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <IconButton size="small" onClick={previous} color="primary">
        <ChevronLeftTwoTone fontSize="large" />
      </IconButton>
      {R.isDefined(children) && (
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
          {children}
        </Box>
      )}
      <IconButton size="small" onClick={next} color="primary">
        <ChevronRight fontSize="large" />
      </IconButton>
    </Stack>
  );
};

export { Stepper };
