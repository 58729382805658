import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';
import { misc } from 'variables';

export const domain = (state: Types.RootState) => state.data.activity;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

// TODO: Remove
export const byZoneId2 = createSelector(list, (list) =>
  R.pipe(
    list,
    R.groupBy((activity) => activity.zoneId ?? misc.MISSING),
  ),
);

export const byZone = createSelector(list, (list) =>
  R.pipe(
    list,
    R.groupBy((activity) => activity.zoneId ?? misc.MISSING),
    R.mapValues((activities) => activities.map((activity) => activity.id)),
  ),
);
