import { combineReducers } from 'redux';
import aggregatedReducers from 'store/aggregated/combinedReducers';
import dataReducers from 'store/data/combinedReducers';
import glueReducers from 'store/glue/combinedReducers';
import pageReducers from 'store/page/combinedReducers';

const rootReducer = combineReducers({
  data: dataReducers,
  glue: glueReducers,
  page: pageReducers,
  aggregated: aggregatedReducers,
});

export default rootReducer;
