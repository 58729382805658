import { createAction } from 'typesafe-actions';

import {
  SET_CHART_EXPLORE_DATE,
  SET_CHART_FILTER,
  SET_CHART_MODE,
  SET_CHART_SELECTED_DATE_TYPE,
  SET_SELECTED_DATES,
  START_SELECT_DATES,
  TOGGLE_PIVOT,
} from './constants';
import { FlexHoursState } from './index';
export const setSelectedDates = createAction(
  SET_SELECTED_DATES,
  (payload: Time.Date[]) => payload,
)();

export const togglePivot = createAction(
  TOGGLE_PIVOT,
  (payload: { id: string }) => payload,
)();

export const setChartMode = createAction(
  SET_CHART_MODE,
  (payload: FlexHoursState['chartMode']) => payload,
)();

export const setChartExploreDate = createAction(
  SET_CHART_EXPLORE_DATE,
  (payload: FlexHoursState['chartExploreDate']) => payload,
)();

export const setChartFilter = createAction(
  SET_CHART_FILTER,
  (payload: FlexHoursState['chartFilter']) => payload,
)();

export const setChartSelectedDateType = createAction(
  SET_CHART_SELECTED_DATE_TYPE,
  (payload: FlexHoursState['chartSelectedDateType']) => payload,
)();

export const startSelectDates = createAction(
  START_SELECT_DATES,
  (payload: { selectedDates: Time.Date[] }) => payload,
)();
