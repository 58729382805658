import { FilterAltOutlined } from '@mui/icons-material';
import { Badge, IconButton, Stack } from '@mui/material';
import { Dropdown, useDropdown } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { activityHourSelectors } from 'store/aggregated/activityHour';
import { flexHoursSelectors } from 'store/page/flexHours';
import Types from 'Types';

import Group from './Group';
const mapStateToProps = (state: Types.RootState) => ({
  activityHierarchy: activityHourSelectors.activityHierarchy(state),
  filterCount: flexHoursSelectors.chartFilterCount(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const FilterButton: FunctionComponent<Props> = (props) => {
  const { activityHierarchy, filterCount } = props;

  const { handleClick, handleClose, open, anchorEl, widthRef } =
    useDropdown(377);
  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          color="secondary"
          badgeContent={filterCount}
        >
          <FilterAltOutlined />
        </Badge>
      </IconButton>
      <Dropdown
        open={open}
        anchorEl={anchorEl}
        widthRef={widthRef}
        handleClose={handleClose}
      >
        <Stack
          direction="column"
          sx={(theme) => ({
            padding: theme.distances.medium,
            gap: theme.distances.medium,
            height: theme.sizes[610],
            overflowY: 'scroll',
          })}
        >
          {activityHierarchy.map((activityType) => (
            <Group key={activityType.id} current={activityType} />
          ))}
        </Stack>
      </Dropdown>
    </>
  );
};

export default connect(mapStateToProps)(FilterButton);
