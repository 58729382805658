import { Plugin } from 'chart.js';
import * as R from 'remeda';

import { scaleFont } from '../constants';

const labelPlugin: Plugin = {
  id: 'label',
  afterBuildTicks(chart) {
    R.forEachObj(chart.scales, (scale) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scale.ctx.letterSpacing = scaleFont.letterSpacing;
    });
  },
};

export { labelPlugin };
