import * as R from 'remeda';
import { apiUtility, domainUtility, timeUtility } from 'utility';

function transformCompanies(
  companies: API.Company[],
): NormalizedDomain<Data.Company> {
  const domain: NormalizedDomain<Data.Company> = { allIds: [], byId: {} };

  for (let index = 0; index < companies.length; index++) {
    const currentCompany = companies[index];

    if (!R.isDefined(currentCompany)) break;

    const company: Data.Company = {
      id: currentCompany.id,
      branchIds: [],
      activityTypeIds: [],
      employeeIds: [],
      description: currentCompany.description ?? '',
      name: currentCompany.name ?? '',
    };

    domainUtility.mutateAdd(domain, company);
  }

  return domain;
}

function transformCompany(args: { _company: API.Company }) {
  const { _company } = args;
  const {
    activityTypes,
    activities,
    areas,
    // boundaries,
    branches,
    distributionCenters,
    employees,
    subZones,
    zones,
  } = _company;
  const employeeDomain: NormalizedDomain<Data.Employee> = {
    allIds: [],
    byId: {},
  };
  const branchDomain: NormalizedDomain<Data.Branch> = {
    allIds: [],
    byId: {},
  };
  const distributionCenterDomain: NormalizedDomain<Data.DistributionCenter> = {
    allIds: [],
    byId: {},
  };
  const zoneDomain: NormalizedDomain<Data.Zone> = {
    allIds: [],
    byId: {},
  };
  const subZoneDomain: NormalizedDomain<Data.SubZone> = {
    allIds: [],
    byId: {},
  };
  const areaDomain: NormalizedDomain<Data.Area> = {
    allIds: [],
    byId: {},
  };
  const activityTypeDomain: NormalizedDomain<Data.ActivityType> = {
    allIds: [],
    byId: {},
  };
  const activityDomain: NormalizedDomain<Data.Activity> = {
    allIds: [],
    byId: {},
  };

  apiUtility.iterator({
    list: branches,
    transformer: (item) => transformBranch({ item, domain: branchDomain }),
  });

  apiUtility.iterator({
    list: distributionCenters,
    transformer: (item) =>
      transformDistributionCenter({ item, domain: distributionCenterDomain }),
  });
  apiUtility.iterator({
    list: areas,
    transformer: (item) => transformArea({ item, domain: areaDomain }),
  });
  apiUtility.iterator({
    list: employees,
    transformer: (item) => transformEmployee({ item, domain: employeeDomain }),
  });
  apiUtility.iterator({
    list: activityTypes,
    transformer: (item) =>
      transformActivityType({ item, domain: activityTypeDomain }),
  });

  apiUtility.iterator({
    list: activities,
    transformer: (item) => transformActivity({ item, domain: activityDomain }),
  });
  apiUtility.iterator({
    list: zones,
    transformer: (item) => transformZone({ item, domain: zoneDomain }),
  });
  apiUtility.iterator({
    list: subZones,
    transformer: (item) => transformSubZone({ item, domain: subZoneDomain }),
  });

  return {
    employeeDomain,
    distributionCenterDomain,
    areaDomain,
    branchDomain,
    activityTypeDomain,
    activityDomain,
    zoneDomain,
    subZoneDomain,
  };
}

function transformBranch(args: {
  item: API.Branch;
  domain: NormalizedDomain<Data.Branch>;
}) {
  const { item, domain } = args;

  const { companyId, description, distributionCenters, name, id } = item;

  const branch: Data.Branch = {
    id,
    companyId,
    description: description ?? '',
    distributionCenterIds: distributionCenters ?? [],
    name: name ?? '',
  };
  domainUtility.mutateAdd(domain, branch);
}

function transformProductivityGoals(
  productivityGoals: API.ProductivityGoal[] | null,
) {
  if (!R.isDefined(productivityGoals)) return [];

  const targets = R.pipe(
    productivityGoals,
    R.map((productivityGoal) => {
      if (!R.isDefined(productivityGoal.validFrom)) return;
      const timestamp = new Date(productivityGoal.validFrom).getTime();
      const day = timeUtility.timestampToDay(timestamp);

      const productivityTarget: Data.ProductivityTarget = {
        date: day,
        id: productivityGoal.id,
        productivity: productivityGoal.productivityNumber,
      };
      return productivityTarget;
    }),
    R.filter(R.isDefined),
    R.sortBy((productivityTarget) => productivityTarget.date.id),
  );

  return targets;
}

function transformZone(args: {
  item: API.Zone;
  domain: NormalizedDomain<Data.Zone>;
}) {
  const { item, domain } = args;

  const { description, subZoneIds, name, id, productivityGoals } = item;

  const zone: Data.Zone = {
    id,
    description: description ?? '',
    name: name ?? '',
    subZoneIds: subZoneIds ?? [],
    targets: transformProductivityGoals(productivityGoals),
  };
  domainUtility.mutateAdd(domain, zone);
}

function transformSubZone(args: {
  item: API.SubZone;
  domain: NormalizedDomain<Data.SubZone>;
}) {
  const { item, domain } = args;

  const {
    description,
    name,
    id,
    subZoneCode,
    zoneId,
    isActive = false,
    productivityGoals,
  } = item;

  if (!R.isDefined(zoneId)) return;

  const subZone: Data.SubZone = {
    id,
    description: description ?? '',
    name: name ?? '',
    code: subZoneCode ?? '',
    zoneId,
    isActive,
    targets: transformProductivityGoals(productivityGoals),
  };

  domainUtility.mutateAdd(domain, subZone);
}

function transformDistributionCenter(args: {
  item: API.DistributionCenter;
  domain: NormalizedDomain<Data.DistributionCenter>;
}) {
  const { item, domain } = args;

  const { areaIds, id, name, zoneIds, description, branchId } = item;

  if (!R.isDefined(branchId)) return;

  const distrubutionCenter: Data.DistributionCenter = {
    id,
    branchId,
    areaIds: areaIds ?? [],
    description: description ?? '',
    zoneIds: zoneIds ?? [],
    name: name ?? '',
  };

  domainUtility.mutateAdd(domain, distrubutionCenter);
}

function transformArea(args: {
  item: API.Area;
  domain: NormalizedDomain<Data.Area>;
}) {
  const { item, domain } = args;

  const { description, id, name, zoneIds, distributionCenterId } = item;

  const area: Data.Area = {
    id,
    description: description ?? '',
    name: name ?? '',
    distributionCenterId,
    zoneIds: zoneIds ?? [],
  };

  domainUtility.mutateAdd(domain, area);
}

function transformEmployee(args: {
  item: API.Employee;
  domain: NormalizedDomain<Data.Employee>;
}) {
  const { item, domain } = args;

  let { firstName, lastName } = item;
  const { id, employeeNumbers, companyId } = item;
  firstName = firstName ?? '';
  lastName = lastName ?? '';

  if (!R.isDefined(companyId)) return;

  const employeeNumber = R.isDefined(employeeNumbers)
    ? R.pipe(
        employeeNumbers,
        R.first(),
        (employeeNumber) => employeeNumber?.employeeNumber ?? '',
      )
    : '';

  const employee: Data.Employee = {
    id,
    companyId,
    name: `${firstName} ${lastName}`,
    nbr: employeeNumber,
  };
  domainUtility.mutateAdd(domain, employee);
}

function transformActivityType(args: {
  item: API.ActivityType;
  domain: NormalizedDomain<Data.ActivityType>;
}) {
  const { item, domain } = args;

  const { description, id, name, activityIds, activityTypeCode, companyId } =
    item;

  // const activityIds = R.isDefined(activities)
  //   ? activities.map((activity) => activity.id)
  //   : [];

  const activityType: Data.ActivityType = {
    id,
    description: description ?? '',
    name: name ?? '',
    activityIds: activityIds ?? [],
    companyId,
    code: activityTypeCode ?? '',
  };

  domainUtility.mutateAdd(domain, activityType);
}

function transformActivity(args: {
  item: API.Activity;
  domain: NormalizedDomain<Data.Activity>;
}) {
  const { item, domain } = args;

  const {
    description,
    id,
    name,
    activityCode,
    activityTypeId,
    isActive,
    subZoneIds,
    zoneId,
    separateInvoice,
  } = item;

  const activity: Data.Activity = {
    id,
    description: description ?? '',
    name: name ?? '',
    activityTypeId,
    code: activityCode ?? '',
    isActive,
    separateInvoice,
    subZoneIds,
    zoneId,
  };

  domainUtility.mutateAdd(domain, activity);
}

function transformSuggestions(
  suggestions: API.Suggestion[],
): NormalizedDomain<Data.Suggestion> {
  const domain: NormalizedDomain<Data.Suggestion> = { allIds: [], byId: {} };

  for (let index = 0; index < suggestions.length; index++) {
    const currentSuggestion = suggestions[index];

    if (!R.isDefined(currentSuggestion)) break;

    const suggestion: Data.Suggestion = {
      id: currentSuggestion.id,
      description: currentSuggestion.description ?? '',
      name: currentSuggestion.title ?? '',
      imageUris: currentSuggestion.imageUris,
      solution: currentSuggestion.solution ?? '',
    };

    domainUtility.mutateAdd(domain, suggestion);
  }

  return domain;
}

export { transformCompanies, transformCompany, transformSuggestions };
