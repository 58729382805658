import { Palette } from '@mui/material';
import { Chart, Plugin } from 'chart.js';
import { stylingUtility } from 'utility';
import { spacing } from 'variables';

import { AXIS_OFFSET, AXIS_WIDTH } from '../constants';

const renderArrows = (
  chart: Chart,
  axisWidth: number,
  axisOffset: number,
  multiAxis: boolean,
  palette: Palette,
) => {
  const {
    ctx,
    chartArea: { top: _top, bottom: _bottom, left, right: _right },
  } = chart;

  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = axisWidth;
  ctx.strokeStyle = palette.text.secondary;

  const arrowSize = stylingUtility.pxToNumber(spacing._8);
  const y_tail_size = stylingUtility.pxToNumber(spacing._5);
  const x_tail_size = stylingUtility.pxToNumber(spacing._8);

  const top = _top - axisOffset;
  const bottom = _bottom + y_tail_size;
  const right = _right + axisOffset;

  ctx.lineCap = 'round';

  // Primary Y-axis
  ctx.moveTo(left, top); //
  ctx.lineTo(left + arrowSize, top + arrowSize);

  ctx.moveTo(left, top);
  ctx.lineTo(left - arrowSize, top + arrowSize);

  ctx.moveTo(left, top);
  ctx.lineTo(left, bottom + y_tail_size);

  // X-axis
  ctx.moveTo(left - x_tail_size, _bottom);
  ctx.lineTo(right, _bottom);

  ctx.moveTo(right, _bottom);
  ctx.lineTo(right - arrowSize, _bottom - arrowSize);

  ctx.moveTo(right, _bottom);
  ctx.lineTo(right - arrowSize, _bottom + arrowSize);

  // Secondary Y-Axis
  if (multiAxis) {
    ctx.moveTo(_right, bottom + y_tail_size);
    ctx.lineTo(_right, top);

    ctx.lineTo(_right - arrowSize, top + arrowSize);
    ctx.moveTo(_right, top);
    ctx.lineTo(_right + arrowSize, top + arrowSize);
  }

  ctx.stroke();
  ctx.closePath();
};

const axisArrowsPlugin = (multiAxis: boolean, palette: Palette): Plugin => {
  return {
    id: 'axisArrows',
    afterDatasetsDraw(chart, _args, _pluginOptions) {
      renderArrows(chart, AXIS_WIDTH, AXIS_OFFSET, multiAxis, palette);
    },
  };
};

export { axisArrowsPlugin };
