import * as R from 'remeda';
import { ActivityState } from 'store/data/activity';
import { ActionType } from 'typesafe-actions';

import * as zoneManagementActions from './actions';
import * as zoneManagementConstants from './constants';
import zoneManagementReducer from './reducer';
import * as zoneManagementSagas from './sagas';
import * as zoneManagementSelectors from './selectors';

export type ZoneManagementState = {
  editedActivityDomain: ActivityState;
};

export type ZoneManagementAction = ActionType<typeof zoneManagementActions>;

const zoneManagementSagaActions = R.pick(zoneManagementActions, [
  'startUpdateSubZones',
]);

export type ZoneManagementSagaAction = ActionType<
  typeof zoneManagementSagaActions
>;

export {
  zoneManagementActions,
  zoneManagementConstants,
  zoneManagementReducer,
  zoneManagementSagas,
  zoneManagementSelectors,
};
