import {
  ExternalAction,
  externalActions as actions,
  ExternalState,
} from 'store/aggregated/external';
import { getType } from 'typesafe-actions';
import { domainUtility } from 'utility';

const initialState: ExternalState = { allIds: [], byId: {} };

const externalReducer = (
  state: ExternalState = initialState,
  action: ExternalAction,
): ExternalState => {
  switch (action.type) {
    case getType(actions.add):
      return domainUtility.add(state, action.payload);
    case getType(actions.set):
      return domainUtility.set(state, action.payload);
    case getType(actions.update):
      return domainUtility.update(state, action.payload);
    case getType(actions.remove):
      return domainUtility.remove(state, action.payload);
    case getType(actions.setDomain):
      const { allIds, byId } = action.payload;
      return { allIds, byId };
    default:
      return state;
  }
};

export default externalReducer;
