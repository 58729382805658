import * as R from 'remeda';
import { UserAction, userActions as actions, UserState } from 'store/glue/user';
import { getType } from 'typesafe-actions';

const initialState: UserState = {
  bearerToken: null,
};

const userReducer = (
  state: UserState = initialState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case getType(actions.setToken):
      const selectedDates = action.payload;
      return R.set(state, 'bearerToken', selectedDates);

    default:
      return state;
  }
};

export default userReducer;
