import { combineReducers } from 'redux';

import { loadingReducer } from './loading';
import { userReducer } from './user';
const glueReducers = combineReducers({
  loading: loadingReducer,
  user: userReducer,
});

export default glueReducers;
