import Glue from 'Glue';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import Types from 'Types';

export const domain = (state: Types.RootState) => state.glue.loading;

export const isLoading = createSelector(
  domain,
  (state: Types.RootState, ownProps: { types: Glue.LoadingType[] }) =>
    ownProps.types,
  (domain, types) => {
    const isLoading = R.pipe(
      types,
      R.find((type) => domain[type].status === 'loading'),
      R.isDefined,
    );

    return isLoading;
  },
);
