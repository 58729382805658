import analysisImage from 'assets/analysis.png';
import configurationImage from 'assets/configuration.png';
import planningImage from 'assets/planning.png';

const routes = {
  base: '/',
  login: '/login',
  signinOidc: '/signin-oidc',

  // analysis
  time: '/time',
  productivity: '/productivity',
  pickingrows: '/picking-rows',

  // planning
  forecast: '/forecast',
  tictac: '/tictac',
  schedule: '/schedule',
  improvements: '/improvements',

  // configuration
  actions: '/actions',
  productivityGoals: '/productivity-goals',
  zoneManagement: '/zone-management',
};

// Exploration
const routeNames: Record<string, string[]> = {
  [routes.time]: ['Analysis', 'Time'],
  [routes.productivity]: ['Analysis', 'Productivity'],
  [routes.zoneManagement]: ['Configuration', 'Zone Management'],
  [routes.forecast]: ['Planning', 'Forecast'],
  [routes.schedule]: ['Planning', 'Schedule'],
  [routes.improvements]: ['Planning', 'Improvements'],
};

export interface MenuCategory {
  id: string;
  name: string;
  imageSource: string;
  routes: (LabelItem & { disabled: boolean })[];
}

const menuCategories: MenuCategory[] = [
  {
    id: '1',
    name: 'Planning',
    imageSource: planningImage,
    routes: [
      { id: routes.forecast, name: 'Forecast', disabled: false },
      { id: routes.schedule, name: 'Schedule', disabled: false },
      { id: routes.improvements, name: 'Improvements', disabled: false },
      { id: routes.tictac, name: 'Tictac', disabled: true },
    ],
  },
  {
    id: '2',
    name: 'Analysis',
    imageSource: analysisImage,
    routes: [
      { id: routes.productivity, name: 'Productivity', disabled: false },
      { id: routes.time, name: 'Time', disabled: false },
      { id: routes.pickingrows, name: 'Picking Rows', disabled: true },
    ],
  },
  {
    id: '3',
    name: 'Configuration',
    imageSource: configurationImage,
    routes: [
      {
        id: routes.zoneManagement,
        name: 'Zone Management',
        disabled: false,
      },
      {
        id: routes.productivityGoals,
        name: 'Productivity Target ',
        disabled: true,
      },
      { id: routes.actions, name: 'Actions', disabled: true },
    ],
  },
];

export { menuCategories, routeNames, routes };
