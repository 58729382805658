import { createAction } from 'typesafe-actions';

import {
  SET_SELECTED_WEEK,
  START_CREATE_FORECAST,
  START_CREATE_PREDICTION,
} from './constants';
import { ForecastState } from './index';

export const setSelectedWeek = createAction(
  SET_SELECTED_WEEK,
  (payload: ForecastState['selectedWeek']) => payload,
)();

export const startCreateForecast = createAction(
  START_CREATE_FORECAST,
  (payload: { zoneId: string; date: Time.Day; value: number }) => payload,
)();
export const startCreatePrediction = createAction(
  START_CREATE_PREDICTION,
  (payload: { zoneId: string; date: Time.Day; value: number }) => payload,
)();
