import { Typography, Unstable_Grid2 as Grid, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import * as R from 'remeda';

import { TableCell } from './TableCell';

type Props = {
  rows: PivotTable.Row[];
  headers?: (LabelItem & { columns?: number })[];
  renderRow(
    row: PivotTable.Row,
    alternatingBackground: boolean,
    headers?: (LabelItem & { columns?: number })[],
  ): React.ReactNode;
};

const Table: FunctionComponent<Props> = (props) => {
  const { headers, rows, renderRow } = props;
  const theme = useTheme();
  const columns = headers
    ? R.pipe(
        headers,
        R.reduce((acc, current) => acc + (current.columns ?? 1), 0),
      )
    : 1;

  const headerRow = R.isDefined(headers) ? (
    headers.map((header, index) => (
      <TableCell
        key={header.id}
        isHeader
        theme={theme}
        lastInLine={index === headers.length - 1}
        alternatingBackground={false}
        columns={header.columns}
      >
        <Typography
          variant="header4"
          sx={{
            color: theme.palette.text.secondary,
            textTransform: 'capitalize',
          }}
        >
          {header.name}
        </Typography>
      </TableCell>
    ))
  ) : (
    <TableCell
      isHeader
      theme={theme}
      alternatingBackground={false}
      noBorder
      columns={columns}
    />
  );

  return (
    <Grid container columns={columns}>
      {headerRow}
      {rows.map((row, index) => renderRow(row, index % 2 == 0, headers))}
    </Grid>
  );
};

export { Table };
